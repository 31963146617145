import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";
import Pagination from "@mui/material/Pagination";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [immagini, setImmagini] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [immagineDaEliminare, setImmagineDaEliminare] = useState(null);

  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(50);
  const [totaleRis, setTotaleRis] = useState(50);

  const [filtroCitta, setFiltroCitta] = useState(null);

  async function query(pagina) {
    const res = await monFetch("/destinationImageList", {
      page: pagina,
      destination: filtroCitta,
    });
    // console.log(res);
    setImmagini(res.destinationimage);
    setTotaleRis(res.count);
    setRisPerPagina(res.resPerPage);
  }

  async function eliminaImmagine(id) {
    const res = await monFetch("/destinationImageDelete", { id });
    // console.log(res);
    setPopupElimina(false);
    query(pagina);
  }

  useEffect(() => {
    props.setElementiBarraTop(<h2>Immagini Destinazioni</h2>);
    query(pagina);
  }, []);

  useEffect(() => {
    query(pagina);
  }, [pagina]);

  useEffect(() => {
    if (filtroCitta === null) {
      return
    }
    if (pagina != 1) {
      setPagina(1);
    } else {
      query(1);
    }
  }, [filtroCitta]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <Button variant="contained" href="/dest_immagini/nuovo">
            Nuovo{" "}
          </Button>
        </Grid>
        <Grid item sm={6} xs={12}></Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Destinazione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroCitta || ""}
            onChange={(e) => {
              setFiltroCitta(e.target.value.toUpperCase());
            }}
          />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Destinazione</TableCell>
            <TableCell>Immagine</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {immagini.map((immagine) => {
            return (
              <TableRow
                key={immagine.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/dest_immagini/" + immagine.id;
                }}
              >
                <TableCell>{immagine.destination}</TableCell>
                <TableCell>{immagine.image_path != null ? "SI" : <span className="text-danger text-bold"> NO </span>}</TableCell>
                <TableCell>{immagine.image2_path != null ? "SI" : <span className="text-danger text-bold"> NO </span>}</TableCell>

                <TableCell
                  style={{
                    textAlign: "right",
                  }}
                >
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setImmagineDaEliminare(immagine.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={Math.ceil(totaleRis / risPerPagina)}
        page={pagina}
        onChange={(e, p) => {
          setPagina(p);
        }}
      />
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaImmagine(immagineDaEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
