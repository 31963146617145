import Button from "@mui/material/Button";
import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { monFetch, convertiData } from "../../inc/inc";
import LinkIcon from "@mui/icons-material/Link";

const Popup = (props) => {
  const [preventiviOptions, setPreventiviOptions] = useState([]);
  const [preventivo, setPreventivo] = useState(null);
  const [popupConferma, setPopupConferma] = useState(false);

  async function query() {
    const res = await monFetch("/quoteList", {
      noLimit: true,
      ordinamento: "id",
      direzione: "desc",
    });
    let temp = [];
    res.quote.forEach((el) => {
      temp.push({
        label:
          el.year +
          " | " +
          el.code +
          " - " +
          (el.companyName || "") +
          " - " +
          convertiData(el.date_insert),
        value: el.id,
      });
    });
    setPreventiviOptions(temp);
  }

  async function spostaProposta() {
    setPopupConferma(false);

    let idProposta = props.idProposta;
    let idNuovoPreventivo = preventivo.value;

    const res = await monFetch("/quotePriceMove", {
      idProposta: idProposta,
      idNuovoPreventivo: idNuovoPreventivo,
    });

    if(res.success){
      window.location.href="/calcoloPreventivo/"+idNuovoPreventivo;
    }
  }

  useEffect(() => {
    if (!props.open) {
      return;
    }

    query();
  }, [props.open]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.fnAnnulla}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle style={{ cursor: "grab" }}>
          Sposta proposta in preventivo
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>
          <Autocomplete
            value={preventivo}
            onChange={(e, value) => {
              setPreventivo(value);
            }}
            options={preventiviOptions}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Preventivo"
                className="inputCustom"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button onClick={props.fnAnnulla}>Annulla</Button>
            <Button
              onClick={() => {
                setPopupConferma(true);
              }}
              variant="contained"
            >
              Sposta
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <PopupConferma
        open={popupConferma}
        fnSuccess={(e) => {
          spostaProposta();
        }}
        fnAnnulla={(e) => setPopupConferma(false)}
      />
    </>
  );
};

const PopupConferma = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle style={{ cursor: "grab" }}>
        Confermi lo spostamento della proposta?
      </DialogTitle>
      {/* <DialogContent>Confermi l'invio della mail?</DialogContent> */}
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
        <Button variant="contained" onClick={props.fnSuccess}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
