import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  monFetch,
  // settaCookieFiltri,
  // getValoreCookieFiltri,
} from "../inc/inc";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/CopyAll";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Pagination from "@mui/material/Pagination";

const Comp = (props) => {
  let location = useLocation();

  const [hotel, setHotel] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(50);
  const [totaleRis, setTotaleRis] = useState(50);

  const [filtroLocalita, setFiltroLocalita] = useState(null);
  const [filtroNome, setFiltroNome] = useState(null);
  const [filtroDescrizione, setFiltroDescrizione] = useState(null);

  const [inputLocalita, setInputLocalita] = useState("");
  const [inputNome, setInputNome] = useState("");
  const [inputDescrizione, setInputDescrizione] = useState("");
  const [inputId, setInputId] = useState(0);

  const [apriBoxNuovo, setApriBoxNuovo] = useState(false);

  const [errore, setErrore] = useState("");

  const aggiungiRimuoviSelezionati = (id, arr, checked) => {
    let temp = [...props.elementiSelezionati];
    if (checked) {
      //aggiungo obj all'array
      temp.push(arr);
    } else {
      //rimuovo obj dall'array
      let obj = temp.find((el) => {
        return el.id == id;
      });

      let index = temp.indexOf(obj);
      if (index >= 0) {
        temp.splice(index, 1);
      }
    }
    props.setElementiSelezionati(temp);
    // console.log(temp);
  };

  async function query(pagina) {
    const res = await monFetch("/destinationHotelList", {
      page: pagina,
      name: filtroNome,
      location: filtroLocalita,
      description: filtroDescrizione,
    });
    setHotel(res.plan);
    setTotaleRis(res.count);
    setRisPerPagina(res.resPerPage);
  }

  async function controllaSeGiaPresente(name, location) {
    const res = await monFetch("/destinationHotelCheckDuplicate", {
      name: name,
      location: location,
    });

    if (!res.disponibile) {
      setErrore("Hotel già inserito");
    } else {
      setErrore("");
    }
  }

  async function salvaNuovo() {
    const res = await monFetch("/destinationHotelSet", {
      name: inputNome,
      location: inputLocalita,
      description: inputDescrizione,
      id: inputId || null,
    });
    query();
    setInputNome("");
    setInputId(0);
    setInputLocalita("");
    setInputDescrizione("");
    setApriBoxNuovo(false);
  }

  async function elimina(id) {
    const res = await monFetch("/destinationHotelDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    if (!props.setElementiBarraTop) {
      return;
    }
    let barraTop = (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          <a href="/dest_hotel" style={{ color: "#fff" }}>
            Hotel
          </a>
        </h2>
        <Button
          className="inputCustom"
          variant="contained"
          onClick={(e) => {
            setInputNome("");
            setInputId(0);
            setInputLocalita("");
            setInputDescrizione("");
            setApriBoxNuovo(!apriBoxNuovo);
          }}
        >
          {!apriBoxNuovo ? "Nuovo" : "Annulla"}
        </Button>
      </div>
    );
    props.setElementiBarraTop(barraTop);
  }, [apriBoxNuovo]);

  useEffect(() => {
    query(pagina);
  }, [pagina]);

  useEffect(() => {
    if (props.setElementiSelezionati) {
      props.setElementiSelezionati([]);
    }
    if (
      filtroLocalita === null &&
      filtroNome === null &&
      filtroDescrizione === null
    ) {
      return;
    }
    if (pagina != 1) {
      setPagina(1);
    } else {
      query(1);
    }
  }, [filtroNome, filtroDescrizione, filtroLocalita]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}></Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Localita"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroLocalita || ""}
            onChange={(e) => {
              setFiltroLocalita(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Nome"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroNome || ""}
            onChange={(e) => {
              setFiltroNome(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Descrizione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroDescrizione || ""}
            onChange={(e) => {
              setFiltroDescrizione(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        {/* form nuovo */}
      </Grid>
      <div
        className="boxNuovaDest"
        style={{ display: apriBoxNuovo ? "flex" : "none", alignItems: "start" }}
      >
        <div
          style={{ display: "flex", gap: 15, flexWrap: "wrap", flexGrow: 1 }}
        >
          <TextField
            style={{ flexGrow: 1 }}
            label="Localita"
            size="small"
            variant="outlined"
            className="inputCustom"
            value={inputLocalita}
            onChange={(e) => {
              setInputLocalita(e.target.value);
              controllaSeGiaPresente(inputNome, e.target.value);
            }}
          />
          <TextField
            style={{ flexGrow: 1 }}
            label="Nome"
            size="small"
            variant="outlined"
            className="inputCustom"
            value={inputNome}
            onChange={(e) => {
              setInputNome(e.target.value);
              controllaSeGiaPresente(e.target.value, inputLocalita);
            }}
          />
          <TextField
            style={{ width: "100%" }}
            label="Descrizione"
            size="small"
            fullWidth
            multiline
            rows={3}
            inputProps={{ max: 250 }}
            variant="outlined"
            className="inputCustom"
            value={inputDescrizione}
            onChange={(e) => {
              setInputDescrizione(e.target.value);
            }}
          />
        </div>
        <div style={{ minWidth: "15%" }}>
          <Button
            className="inputCustom"
            variant="contained"
            disabled={!inputDescrizione || errore ? true : false}
            fullWidth
            onClick={(e) => {
              salvaNuovo();
            }}
          >
            {inputId == 0 ? "Aggiungi" : "Modifica"}
          </Button>
          {errore && <div className="messaggioErrore">{errore}</div>}
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            {props.selezionabile && <TableCell></TableCell>}
            <TableCell>Località</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            {!props.selezionabile && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {hotel.map((p) => {
            return (
              <TableRow key={p.id} className="">
                {props.selezionabile && (
                  <TableCell style={{ width: 50 }}>
                    <input
                      type="checkbox"
                      checked={
                        props.elementiSelezionati.find((el) => {
                          return el.id == p.id;
                        })
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        aggiungiRimuoviSelezionati(p.id, p, e.target.checked);
                      }}
                    ></input>
                  </TableCell>
                )}
                <TableCell>{p.location}</TableCell>
                <TableCell>{p.name}</TableCell>
                <TableCell>{p.description}</TableCell>

                {!props.selezionabile && (
                  <TableCell
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputNome(p.name);
                        setInputLocalita(p.location);
                        setInputDescrizione(p.description);
                        setInputId(p.id);
                        setErrore("");
                        setApriBoxNuovo(true);
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                    <CopyIcon
                      color="warning"
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputNome(p.name);
                        setErrore("");
                        setInputId(0)
                        setInputLocalita(p.location);
                        setInputDescrizione(p.description);
                        setApriBoxNuovo(true);
                        controllaSeGiaPresente(p.name, p.location)
                      }}
                    />
                    <DeleteIcon
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopupElimina(true);
                        setDaEliminare(p.id);
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={Math.ceil(totaleRis / risPerPagina)}
        page={pagina}
        onChange={(e, p) => {
          setPagina(p);
        }}
      />
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
