import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const Comp = () => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [keyRuolo, setKeyRuolo] = useState("");

  async function queryRuolo() {
    const res = await monFetch("/roleGet", {
      id: params.ruolo,
    });
    console.log(res);
    const level = res.role;
    setNome(level.name);
    setDescrizione(level.description);
    setKeyRuolo(level.roleKey)
  }

  async function salvaUtente(e) {
    e.preventDefault();
    // console.log(password);
    // return
    const res = await monFetch("/roleSet", {
      id: params.ruolo,
      name: nome,
      description: descrizione,
      roleKey: keyRuolo
    });
    // console.log(res);

    if (res.success) {
      window.location.reload();
    }
  }

  useEffect(() => {
    queryRuolo();
  }, []);

  return (
    <form onSubmit={(e) => salvaUtente(e)}>
      <h2>Modifica Ruolo</h2>
      <TextField
        style={{ marginBottom: "20px" }}
        required
        label="Nome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome || ""}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <TextField
        style={{ marginBottom: "20px" }}
        label="Descrizione"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={descrizione || ""}
        onChange={(e) => {
          setDescrizione(e.target.value);
        }}
      />
      <TextField
        style={{ marginBottom: "20px" }}
        label="Chiave"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={keyRuolo || ""}
        onChange={(e) => {
          setKeyRuolo(e.target.value);
        }}
      />
      <Button className="inputCustom" type="submit" variant="contained">
        Salva
      </Button>
    </form>
  );
};

export default Comp;
