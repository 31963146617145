import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { convertiData, monFetch } from "../inc/inc";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

export default function Comp(props) {
  let params = useParams();

  const [data, setData] = useState([]);

  const [confermato, setConfermato] = useState(false);

  const [clausola1, setClausola1] = useState(false);
  const [clausola2, setClausola2] = useState(false);
  const [clausola3, setClausola3] = useState(false);

  async function verify_token() {
    const res = await monFetch("/getContractConfirmationData", {
      quote_id: params.quote_id,
      calc_quote_id: params.calc_quote_id,
      token: params.token,
    });
    if (!res.success) {
      window.location.href = "/login";
    } else {
      if (res.res.dataConferma) {
        setConfermato(true);
      }
      if (res.res.flags) {
        let flags = JSON.parse(res.res.flags);
        setClausola1(flags.clausola1);
        setClausola2(flags.clausola2);
        setClausola3(flags.clausola3);
      }
      setData(res.res);
    }
  }

  async function confermaContratto() {
    let conferme = {
      clausola1: clausola1,
      clausola2: clausola2,
      clausola3: clausola3,
    };

    let jsonConferme = JSON.stringify(conferme);

    console.log(params.quote_id, params.calc_quote_id, jsonConferme);

    const res = await monFetch("/confirmContract", {
      quote_id: params.quote_id,
      calc_quote_id: params.calc_quote_id,
      flags: jsonConferme,
      token: params.token,
    });

    setConfermato(true);
  }

  useEffect(() => {
    verify_token();
  }, []);

  return (
    <Container>
      <Grid item xs={12}>
        <h1 style={{ borderBottom: "1px solid #d12985" }}>
          Conferma contratto
        </h1>
        <p>
          Accettando le condizioni sotto riportate si accetta il contratto
          relativo al seguente preventivo:
        </p>
        <div className="boxDettagliContratto">
          <div className="f1">
            <p>{data.numeroPrev}</p>
            <p style={{ textTransform: "none" }}> {data.dataRealizzazione} </p>
            <br />
            <p>{data.tipoViaggio} </p>
            <p
              style={{ fontSize: "50px", fontWeight: "600" }}
              className="destinazioneContratto"
            >
              {data.destinazione}
            </p>
            <p>{data.date}</p>
            <p style={{ marginTop: 30 }}>Quota a partecipante</p>
            <div className="contrTotaleAPersona">{data.totalePagante} €</div>
          </div>

          <div className="f2"> 
            <img
              src="/img/logo-bianco.svg"
              style={{ display: "block", margin: "auto"}}
            ></img>
          </div>
        </div>

        <div className="approvazioneCondizioniCheck">
          <Checkbox
            checked={clausola1}
            disabled={confermato}
            onChange={(e) => {
              setClausola1(e.target.checked);
            }}
          />
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            tincidunt metus vitae convallis hendrerit. Quisque ac consectetur
            ante. Curabitur a lacinia turpis. Fusce vulputate mi pretium turpis
            eleifend, vel molestie lectus ornare. Donec sit amet lectus id mi
            tempor ultricies at in orci. Etiam in volutpat ex. Nulla vitae porta
            eros. Cras a semper sem.
          </span>
        </div>
        <div className="approvazioneCondizioniCheck">
          <Checkbox
            checked={clausola2}
            disabled={confermato}
            onChange={(e) => {
              setClausola2(e.target.checked);
            }}
          />
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            tincidunt metus vitae convallis hendrerit. Quisque ac consectetur
            ante. Curabitur a lacinia turpis. Fusce vulputate mi pretium turpis
            eleifend, vel molestie lectus ornare. Donec sit amet lectus id mi
            tempor ultricies at in orci. Etiam in volutpat ex. Nulla vitae porta
            eros. Cras a semper sem.
          </span>
        </div>
        <div className="approvazioneCondizioniCheck">
          <Checkbox
            checked={clausola3}
            disabled={confermato}
            onChange={(e) => {
              setClausola3(e.target.checked);
            }}
          />
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            tincidunt metus vitae convallis hendrerit. Quisque ac consectetur
            ante. Curabitur a lacinia turpis. Fusce vulputate mi pretium turpis
            eleifend, vel molestie lectus ornare. Donec sit amet lectus id mi
            tempor ultricies at in orci. Etiam in volutpat ex. Nulla vitae porta
            eros. Cras a semper sem.
          </span>
        </div>
        <div style={{ marginBottom: 50, marginTop: 15 }}>
          {!confermato && (
            <Button
              className="inputCustom"
              disabled={!clausola1 || !clausola2 || !clausola3}
              type="submit"
              variant="contained"
              onClick={() => {
                confermaContratto();
              }}
            >
              Conferma
            </Button>
          )}
          {confermato && (
            <div className="notificaConferma">
              Preventivo confermato in data{" "}
              {convertiData(data.dataConferma || new Date())}
            </div>
          )}
        </div>
      </Grid>
    </Container>
  );
}
