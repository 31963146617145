import TextField from "@mui/material/TextField";
import { monFetch, monForm } from "../../inc/inc";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";

import { FileUploader } from "react-drag-drop-files";

const MediaUpload = (p) => {
  let tree = false;
  if (p.tipoPopup == "tree") {
    tree = true;
  }

  // console.log("Media Upload P", p, tree);
  const [nomeFile, setNomeFile] = useState("");
  const [optionsTipiMedia, setOptionsTipiMedia] = useState([]);
  const [optionsSubCategorie, setOptionsSubCategorie] = useState([]);
  const [tipoMedia, setTipoMedia] = useState(p.tipoMedia || "");
  const [subCat, setSubCat] = useState("");

  // async function queryTipiMedia() {
  //   const res = await monFetch("/mediaTypeList", {});
  //   // console.log(res);
  //   const stati = [];
  //   res.media.forEach((el) => {
  //     stati.push({ label: el.media_type, value: el.id });
  //   });
  //   setOptionsTipiMedia(stati);
  // }

  async function querySubCategorie() {
    const res = await monFetch("/mediaSubCatList", {
      idCat: p.tipoMedia,
    });
    // console.log(res);
    const temp = [
      // { label: "Nessuna", value: 0 }
    ];
    res.res.forEach((el) => {
      temp.push({ label: el.media_subcat, value: el.id });
    });
    setOptionsSubCategorie(temp);
  }

  // async function caricaFile(file) {
  //   // e.preventDefault();
  //   // console.log(file);
  //   var formData = new FormData();
  //   // let file = document.querySelector("#fileUpload").files[0];
  //   let idPrev = p.idPrev;

  //   const d = new Date();
  //   let nomeFile = d.getTime() + idPrev;

  //   formData.append("file", file);
  //   formData.append("quote_id", idPrev);
  //   formData.append("file_name", nomeFile);
  //   formData.append("media_type", tipoMedia);

  //   const res = await monForm("/mediaAdd", formData);
  //   if (res.media) {
  //     setNomeFile("File Caricato!");
  //     // document.querySelector("#fileUpload").value = null;
  //   }
  //   //queryMediaAssociati();
  //   //const resDisp = controllaDisponibilita(res.libri);
  //   if (p.fnSuccess) {
  //     p.setTipoMedia("");
  //     p.fnSuccess();
  //   }
  // }

  async function caricaFileTree(file) {
    // e.preventDefault();
    // console.log(file);
    var formData = new FormData();
    // let file = document.querySelector("#fileUpload").files[0];
    let idPrev = p.idPrev;

    const d = new Date();
    let random = Math.floor(1 + Math.random() * 9999).toString();
    let nomeFile = d.getTime() + "_" + idPrev + "_" + random;

    formData.append("file", file);
    formData.append("quote_id", idPrev);
    formData.append("file_name", nomeFile);
    formData.append("categoryId", tipoMedia);
    formData.append("subCategoryId", subCat);

    const res = await monForm("/mediaAddTree", formData);
    if (res.media) {
      setNomeFile("File Caricato!");
      // document.querySelector("#fileUpload").value = null;
    }
    //queryMediaAssociati();
    //const resDisp = controllaDisponibilita(res.libri);
    if (p.fnSuccess) {
      p.setTipoMedia("");
      p.fnSuccess();
    }
  }

  useEffect((e) => {
    if (tree) {
      querySubCategorie();
    }
    // else {
    //   queryTipiMedia();
    // }
  }, []);

  return (
    <div className="boxMedia boxUpload">
      <h2 style={{ marginTop: 0 }}>Carica Allegati</h2>
      <form>
        {tree ? (
          //new Tree
          <>
            <Autocomplete
              value={
                optionsSubCategorie.find((op) => op.value == subCat)
                  ? optionsSubCategorie.find((op) => op.value == subCat)
                  : null
              }
              onChange={(e, value) => {
                // console.log("change autoc", value);
                setSubCat(value?.value);
              }}
              options={optionsSubCategorie}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Sottocategoria"
                  className="inputCustom"
                  // required
                />
              )}
            />
          </>
        ) : (
          //OLD tipo media
          <Autocomplete
            value={
              optionsTipiMedia.find((op) => op.value == tipoMedia)
                ? optionsTipiMedia.find((op) => op.value == tipoMedia)
                : null
            }
            onChange={(e, value) => {
              // console.log("change autoc", value);
              setTipoMedia(value?.value);
            }}
            options={optionsTipiMedia}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Tipo allegato"
                className="inputCustom"
                required
              />
            )}
          />
        )}
        <br />
        {/* <Button variant="contained" component="label" disabled={!tipoMedia}>
          Scegli File
          <input
            hidden
            type="file"
            id="fileUpload"
            onChange={(e) => {
              setNomeFile(
                e.target.files[0]?.name ? e.target.files[0]?.name : ""
              );
              caricaFile(e);
            }}
          />
        </Button> */}
        <FileUploader
          label="Carica file"
          multiple
          disabled={!tipoMedia}
          name="fileUpload"
          handleChange={(files) => {
            // console.log(files)
            // return
            if (!tipoMedia) {
              return;
            }
            // setNomeFile(file?.name ? file?.name : "");
            Array.from(files).forEach((file) => {
              if (tree) {
                caricaFileTree(file);
              }
              // else {
              //   caricaFile(file);
              // }
            });
          }}
        />
        {" " + nomeFile}
      </form>
    </div>
  );
};

export { MediaUpload };
