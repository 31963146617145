import TextField from "@mui/material/TextField";
import { monFetch, calcDataSommaGiorni } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";


const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;
  let index2 = props.index2;

  let isAutista = props.isAutista;

  let nNotti = 1;

  useEffect(() => {
   
    let dataFine = calcDataSommaGiorni(dati.inizio, nNotti);
    // console.log(dati.inizio, nNotti, dataFine, index, index2);
    settaDati(arr, "fine", index, index2, dataFine || null);
  }, [nNotti, dati.inizio]);

  return (
    <>
      <Grid item xs={12}>
        <label className="labelGruppo noBorder">Proposta {index2 + 1} - {props.dati.tipo} notte</label>
      </Grid>

      <Grid item sm={12} xs={12} className="hideOnPrint">
        <div className="labelGruppoCont"></div>
      </Grid>

      <Grid item xs={12}>
        <TextField
          size="small"
          label="Nome"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.hotel || ""}
          onChange={(e) => {
            settaDati(arr, "hotel", index, index2, e.target.value || null);
          }}
        />

      </Grid>

      {!isAutista && (
        <>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label="Data Inizio"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom"
              value={dati.inizio || ""}
              onChange={(e) => {
                settaDati(arr, "inizio", index, index2, e.target.value || null);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label="Data Fine"
              disabled
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom"
              value={dati.fine || ""}
            />
          </Grid>
        </>
      )}

      {!isAutista && (
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Descrizione"
            multiline
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: 250 }}
            variant="outlined"
            className="inputCustom"
            value={dati.descrizione || ""}
            onChange={(e) =>
              settaDati(arr, "descrizione", index, index2, e.target.value)
            }
          />
        </Grid>
      )}
    </>
  );
};

export default Comp;
