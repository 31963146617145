import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  monFetch,
  checkAutista,
  calcDataFine,
  checkRuoloUtente,
  convertiData,
} from "../inc/inc";
import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Sistemazione from "./CalcoloPrevBlocchi/Sistemazione";
import SistemazioneMezzo from "./CalcoloPrevBlocchi/SistemazioneMezzo";
import PropostaAereo from "./CalcoloPrevBlocchi/PropostaAereo";
import PropostaNave from "./CalcoloPrevBlocchi/PropostaNave";
import PropostaTreno from "./CalcoloPrevBlocchi/PropostaTreno";
import ProposteBus from "./CalcoloPrevBlocchi/ProposteBus";
import Guide from "./CalcoloPrevBlocchi/Guide";
import Escursioni from "./CalcoloPrevBlocchi/Escursioni";
import Ingressi from "./CalcoloPrevBlocchi/Ingressi";
import Riepilogo from "./CalcoloPrevBlocchi/Riepilogo";
import Ristorazione from "./CalcoloPrevBlocchi/Ristorazione";
import Transfer from "./CalcoloPrevBlocchi/Transfer";
import { CircularProgress } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import HikingIcon from "@mui/icons-material/Hiking";
import TourIcon from "@mui/icons-material/Tour";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

import { memo } from "react";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditorBase from "./componenti_generici/EditorBase";
import BoxNotifica from "./componenti_generici/BoxNotifica";

import PopupAggiungi from "./CalcoloPrevBlocchi/PopupAggiungi";
import PopupInvioMail from "./CalcoloPrevBlocchi/PopupInvioMail";
import PopupSpostaProposta from "./CalcoloPrevBlocchi/PopupSpostaProposta";
import PopupDettaglioCliente from "./PopupDettaglioCliente";
import EmailIcon from "@mui/icons-material/Email";
import ArticleIcon from "@mui/icons-material/Article";

import queryPreventivo from "../query/queryPreventivo";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";

let timeoutSave = null;
let caricato = false;
let tempTrigger = 0;

let timeout;
let nomePropostaVar = "";

const queryPreventivoCalcolo = async (
  idForzato = null,
  inDuplicazione = false,
  parametri
) => {
  parametri.setInCaricamento((inCaricamento) => inCaricamento + 1);
  let dati = {};

  if (idForzato) {
    dati.id = idForzato;
  } else {
    dati.quote_id = parametri.id;
  }

  // console.log(dati);
  // if (mezzo && mezzo.value == 2) {
  ///se aereo query dei vettori
  parametri.queryVettoriAereo();
  // }

  const res = await monFetch("/quotePriceGet", dati);

  if (!res.success) {
    parametri.setNumeroSistemazioni(1);
    parametri.setNumeroSoste(1);
  }

  if (res.success) {
    let quote = res.quote.quote;
    let mezzo = parametri.optionsTrasporti.find((el) => {
      return el.value == quote.transport_id;
    });

    if (mezzo && mezzo.value == 2) {
      ///se aereo query dei vettori
      parametri.queryVettoriAereo();
    }

    ///bus
    let bus = res.quote.bus;
    let temp = [];
    if (bus && bus.length > 0) {
      bus.forEach((el) => {
        temp.push({
          posti: el.seat,
          n_autisti: el.n_drivers,
          parcheggi: el.parking,
          ztl: el.ztl,
          costo: el.cost,
          costo2Autista: el.cost2nd,
          autonoleggio: el.rent,
          autista: el.driver,
          targa: el.plate,
          totale: el.total,
        });
      });
      parametri.setNumeroBus(bus.length);
    } else {
      parametri.setNumeroBus(0);
    }
    // console.log("dati bus 1", temp);
    parametri.setDatiBus(temp);
    /////

    ///totali riepilogo
    let tempR = [];
    res.quote.recap.forEach((el, index) => {
      if (!tempR[el.n_transport]) {
        tempR[el.n_transport] = [];
      }
      if (!tempR[el.n_transport][el.n_hotel]) {
        tempR[el.n_transport][el.n_hotel] = {
          totale: el.total,
          forzato: el.forced,
        };
      }
    });

    parametri.setTotaliRiepilogoDB(tempR);
    //////

    let totSenzaAutisti = quote.payers + quote.free;
    ////salvo id della proposta per il salvataggio
    if (!inDuplicazione) {
      parametri.setCalcQuoteId(quote.id);
    }

    parametri.setAutisti(quote.drivers);
    parametri.setDataPreventivo(quote.date_insert?.split(" ")[0]);
    parametri.setPropostaControllata(quote.checked ? true : false);
    parametri.setTotalePaganti(quote.payers);
    parametri.setTotaleNonPaganti(quote.free);
    parametri.setPagantiM(quote.payers_m || 0);
    parametri.setPagantiF(quote.payers_f || 0);
    parametri.setNonPagantiM(quote.free_m || 0);
    parametri.setNonPagantiF(quote.free_f || 0);

    setTimeout(() => {
      parametri.setGratuitiInSingola(quote.free_single_room);
      parametri.setGratuitiInDoppia(quote.free_double_room);
      parametri.setPagantiInSingola(quote.paid_single_room);
      parametri.setPagantiInDoppia(quote.paid_double_room);
    }, 1000);
    parametri.setMezzoTrasporto(mezzo);
    if (!inDuplicazione) {
      parametri.setNomeProposta(quote.proposal_name || "");
      nomePropostaVar = quote.proposal_name || "";
      // console.log(3, quote.proposal_name, nomePropostaVar);
    }
    parametri.setArea(quote.area);
    parametri.setPolicy(quote.assic_policy);
    parametri.setProgrammaCustom(quote.custom_program || "");
    parametri.setNascondiProgramma(quote.hide_program || "");
    parametri.setNoteStampa(quote.print_notes || "");
    parametri.setGiorni(quote.days || "");
    parametri.setNotti(quote.days - 1 || "");
    parametri.setPartenza(quote.date_from || "");
    parametri.setRientro(quote.date_to || "");
    parametri.setDataVerificaPrezzi(quote.price_verify_date || "");
    parametri.setIncludiSimilari(quote.includi_hotel_simili || 0);

    let contrTemp = "";
    if (quote.date_confirm) {
      contrTemp = {
        stato: "accett",
        colore: "green",
        descr:
          "Contratto confermato in data " +
          convertiData(quote.date_confirm),
      };
    } else if (quote.mail_istituto_inviata) {
      contrTemp = {
        stato: "si",
        colore: "#0000ff",
        descr:
          "Contratto inviato in data " +
          convertiData(quote.mail_istituto_inviata),
      };
    } else {
      contrTemp = {
        stato: "no",
        colore: "red",
        descr: "Contratto non inviato",
      };
    }
    parametri.setContrattoInviato(contrTemp);
    parametri.setBusInLoco(quote.bus_in_loco || 0);
    parametri.setPrimaNotteInMezzo(quote.first_night_in_transport || 0);
    parametri.setUltimaNotteInMezzo(quote.last_night_in_transport || 0);

    let tipoNaveDB = parametri.optionsNave.find((el) => {
      return el.value == quote.ship_type;
    });

    if (!tipoNaveDB) {
      tipoNaveDB = parametri.optionsNave[0];
    }

    parametri.setTipoNave(tipoNaveDB);

    ///sistemazioni
    let sistemazioni = res.quote.hotel;
    if (parametri.soloMezzo) {
      ///no sistemazioni
      parametri.setNumeroSistemazioni(0);
      parametri.setNumeroSoste(0);
      parametri.setArrDatiSistemazione([]);
    } else if (sistemazioni.length > 0) {
      let temp = [];
      // let cntSoste = 0;
      // let cntSist = 0;

      let maxSoste = 0;
      let maxSist = 0;
      sistemazioni.forEach((el) => {
        if (el.n_stop > maxSoste) {
          maxSoste = el.n_stop;
        }
        if (el.n_proposal > maxSist) {
          maxSist = el.n_proposal;
        }
        // console.log(el);
        if (!temp[el.n_stop]) {
          //pusho la sosta
          temp.push([]);
        }
        temp[el.n_stop][el.n_proposal] = {
          hotel: el.name,
          localita: el.location,
          descrizione: el.description,
          note: el.note,
          suppSingola: el.single_charge,
          suppDoppia: el.double_charge,
          trattamento: el.treatment,
          trattamentoText: el.treatmentDesc,
          gratuitaBB: el["1free"] > 0 ? el["1free"] : 0,
          gratuitaHB: el["1freeHB"] > 0 ? el["1freeHB"] : 0,
          gratuitaPasto: el["1freePasto"] > 0 ? el["1freePasto"] : 0,
          notti: el.night,
          inizio: el.date_start,
          fine: el.date_end,
          costoNotteBB: el.nightly_cost > 0 ? el.nightly_cost : 0,
          costoNotteHB: el.nightly_cost_hb > 0 ? el.nightly_cost_hb : 0,
          costoNottePasto:
            el.nightly_cost_pasto > 0 ? el.nightly_cost_pasto : 0,
          totaleAPagante: 0,
          forfait: el.forfait,
          posizione: el.position,
          posizioneText: el.positionName,
          stelle: el.stars,
          supp1Notte: el.single_night_charge,
          tipo: el.type,
          tipoText: el.hotelType,
          forn: el.supplier_name,
          biancheria: el.clothes,
          lettiVuoti: el.beds,
        };
      });

      // console.log(temp);
      parametri.setArrDatiSistemazione(temp);

      parametri.setNumeroSistemazioni(maxSist + 1);
      parametri.setNumeroSoste(maxSoste + 1);

      parametri.ricalcolaSistemazioni(temp, maxSoste + 1, maxSist + 1);
    } else {
      parametri.setNumeroSistemazioni(0);
      parametri.setNumeroSoste(0);
      parametri.setArrDatiSistemazione([]);
    }

    ///sistemazioni mezzo
    let sistemazioniMezzo = res.quote.sistMezzo;
    // console.log(999,sistemazioniMezzo);
    if (sistemazioniMezzo.length > 0) {
      let temp = [];
      let indiciProcessati = [];
      let indexArray = -1;
      sistemazioniMezzo.forEach((el) => {
        if (!indiciProcessati.includes(el.transport_night_index)) {
          temp.push([]);
          indiciProcessati.push(el.transport_night_index);
          indexArray++;
        }

        temp[indexArray][el.n_proposal] = {
          hotel: el.name,
          descrizione: el.description,
          inizio: el.date_start,
          fine: el.date_end,
          proposta: el.n_proposal,
          indice: el.transport_night_index,
          tipo: el.transport_night_index == 0 ? "prima" : "ultima",
        };
      });

      // console.log(999,temp);
      parametri.setArrDatiSistemazioneMezzo(temp);
    }
    ////

    ///catering
    let cater = res.quote.catering;
    if (cater.length > 0) {
      let temp = [...parametri.prezziRistorazione];
      let item = temp.find((el) => {
        return el.priceKey == "catering_meal_basket";
      });
      item.quantita = cater[0].basket;
      item.gratuita = cater[0].basket_1free;
      item.prezzo = cater[0].basket_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_meal_pizza";
      });
      item.quantita = cater[0].pizza;
      item.gratuita = cater[0].pizza_1free;
      item.prezzo = cater[0].pizza_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_meal_restaurant";
      });
      item.quantita = cater[0].restaurant;
      item.gratuita = cater[0].restaurant_1free;
      item.prezzo = cater[0].restaurant_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_meal_self";
      });
      item.quantita = cater[0].self;
      item.gratuita = cater[0].self_1free;
      item.prezzo = cater[0].self_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_pax_day_teacher";
      });
      item.quantita = cater[0].teacher;
      item.gratuita = cater[0].teacher_1free;
      item.prezzo = cater[0].teacher_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_pax_night_special";
      });
      item.quantita = cater[0].special;
      item.gratuita = cater[0].special_1free;
      item.prezzo = cater[0].special_price;

      item = temp.find((el) => {
        return el.priceKey == "catering_meal_water";
      });
      item.quantita = cater[0].water || 0;
      item.gratuita = cater[0].water_1free || 0;
      item.prezzo = cater[0].water_price || 0;

      parametri.setPrezziRistorazione(temp);
      parametri.setPrezziRistorazioneDB([...temp]);
      // parametri.setFree1Ristorazione(cater[0]["1free"]);

      //togli secondi data
      let tempData = quote.date_insert;

      if (quote.date_update) {
        tempData = quote.date_update;
      }
      if (tempData) {
        tempData = tempData.split(":");
        tempData.pop();
        tempData = tempData.join(":");

        parametri.setDataUpdate(tempData);
      }
    }

    ///guide
    let guide = res.quote.guide;
    let guidePDF = res.quote.guidePDF;
    let arrDatiGuide2 = [];
    let arrDatiGuide2PDF = [];
    if (guide) {
      guide.forEach((el, index) => {
        // console.log(index);
        if (!arrDatiGuide2[index]) {
          arrDatiGuide2.push({ ...parametri.datiGuide });
        }
        parametri.settaDatiGuide(
          arrDatiGuide2,
          index,
          {
            id: el.id_guida,
            localita: el.location,
            guidaPers: el.participants_per_guide,
            halfDay: el.half_day,
            fullDay: el.full_day,
            auricolari: el.auricolari,
          },

          totSenzaAutisti
        );
      });
    }
    parametri.setArrDatiGuide(arrDatiGuide2);
    if (guidePDF) {
      guidePDF.forEach((el, index) => {
        // console.log(index);
        if (!arrDatiGuide2PDF[index]) {
          arrDatiGuide2PDF.push({ ...parametri.datiGuide });
        }
        parametri.settaDatiGuide(
          arrDatiGuide2PDF,
          index,
          {
            id: el.id_guida,
            localita: el.location,
            guidaPers: el.participants_per_guide,
            halfDay: el.half_day,
            fullDay: el.full_day,
          },

          totSenzaAutisti,
          true //PDF
        );
      });
    }
    parametri.setArrDatiGuidePDF(arrDatiGuide2PDF);

    ///escursioni
    let extra = res.quote.extra;
    let extraPDF = res.quote.extraPDF;
    let arrDatiEscursioni2 = [];
    let arrDatiEscursioni2PDF = [];
    if (extra) {
      extra.forEach((el, index) => {
        if (!arrDatiEscursioni2[index]) {
          arrDatiEscursioni2.push({ ...parametri.datiEscursioni });
        }
        parametri.settaDatiEscursioni(
          arrDatiEscursioni2,
          index,
          {
            id: el.id_extra,
            localita: el.location,
            euroGruppo: el.group_price,
            euroPax: el.passenger_price,
            maxPax: el.max_passenger,
          },
          totSenzaAutisti
        );
      });
    }
    parametri.setArrDatiEscursioni(arrDatiEscursioni2);

    if (extraPDF) {
      extraPDF.forEach((el, index) => {
        if (!arrDatiEscursioni2PDF[index]) {
          arrDatiEscursioni2PDF.push({ ...parametri.datiEscursioni });
        }
        parametri.settaDatiEscursioni(
          arrDatiEscursioni2PDF,
          index,
          {
            id: el.id_extra,
            localita: el.location,
            euroGruppo: el.group_price,
            euroPax: el.passenger_price,
            maxPax: el.max_passenger,
          },
          totSenzaAutisti,
          true //PDF
        );
      });
    }
    parametri.setArrDatiEscursioniPDF(arrDatiEscursioni2PDF);

    ///ingressi
    let ingr = res.quote.entrance;
    let ingrPDF = res.quote.entrancePDF;
    let arrDatiIngressi2 = [];
    let arrDatiIngressi2PDF = [];
    if (ingr) {
      ingr.forEach((el, index) => {
        if (!arrDatiIngressi2[index]) {
          arrDatiIngressi2.push({ ...parametri.datiIngressi });
        }
        parametri.settaDatiIngressi(
          arrDatiIngressi2,
          index,
          {
            id: el.id_entrance,
            descrizione: el.description,
            descrizione_interna: el.internal_description,
            prezzoGruppo: el.group_price,
            prezzoPax: el.passenger_price,
            prezzoNonPag: el.passenger_free_price,
          },

          quote.payers,
          quote.free
        );
      });
    }

    parametri.setArrDatiIngressi(arrDatiIngressi2);

    if (ingrPDF) {
      ingrPDF.forEach((el, index) => {
        if (!arrDatiIngressi2PDF[index]) {
          arrDatiIngressi2PDF.push({ ...parametri.datiIngressi });
        }
        parametri.settaDatiIngressi(
          arrDatiIngressi2PDF,
          index,
          {
            id: el.id_entrance,
            descrizione: el.description,
            descrizione_interna: el.internal_description,
            prezzoGruppo: el.group_price,
            prezzoPax: el.passenger_price,
            prezzoNonPag: el.passenger_free_price,
          },

          quote.payers,
          quote.free,
          true //PDF
        );
      });
    }

    parametri.setArrDatiIngressiPDF(arrDatiIngressi2PDF);

    ///trasfer
    let trans = res.quote.transfer;
    let indexSchool = -1;
    let indexLoco = -1;
    let transferLoco2 = [];
    let transferScuola2 = [];
    if (trans) {
      trans.forEach((el, index) => {
        if (el.type == "school") {
          indexSchool++;
          transferScuola2.push({});
          parametri.settaDatiTransferScuola(transferScuola2, indexSchool, {
            prezzoAndata: el.outward,
            prezzoRitorno: el.return,
            postiBus: el.n_seat,
            compagniaTrans: el.trans_company,
          });
          // parametri.settaDatiTransferScuola(
          //   transferScuola2,
          //   "prezzoRitorno",
          //   indexSchool,
          //   el.return
          // );
          // parametri.settaDatiTransferScuola(
          //   transferScuola2,
          //   "postiBus",
          //   indexSchool,
          //   el.n_seat
          // );
        } else {
          indexLoco++;
          transferLoco2.push({});
          parametri.settaDatiTransferLoco(transferLoco2, indexLoco, {
            prezzoAndata: el.outward,
            prezzoRitorno: el.return,
            postiBus: el.n_seat,
          });
        }
      });

      parametri.setTransferLoco(transferLoco2);
      parametri.setTransferScuola(transferScuola2);
    }

    ///proposte aereo
    if (res.quote.airplane?.length > 0) {
      let aereo = [];

      aereo = res.quote.airplane;

      let temp2 = [];
      let cntAereo = 0;

      aereo.forEach((el) => {
        cntAereo++;
        // console.log(el);
        temp2.push({
          vettoreA: el.vector_outward,
          partenzaA: el.outward_start,
          arrivoA: el.outward_end,
          costoPaxA: el.outward_pricePax,
          vettoreR: el.vector_return,
          locPartenza: el.location_outward,
          locRitorno: el.location_return,
          locPartenzaEnd: el.location_outward_end,
          locRitornoEnd: el.location_return_end,
          nrPartenza: el.nrPartenza,
          nrRitorno: el.nrRitorno,
          partenzaR: el.return_start,
          arrivoR: el.return_end,
          dataPartenzaA: el.outward_date_start,
          dataArrivoA: el.outward_date_end,
          dataPartenzaR: el.return_date_start,
          dataArrivoR: el.return_date_end,
          costoPaxR: el.return_pricePax,
          bagManoPartenza: el.hand_lug_outward,
          bagManoArrivo: el.hand_lug_return,
          bagManoInStivaPartenza: el.hand_lug_stiva_outward,
          bagManoInStivaArrivo: el.hand_lug_stiva_return,
          bagStivaPartenza: el.lug_outward,
          bagStivaArrivo: el.lug_return,
          compresoManoA: el.hand_lug_outward_included,
          compresoManoR: el.hand_lug_return_included,
          compresoManoInStivaA: el.hand_lug_stiva_outward_included,
          compresoManoInStivaR: el.hand_lug_stiva_return_included,
          compresoStivaA: el.lug_outward_included,
          compresoStivaR: el.lug_return_included,
          noteAereo: el.notes,
        });
      });
      parametri.setProposteAereo(temp2);

      parametri.setNumeroProposteAereo(cntAereo);
    } else {
      parametri.setProposteAereo([]);
      parametri.setNumeroProposteAereo(0);
    }

    ///proposte nave
    if (res.quote.ship?.length > 0) {
      let nave = [];

      nave = res.quote.ship;

      let temp3 = [];
      let cntNave = 0;

      nave.forEach((el) => {
        cntNave++;
        // console.log(el);
        temp3.push({
          vettoreA: el.vector_outward,
          partenzaA: el.outward_start,
          arrivoA: el.outward_end,
          costoPaxA: el.outward_pricePax,
          vettoreR: el.vector_return,
          locPartenza: el.location_outward,
          locRitorno: el.location_return,
          locPartenzaEnd: el.location_outward_end,
          locRitornoEnd: el.location_return_end,
          partenzaR: el.return_start,
          arrivoR: el.return_end,
          costoPaxR: el.return_pricePax,
          bagManoPartenza: el.hand_lug_outward,
          bagManoArrivo: el.hand_lug_return,
          bagStivaPartenza: el.lug_outward,
          bagStivaArrivo: el.lug_return,
        });
      });
      parametri.setProposteNave(temp3);

      parametri.setNumeroProposteNave(cntNave);
    } else {
      parametri.setProposteNave([]);
      parametri.setNumeroProposteNave(0);
    }

    ///treno
    if (res.quote.train?.length > 0) {
      let treno = res.quote.train;
      let temp4 = [];
      let cntProp = 0;

      treno.forEach((el) => {
        if (temp4[el.n_proposal] === undefined) {
          //pusho la sosta
          temp4[el.n_proposal] = {};
        }
        if (el.n_proposal > cntProp) {
          cntProp = el.n_proposal;
        }

        if (!temp4[el.n_proposal].treni) {
          temp4[el.n_proposal].treni = [];
        }

        temp4[el.n_proposal].treni[el.n_train] = {
          // vettore: el.vector_outward,
          partenza: el.departure,
          arrivo: el.arrival,
          dataPartenza: el.departure_date,
          dataArrivo: el.arrival_date,
          costoPax: el.pricePax,
          train_type: el.train_type,
          train_company: el.train_company,
          gratuita: el["1free"],
          max: el["gratuitaMax"],
          direction: el.direction,
          partenzaLoc: el.location_start,
          arrivoLoc: el.location_end,
        };
      });
      // console.log("zzz2", temp4, cntProp + 1);
      parametri.setProposteTreno(temp4);
      parametri.setNumeroProposteTreno(cntProp + 1);
    } else {
      parametri.setProposteTreno([]);
      parametri.setNumeroProposteTreno(0);
    }
  } else {
    //se non ci sono risultati
    parametri.ricalcolaSistemazioni([], 1, 1);
  }
  caricato = true;
  parametri.setInCaricamento((inCaricamento) => inCaricamento - 1);
  // setTimeout(() => {
  //   parametri.setPropostaCaricata(true);
  // }, 3000);
};

async function salvaPreventivo(
  apriSnackbar = false,
  parametri,
  tornaAllaLista = false,
  reload = true
) {
  parametri.setErrore(null);
  parametri.setInSalvataggio(true);

  let error = "";

  //controllo che le notti inserite nei vari step siano minori delle notti totali
  let numeroNottiCheck = [];
  parametri.arrDatiSistemazione.forEach((el, index) => {
    el.forEach((el2, index2) => {
      if (el2.notti) {
        if (numeroNottiCheck[index2]) {
          numeroNottiCheck[index2] += Number(el2.notti || 0);
        } else {
          numeroNottiCheck[index2] = Number(el2.notti || 0);
        }
      }
    });
  });

  ///numeroNottiCheck contiene il numero di notti per ogni proposta
  let numeroNottiConteggio = parametri.notti;

  if (parametri.primaNotteInMezzo) {
    ///la prima notte sul mezzo non conta
    numeroNottiConteggio--;
  }
  if (parametri.ultimaNotteInMezzo) {
    ///l'ultima notte sul mezzo non conta
    numeroNottiConteggio--;
  }

  // console.log(222, numeroNottiConteggio, numeroNottiCheck);

  numeroNottiCheck.forEach((el) => {
    if (el != numeroNottiConteggio) {
      error = "Il numero di notti nelle soste è diverso delle notti notali";
    }
    parametri.setErrore(error);
    parametri.setInSalvataggio(false);
    return;
  });

  if (error == "") {
    parametri.setErrore(false);
  }

  // console.log(error, numeroNottiCheck, parametri.notti);
  if (error) {
    return;
  }

  // console.log("nome PROPOSTA", parametri.nomeProposta, nomePropostaVar);

  let dati = {
    calcQuoteId: parametri.calcQuoteId,
    prev: {
      nomeProposta: nomePropostaVar || parametri.nomeProposta,
      totalePaganti: parametri.totalePaganti,
      totaleNonPaganti: parametri.totaleNonPaganti,
      pagantiM: parametri.pagantiM,
      pagantiF: parametri.pagantiF,
      nonPagantiM: parametri.nonPagantiM,
      nonPagantiF: parametri.nonPagantiF,
      autisti: parametri.autisti,
      gratuitiInDoppia: parametri.gratuitiInDoppia,
      gratuitiInSingola: parametri.gratuitiInSingola,
      pagantiInSingola: parametri.pagantiInSingola,
      pagantiInDoppia: parametri.pagantiInDoppia,
      totale: parametri.totaleConAutisti,
      mezzoTrasporto: parametri.mezzoTrasporto,
      giorni: parametri.giorni,
      notti: parametri.notti,
      rientro: parametri.rientro,
      partenza: parametri.partenza,
      area: parametri.area,
      numeroPreventivo: parametri.numeroPreventivo,
      id: parametri.id,
      assic_policy: parametri.policy,
      tipoNave: parametri.tipoNave.value,
      programmaCustom: parametri.programmaCustom,
      nascondiProgramma: parametri.nascondiProgramma,
      includiSimilari: parametri.includiSimilari,
      noteStampa: parametri.noteStampa,
      risposteBus: parametri.risposteBus,
      dataVerificaPrezzi: parametri.dataVerificaPrezzi || null,
      bus_in_loco: parametri.busInLoco,
      primaNotteInMezzo: parametri.primaNotteInMezzo,
      ultimaNotteInMezzo: parametri.ultimaNotteInMezzo,
    },
    sistemazioni: {
      numeroSistemazioni: parametri.numeroSistemazioni,
      numeroSoste: parametri.numeroSoste,
      sistemazioni: parametri.arrDatiSistemazione,
      sistemazioniMezzo: parametri.arrDatiSistemazioneMezzo,
    },
    extraRistorazione: !parametri.soloMezzo
      ? {
          totalePersRistorazione: parametri.totalePersRistorazione,
          prezziRistorazione: parametri.prezziRistorazione,
        }
      : null,
    guide: parametri.arrDatiGuide,
    guidePDF: parametri.arrDatiGuidePDF,
    escursioni: parametri.arrDatiEscursioni,
    escursioniPDF: parametri.arrDatiEscursioniPDF,
    ingressi: parametri.arrDatiIngressi,
    ingressiPDF: parametri.arrDatiIngressiPDF,
    transferLoco: parametri.transferLoco,
    transferScuola: parametri.transferScuola,
    riepilogoForzato: parametri.totaliRiepilogoSave,
  };

  if (parametri.isBus || parametri.isNave) {
    dati.bus = {
      datiBus: parametri.datiBus,
    };
    if (parametri.isNave) {
      dati.nave = parametri.proposteNave;
    }
  } else if (parametri.isAereo) {
    dati.aereo = parametri.proposteAereo;
  } else if (parametri.isTreno) {
    dati.treno = parametri.proposteTreno;
  }

  // console.log(dati);
  // return;

  const res = await monFetch("/quotePriceSet", dati);
  if (res.success) {
    parametri.setCalcQuoteId(res.calc_quote_id);
    parametri.setInSalvataggio(false);
    // parametri.setSalvato(true);
    if (apriSnackbar) {
      // apri una notifica di salvatggio
      parametri.setMessaggioSnackbar("Salvataggio effettuato");
      parametri.setApriSnackbar(true);
    }
    if (!reload) {
      ///no redirect
    } else {
      if (!tornaAllaLista) {
        setTimeout(() => {
          window.location.href = "/calcoloPreventivo/" + parametri.id;
        }, 1000);
      }

      if (tornaAllaLista || !parametri.calcQuoteId) {
        window.location.href = "/preventivi/";
      }
    }

    parametri.queryListaPropostePrev(false);
  }
}

const calcolaRiepilogo = (parametri) => {
  //calcolo dati dei riepiloghi ///////////////
  let numeroSistemazioniOkRiep = parametri.numeroSistemazioniOk;

  if (parametri.numeroSistemazioniOk == 0) {
    numeroSistemazioniOkRiep = 1;
  }
  clearTimeout(parametri.timeoutId.riepilogo);
  parametri.timeoutId.riepilogo = setTimeout(() => {
    if (!parametri.totalePaganti) {
      return;
    }
    let totaleTemp = 0;
    totaleTemp =
      Number(parametri.totalePersBus) +
      Number(parametri.totalePersRistorazione) +
      Number(parametri.totalePersGuide) +
      Number(parametri.totalePersEscursioni) +
      Number(parametri.totalePersIngressi) +
      Number(parametri.totaleTransferLoco) +
      Number(parametri.totaleTransferScuola);

    let temp = [];
    if (parametri.numeroProposteAereo > 0) {
      for (let i = 0; i < parametri.numeroProposteAereo; i++) {
        temp.push([]);
        for (let j = 0; j < numeroSistemazioniOkRiep; j++) {
          temp[i].push({
            nome: "Aereo " + Number(i + 1) + " - Prop. " + Number(j + 1),
          });
        }
      }
    } else if (parametri.numeroProposteTreno > 0) {
      for (let i = 0; i < parametri.numeroProposteTreno; i++) {
        temp.push([]);
        for (let j = 0; j < numeroSistemazioniOkRiep; j++) {
          temp[i].push({
            nome: "Treno " + Number(i + 1) + " - Prop. " + Number(j + 1),
          });
        }
      }
    } else if (parametri.numeroProposteNave > 0) {
      for (let i = 0; i < parametri.numeroProposteNave; i++) {
        temp.push([]);
        for (let j = 0; j < numeroSistemazioniOkRiep; j++) {
          temp[i].push({
            nome: "Nave " + Number(i + 1) + " - Prop. " + Number(j + 1),
          });
        }
      }
    } else {
      // viaggio in bus
      for (let i = 0; i < 1; i++) {
        temp.push([]);
        for (let j = 0; j < numeroSistemazioniOkRiep; j++) {
          temp[i].push({ nome: "Bus - Prop. " + Number(j + 1) });
        }
      }
    }
    // loop sui mezzi
    temp.forEach((elMezzo, indexM) => {
      let totaleMezzo = 0;
      totaleMezzo += Number(
        parametri.proposteAereo.length > 0
          ? parametri.proposteAereo[indexM]?.totaleAPagante || 0
          : 0
      );
      totaleMezzo += Number(
        parametri.proposteTreno.length > 0
          ? parametri.proposteTreno[indexM]?.totaleAPagante || 0
          : 0
      );
      totaleMezzo += Number(
        parametri.proposteNave.length > 0
          ? parametri.proposteNave[indexM]?.totaleAPagante || 0
          : 0
      );

      // console.log(
      //   "TOT MEZZO",
      //   totaleMezzo,
      //   proposteAereo,
      //   proposteTreno,
      //   proposteNave
      // );
      // loop sulle sistemazioni --- il livello 1 è il mezzo (es. aereo 1, 2, 3), il secondo sono le sistemazioni ---
      elMezzo.forEach((elSist, indexS) => {
        let totaleAlloggi = 0;
        // let totaleViaggio = totaleMezzo;

        parametri.arrDatiSistemazione.forEach((el2, indexSist) => {
          totaleAlloggi += Number(el2[indexS]?.totaleAPagante);
        });

        // totaleAlloggi += Number(arrDatiSistemazione[indexS]?.totaleAPagante);

        ///netto = totale alloggi + totale mezzi (no bus) + tutti gli altri totali
        elSist.netto = parametri.arrotonda(
          (totaleTemp || 0) + (totaleAlloggi || 0) + (totaleMezzo || 0)
        );

        // console.log(
        //   "RIEPILOGO NETTO",
        //   elSist.netto,
        //   totaleTemp,
        //   totaleAlloggi,
        //   totaleMezzo
        // );

        let valorePerTotale = (elSist.lordo = Math.ceil(
          Number(elSist.netto) + Number(parametri.markup)
        ));
        /////se totale forzato aggiorno lordo tot di conseguenza
        if (
          parametri.totaliRiepilogoSave[indexM] &&
          parametri.totaliRiepilogoSave[indexM][indexS] &&
          parametri.totaliRiepilogoSave[indexM][indexS].forzato > 0
        ) {
          valorePerTotale =
            parametri.totaliRiepilogoSave[indexM][indexS].forzato;
        } else if (
          parametri.totaliRiepilogoSave[indexM] &&
          parametri.totaliRiepilogoSave[indexM][indexS] &&
          parametri.totaliRiepilogoSave[indexM][indexS].automatico > 0
        ) {
          valorePerTotale =
            parametri.totaliRiepilogoSave[indexM][indexS].automatico;
        } else if (
          parametri.totaliRiepilogoDB[indexM] &&
          parametri.totaliRiepilogoDB[indexM][indexS] &&
          parametri.totaliRiepilogoDB[indexM][indexS].totale > 0
        ) {
          valorePerTotale = parametri.totaliRiepilogoDB[indexM][indexS].totale;
        }

        elSist.lordoForzato = Number(valorePerTotale);
        elSist.lordoTot = Math.ceil(valorePerTotale * parametri.totalePaganti);
        elSist.muPercent = parametri.arrotonda(
          (Number(parametri.markup) / Number(elSist.lordo)) * 100
        );

        // console.log("MARKUP RIEPILOGO", markup);
        if (!Number(elSist.muPercent)) {
          elSist.muPercent = 0;
        }

        //margine riepilogo
        elSist.margine = parametri.arrotonda(
          parametri.markup * parametri.totalePaganti
        );
        //

        elSist.totTrasporti = Math.ceil(
          Number(parametri.totalePersBus) + Number(totaleMezzo)
        );
        elSist.totTrasportiTot = Math.ceil(
          elSist.totTrasporti * parametri.totalePaganti
        );
        //
        elSist.totHotel = Math.ceil(totaleAlloggi);
        elSist.totHotelTot = Math.ceil(
          elSist.totHotel * parametri.totalePaganti
        );
        //
        elSist.totServizi = Math.ceil(
          elSist.lordo - elSist.totHotel - elSist.totTrasporti
        );

        if (elSist.totServizi < 0) {
          elSist.totServizi = 0;
        }
        elSist.totServiziTot = Math.ceil(
          elSist.totServizi * parametri.totalePaganti
        );
      });
    });

    parametri.setArrDatiRiepilogo(temp);

    //inizializzo riepilogo forzato
    // inizializzaRiepilogoForzato([...temp]);
  }, 500);
};

const Caricamento = memo((props) => {
  if (!props.inCaricamento) {
    return null;
  }
  return (
    <div className="caricamento">
      <CircularProgress
        variant="determinate"
        value={75}
        className="rotationE"
      />
    </div>
  );
});

const Comp = (props) => {
  // console.log("RENDER")

  let params = useParams();
  let id = null;
  let idProposta = null;

  const [apriSnackbar, setApriSnackbar] = useState(false);
  const [messaggioSnackbar, setMessaggioSnackbar] = useState("");

  if (params.id) {
    id = params.id;
  }

  if (params.idProposta) {
    idProposta = params.idProposta;
  }

  let parDuplica = new URLSearchParams(window.location.search).get("duplica");

  const [inCaricamento, setInCaricamento] = useState(0);
  const [timeoutId, setTimeoutId] = useState({
    rist: null,
    guide: null,
    escurs: null,
    ingressi: null,
    transScuola: null,
    transLoco: null,
    riepilogo: null,
  });

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  const chiudiSnackbar = (event, reason) => {
    setApriSnackbar(false);
  };

  const [statoPreventivo, setStatoPreventivo] = useState(0);

  const [calcQuoteId, setCalcQuoteId] = useState(null);

  const [tipoViaggio, setTipoViaggio] = useState(0);

  let soloLand = false;
  let soloMezzo = false;
  ///true se sono loggato come autista
  const [isAutista, setIsAutista] = useState(false);

  const [inserimentoNuovo, setInserimentoNuovo] = useState(false);
  const [inSalvataggio, setInSalvataggio] = useState(false);

  const [isBus, setIsBus] = useState(false);
  const [isAereo, setIsAereo] = useState(false);
  const [isTreno, setIsTreno] = useState(false);
  const [isNave, setIsNave] = useState(false);

  //opzioni proposte associate al preventivo
  const [optionsProposteCalcolo, setOptionsProposteCalcolo] = useState([]);

  const [optionsNave, setOptionsNave] = useState([
    {
      label: "Bus al seguito",
      value: "busSI",
    },
    {
      label: "Bus non al seguito",
      value: "busNO",
    },
  ]);

  const [tipoNave, setTipoNave] = useState(optionsNave[0]);

  const [numeroSistemazioni, setNumeroSistemazioni] = useState(1);
  //numero sistemazioni dopo submit
  const [numeroSistemazioniOk, setNumeroSistemazioniOk] = useState(1);
  const [numeroSoste, setNumeroSoste] = useState(1);

  // const [insegnantiPaganti, setInsegnantiPaganti] = useState(0);
  // const [insegnantiNonPaganti, setInsegnantiNonPaganti] = useState(0);
  // const [genitoriPaganti, setGenitoriPaganti] = useState(0);
  // const [genitoriNonPaganti, setGenitoriNonPaganti] = useState(0);
  // const [studenti, setStudenti] = useState(0);
  // const [studentiConDisabilita, setStudentiConDisabilita] = useState(0);

  const [mezziTrasportoPrev, setMezziTrasportoPrev] = useState([]);
  const [mezzoTrasporto, setMezzoTrasporto] = useState(null);
  const [optionsTrasporti, setOptionsTrasporti] = useState([]);

  // console.log(mezzoTrasporto)
  const [area, setArea] = useState("Italia");
  const [optionsArea, setOptionsArea] = useState(["Italia", "Europa", "Mondo"]);

  const [numeroPreventivo, setNumeroPreventivo] = useState("");
  const [numeroPreventivoKreo, setNumeroPreventivoKreo] = useState("");
  const [destinazione, setDestinazione] = useState("");
  const [nomeProposta, setNomeProposta] = useState("");

  const [dataPreventivo, setDataPreventivo] = useState("");
  const [dataUpdate, setDataUpdate] = useState("");
  const [totalePaganti, setTotalePaganti] = useState(1);
  const [totaleNonPaganti, setTotaleNonPaganti] = useState(0);

  const [pagantiM, setPagantiM] = useState(0);
  const [pagantiF, setPagantiF] = useState(0);
  const [nonPagantiM, setNonPagantiM] = useState(0);
  const [nonPagantiF, setNonPagantiF] = useState(0);

  const [totaleConAutisti, setTotaleConAutisti] = useState(1);
  const [totaleSenzaAutisti, setTotaleSenzaAutisti] = useState(0);
  // const [importoTotale, setImportoTotale] = useState(0);

  const [gratuitiInDoppia, setGratuitiInDoppia] = useState(0);
  const [gratuitiInSingola, setGratuitiInSingola] = useState(0);

  const [pagantiInDoppia, setPagantiInDoppia] = useState(0);
  const [pagantiInSingola, setPagantiInSingola] = useState(0);

  const [giorni, setGiorni] = useState(0);
  const [notti, setNotti] = useState(0);
  const [istituto, setIstituto] = useState("");
  const [istitutoId, setIstitutoId] = useState("");
  const [rientro, setRientro] = useState("");
  const [addetto, setAddetto] = useState("");
  const [addettoConferma, setAddettoConferma] = useState("");
  const [addettoRiquotazione, setAddettoRiquotazione] = useState("");
  const [partenza, setPartenza] = useState("");

  const [autisti, setAutisti] = useState(0);
  const [propostaControllata, setPropostaControllata] = useState(false);
  const [assicurazioni, setAssicurazioni] = useState([]);

  const [numeroBus, setNumeroBus] = useState(0);
  const [datiBus, setDatiBus] = useState([]);
  const [spesePasti, setSpesePasti] = useState(0);
  const [totalePersBus, setTotalePersBus] = useState(0);

  const [prezziRistorazione, setPrezziRistorazione] = useState([]);
  const [prezziRistorazioneDB, setPrezziRistorazioneDB] = useState(null);
  // const [free1Ristorazione, setFree1Ristorazione] = useState(100);
  const [totalePersRistorazione, setTotalePersRistorazione] = useState(0);

  const [arrDatiSistemazione, setArrDatiSistemazione] = useState([]);
  const [arrDatiSistemazioneMezzo, setArrDatiSistemazioneMezzo] = useState([]);

  const [arrDatiGuide, setArrDatiGuide] = useState([]);
  const [arrDatiGuidePDF, setArrDatiGuidePDF] = useState([]);
  const [totalePersGuide, setTotalePersGuide] = useState(0);

  const [arrDatiEscursioni, setArrDatiEscursioni] = useState([]);
  const [arrDatiEscursioniPDF, setArrDatiEscursioniPDF] = useState([]);
  const [totalePersEscursioni, setTotalePersEscursioni] = useState(0);

  const [arrDatiIngressi, setArrDatiIngressi] = useState([]);
  const [arrDatiIngressiPDF, setArrDatiIngressiPDF] = useState([]);
  const [totalePersIngressi, setTotalePersIngressi] = useState(0);

  const [arrDatiRiepilogo, setArrDatiRiepilogo] = useState([]);
  const [totaliRiepilogoDB, setTotaliRiepilogoDB] = useState([]);
  const [totaliRiepilogoSave, setTotaliRiepilogoSave] = useState([]);

  const [transferScuola, setTransferScuola] = useState([]);
  const [totaleTransferScuola, setTotaleTransferScuola] = useState(0);

  const [transferLoco, setTransferLoco] = useState([]);
  const [totaleTransferLoco, setTotaleTransferLoco] = useState(0);

  const [numeroProposteAereo, setNumeroProposteAereo] = useState(0);
  const [numeroProposteNave, setNumeroProposteNave] = useState(0);
  const [numeroProposteTreno, setNumeroProposteTreno] = useState(0);
  const [dataVerificaPrezzi, setDataVerificaPrezzi] = useState("");

  const [proposteAereo, setProposteAereo] = useState([]);
  const [proposteNave, setProposteNave] = useState([]);
  const [proposteTreno, setProposteTreno] = useState([]);

  const [gratuitaTreno, setGratuitaTreno] = useState(0);

  const [markup, setMarkup] = useState(0);

  const [optionsPolicy, setOptionsPolicy] = useState([]);
  const [policy, setPolicy] = useState(null);
  const [policyNome, setPolicyNome] = useState("");
  const [policyValue, setPolicyValue] = useState(null);
  const [tipiPolicy, setTipiPolicy] = useState("");

  const [optHotel, setOptHotel] = useState({});
  const [includiSimilari, setIncludiSimilari] = useState(true);

  // const [salvato, setSalvato] = useState(false);
  const [errore, setErrore] = useState(false);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [popupDuplica, setPopupDuplica] = useState(false);

  const [programmaCustom, setProgrammaCustom] = useState("");
  const [nascondiProgramma, setNascondiProgramma] = useState("");
  const [noteStampa, setNoteStampa] = useState("");
  const [noteBus, setNoteBus] = useState("");
  const [risposteBus, setRisposteBus] = useState("");

  const [vettoriAereo, setVettoriAereo] = useState([]);

  ////uso questo dato per triggerare autosave
  // const [propostaCaricata, setPropostaCaricata] = useState(false);

  const [costoPranzoAutista, setCostoPranzoAutista] = useState(0);

  const [popupAggiungiOpen, setPopupAggiungiOpen] = useState(false);
  const [popupAggiungiTipo, setPopupAggiungiTipo] = useState("");

  const [popupMailOpen, setPopupMailOpen] = useState(false);
  const [popupSpostaPropostaOpen, setPopupSpostaPropostaOpen] = useState(false);

  const [popupDettaglioCliente, setPopupDettaglioCliente] = useState(false);

  const [inserisciExtraPDF, setInserisciExtraPDF] = useState(false);

  const [proposteControllate, setProposteControllate] = useState(false);

  const [contrattoInviato, setContrattoInviato] = useState({});

  const [busInLoco, setBusInLoco] = useState(false);
  const [primaNotteInMezzo, setPrimaNotteInMezzo] = useState(false);
  const [ultimaNotteInMezzo, setUltimaNotteInMezzo] = useState(false);

  const datiSistemazione = {
    hotel: "",
    localita: "",
    suppSingola: 0,
    suppDoppia: 0,
    trattamento: "",
    gratuitaBB: 0,
    gratuitaHB: 0,
    gratuitaPasto: 0,
    notti: 0,
    costoNotteBB: 0,
    costoNotteHB: 0,
    costoNottePasto: 0,
    totaleAPagante: 0,
    descrizione: "",
  };

  const datiGuide = {
    localita: "",
    guidaPers: 1,
    halfDay: 0,
    fullDay: 0,
    totale: 0,
  };

  const datiEscursioni = {
    localita: "",
    maxPax: 1,
    euroGruppo: 0,
    euroPax: 0,
    totale: 0,
  };

  const datiIngressi = {
    descrizione: "",
    descrizione_interna: "",
    prezzoPax: 0,
    prezzoGruppo: 0,
    totale: 0,
  };

  const datiTransfer = {
    prezzoAndata: 0,
    prezzoRitorno: 0,
    totalePers: 0,
    totale: 0,
  };

  async function duplicaPreventivo() {
    window.location.href =
      "/calcoloPreventivo/" + id + "?duplica=" + calcQuoteId;
    return;

    // setNomeProposta("Proposta " + (optionsProposteCalcolo.length + 1));
    // nomePropostaVar = "Proposta " + (optionsProposteCalcolo.length + 1);
    // setCalcQuoteId(null);
    // setPopupDuplica(false);
  }

  async function getCostoPranzoAutista() {
    const res = await monFetch("/priceMealDriver", {});
    if (res.success) {
      setCostoPranzoAutista(res.res);
    }
  }

  async function eliminaPreventivo(idEl) {
    const res = await monFetch("/quotePriceDelete", { id: idEl });
    if (res.success) {
      setPopupElimina(false);
      // console.log(window.location.origin + window.location.pathname);
      window.location.href = "/calcoloPreventivo/" + id;
    }
  }
  // console.log(window.location)

  function syncMaschiFemmine(maschi, femmine, tipo) {
    maschi = Number(maschi);
    femmine = Number(femmine);
    if (tipo == "paganti") {
      let max = totalePaganti;
      let min = 0;
      if (maschi > max) {
        maschi = max;
      }
      if (maschi < min) {
        maschi = min;
      }
      if (femmine > max) {
        femmine = max;
      }
      if (femmine < min) {
        femmine = min;
      }

      setPagantiM(maschi);
      setPagantiF(femmine);
    } else if (tipo == "nonPaganti") {
      let max = totaleNonPaganti;
      let min = 0;
      if (maschi > max) {
        maschi = max;
      }
      if (maschi < min) {
        maschi = min;
      }
      if (femmine > max) {
        femmine = max;
      }
      if (femmine < min) {
        femmine = min;
      }
      setNonPagantiM(maschi);
      setNonPagantiF(femmine);
    }
  }

  async function queryDettagliCliente(customer_id) {
    const res = await monFetch("/customerGet", {
      id: customer_id,
    });
    if (res.success) {
      setNome(res.customer.first_name || "");
      setCognome(res.customer.last_name || "");
      setNomeAzienda(res.customer.companyName || "");
      setCF(res.customer.fiscalCode || "");
      setIndirizzo(res.customer.address || "");
      setCitta(res.customer.city || "");
      setProvincia(res.customer.state || "");
      setCAP(res.customer.postalCode || "");
      setTelefono(res.customer.phone || "");
      setCellulare(res.customer.mobilePhone || "");

      setPopupDettaglioCliente(true);
    }
  }

  async function queryMarkup() {
    const res = await monFetch("/markupGet", {
      transport_id: mezzoTrasporto?.value,
      paganti: totalePaganti,
      quoteDate: dataPreventivo ? dataPreventivo.split(" ")[0] : null,
    });
    setMarkup(res.markup);
  }

  const allineaDatiGuideEcc = (paganti, nonPaganti) => {
    paganti = Number(paganti);
    nonPaganti = Number(nonPaganti);

    // console.log(
    //   paganti,
    //   nonPaganti,
    //   paganti + nonPaganti,
    //   arrDatiGuide,
    //   arrDatiEscursioni,
    //   arrDatiIngressi
    // );

    /////allineo totali guide al cambio dei paganti
    arrDatiGuide.forEach((el, index) => {
      settaDatiGuide(arrDatiGuide, index, {}, paganti + nonPaganti);
    });
    /////allineo totali ingressi al cambio dei paganti
    arrDatiIngressi.forEach((el, index) => {
      settaDatiIngressi(arrDatiIngressi, index, {}, paganti, nonPaganti);
    });
    /////allineo totali escursioni al cambio dei paganti
    arrDatiEscursioni.forEach((el, index) => {
      settaDatiEscursioni(arrDatiEscursioni, index, {}, paganti + nonPaganti);
    });
  };

  async function checkProposteControllate() {
    //controllo se tutte le proposte per quel preventivo son controllate
    const res = await monFetch("/checkProposteControllate", {
      quoteId: id,
    });
    setProposteControllate(res.controllate);
  }

  function generaSistemazioniMezzo(indexFn, value, tipo) {
    let temp = [...arrDatiSistemazioneMezzo];
    console.log("inizio", temp);
    ///cerco se presente item di andata / ritorno
    let item = null;
    temp.forEach((el) => {
      el.forEach((el2) => {
        // console.log(el2.indice, index)
        if (el2.indice == indexFn) {
          item = el;
        }
      });
    });
    // console.log(111, item)

    if (!item) {
      ///se elemento non presente
      if (value) {
        temp.push([]);
      }
    } else {
      if (value) {
        item = [];
      } else {
        // console.log("delete", temp[temp.indexOf(item)])
        temp.splice(temp.indexOf(item), 1);
      }
    }

    ////per ogni proposta di sistemazione creo una proposta di sistemazione per il mezzo
    for (let i = 0; i < numeroSistemazioni; i++) {
      temp.forEach((el, index) => {
        if (!el[i]) {
          temp[index][i] = {
            tipo: tipo,
            indice: indexFn,
            hotel: "Notte in " + mezzoTrasporto?.label,
            proposta: i,
          };
        }
      });
    }
    ///

    //ordino array per prop indice
    temp.sort((a, b) => {
      if (a[0]?.indice > b[0]?.indice) {
        return 1;
      } else {
        return -1;
      }
    });

    console.log("fine", temp);
    setArrDatiSistemazioneMezzo(temp);
  }

  useEffect(() => {
    //check se sono loggato come autista
    setIsAutista(checkAutista());
    getCostoPranzoAutista();

    queryPreventivo(parametri);
    let intNum = setInterval(() => {
      ///setto min input a 0
      document.querySelectorAll("input[type=number]").forEach((el) => {
        el.setAttribute("min", 0);
        el.setAttribute("step", "any");
      });
    }, 5000);

    setInterval(() => {
      ///setto min input a 0
      tempTrigger += 1;
    }, 500);

    ////se sono in inserimento nuovo
    let nuovo = false;
    if (window.location.search.includes("nuovo") && !calcQuoteId) {
      setInserimentoNuovo(true);
      nuovo = true;
    }

    queryOpzioniHotel();
    queryListaPropostePrev(nuovo);

    checkProposteControllate();

    return () => clearInterval(intNum);
  }, []);

  useEffect(() => {
    queryAssicPolicy();
    queryListaPrezzi("catering");
  }, [dataPreventivo]);

  useEffect(() => {
    let barraTop = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "100%",
          }}
        >
          <h2>
            {isBus && (
              <img src="/img/icona-bus.png" className="imgTitolo"></img>
            )}
            {isAereo && (
              <img src="/img/icona-aereo.png" className="imgTitolo"></img>
            )}
            {isNave && (
              <img src="/img/icona-nave.png" className="imgTitolo"></img>
            )}
            {isTreno && (
              <img src="/img/icona-treno.png" className="imgTitolo"></img>
            )}
            Preventivo
            {numeroPreventivo != "" && (
              <span className="numeroPreventivo">
                {numeroPreventivo}{" "}
                {numeroPreventivoKreo ? " - K" + numeroPreventivoKreo : ""}
              </span>
            )}
          </h2>
        </div>
        {/* selettore delle proposte (pagine calcolo) legate al preventivo */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // width: "100%",
            gap: 6,
          }}
        >
          {/* //conteggio delle proposte di calcolo */}
          {
            <div className="conteggioProposteCalc">
              {optionsProposteCalcolo.length}
            </div>
          }

          {id && (
            <>
              <Autocomplete
                value={
                  optionsProposteCalcolo.find((el) => {
                    return el.value == calcQuoteId;
                  })
                    ? optionsProposteCalcolo.find((el) => {
                        return el.value == calcQuoteId;
                      })
                    : null
                }
                onChange={(e, value) => {
                  window.location.href =
                    "/calcoloPreventivo/" + id + "/" + value.value;
                  // setPropostaCaricata(false);
                  clearTimeout(timeoutSave);
                }}
                options={optionsProposteCalcolo}
                style={{ width: "260px" }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    className="inputCustom"
                  />
                )}
              />

              {!isAutista && (
                <Button
                  variant="contained"
                  className=""
                  onClick={(e) => {
                    ////carico la pagina in modalità inserimento nuovo
                    window.location.href =
                      "/calcoloPreventivo/" + id + "?nuovo";
                  }}
                  title="Aggiungi nuovo"
                >
                  <AddIcon />
                </Button>
              )}
              <Button
                variant="contained"
                style={{ whiteSpace: "nowrap" }}
                href={"/preventivo/" + id}
                title="Vai al preventivo"
              >
                <ArticleIcon />
              </Button>
              <Button
                variant="contained"
                style={{ whiteSpace: "nowrap" }}
                href={"/preventivo/media/" + id}
                title="Allegati"
              >
                <AttachFileIcon />
              </Button>
              {!isAutista && (
                <Button
                  variant="contained"
                  className="bottoneCopia"
                  onClick={(e) => {
                    setPopupDuplica(true);
                  }}
                  title="Duplica proposta"
                >
                  <ContentCopyIcon />
                </Button>
              )}

              {calcQuoteId && (
                <Button
                  variant="contained"
                  className="bottoneCopia"
                  onClick={(e) => {
                    setPopupSpostaPropostaOpen(true);
                  }}
                  title="Sposta proposta"
                >
                  <ShortcutIcon />
                </Button>
              )}

              {calcQuoteId && !isAutista && (
                <Button
                  variant="contained"
                  // style={{ marginLeft: 15 }}
                  target="_blank"
                  href={
                    process.env.REACT_APP_BACKEND +
                    "/generaPDF?id=" +
                    calcQuoteId
                  }
                  title="Genera PDF"
                >
                  <PictureAsPdfIcon />
                </Button>
              )}

              {statoPreventivo == 50 && (
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setPopupMailOpen(true);
                  }}
                  title="Email tour operator"
                >
                  <EmailIcon />
                </Button>
              )}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",

            gap: 6,
          }}
        >
          <Button
            variant="contained"
            disabled={inSalvataggio}
            className="bottoneSalva"
            onClick={(e) => {
              e.target.disabled = true;
              ////copiato in useEffect al cambio del riepilogo
              salvaPreventivo(true, parametri, false, false);

              setTimeout(() => {
                e.target.disabled = false;
              }, 1000);
            }}
          >
            {!inSalvataggio ? "Salva" : <CircularProgress size={20} />}
          </Button>

          <Button
            variant="contained"
            className="bottoneAzione"
            onClick={(e) => {
              window.print();
            }}
            title="Stampa"
          >
            <PrintIcon />
          </Button>
        </div>
      </>
    );

    props.setElementiBarraTop(barraTop);
  }, [
    isBus,
    isTreno,
    isNave,
    isAereo,
    calcQuoteId,
    inSalvataggio,
    nomeProposta,
    arrDatiRiepilogo,
    tempTrigger,
  ]);

  // const [numeroGuide, setNumeroGuide] = useState(3);

  const settaDatiSistemazione = (array, key, index, index2, value) => {
    let temp = [...array];
    temp[index][index2][key] = value;

    setArrDatiSistemazione(temp);
  };

  const settaDatiSistemazioneMezzo = (array, key, index, index2, value) => {
    let temp = [...array];
    temp[index][index2][key] = value;

    setArrDatiSistemazioneMezzo(temp);
  };

  const settaDatiProposteAereo = (array, objValues, index) => {
    let temp = [...array];
    for (const property in objValues) {
      // console.log(`${property}: ${object[property]}`);
      temp[index][property] = objValues[property];
    }
    setProposteAereo(temp);
  };

  const settaDatiProposteNave = (array, objValues, index) => {
    let temp = [...array];
    for (const property in objValues) {
      // console.log(`${property}: ${object[property]}`);
      temp[index][property] = objValues[property];
    }
    setProposteNave(temp);
  };

  const settaDatiProposteTreno = (array, objValues, index, index2 = null) => {
    let temp = [...array];
    for (const property in objValues) {
      // console.log(`${property}: ${object[property]}`);
      if (index2 === null) {
        temp[index][property] = objValues[property];
      } else {
        temp[index].treni[index2][property] = objValues[property];
      }
    }
    setProposteTreno(temp);
  };

  const settaDatiEscursioni = (
    array,
    index,
    values,
    totaleSenzaAutistiFn = null,
    pdf = false
  ) => {
    // let temp = [...array];
    // temp[index][key] = value;
    // console.log(temp, index, value);
    if (!totaleSenzaAutistiFn) {
      totaleSenzaAutistiFn = totaleSenzaAutisti;
    }

    let temp = [...array];
    for (let key in values) {
      temp[index][key] = values[key];
    }
    ////calcolo totale
    let elem = temp[index];

    elem.totale =
      Math.ceil(totaleSenzaAutistiFn / elem.maxPax) * Number(elem.euroGruppo) +
      Number(elem.euroPax) * totaleSenzaAutistiFn;

    elem.totale = arrotonda(Number(elem.totale));

    if (!pdf) {
      setArrDatiEscursioni(temp);
    } else {
      setArrDatiEscursioniPDF(temp);
    }
  };

  const settaDatiGuide = (
    array,
    index,
    values,
    totaleSenzaAutistiFn = null,
    pdf = false
  ) => {
    if (!totaleSenzaAutistiFn) {
      totaleSenzaAutistiFn = totaleSenzaAutisti;
    }
    let temp = [...array];
    for (let key in values) {
      temp[index][key] = values[key];
    }

    ////calcolo totale
    let elem = temp[index];

    elem.totale =
      Math.ceil(totaleSenzaAutistiFn / elem.guidaPers) *
        (Number(elem.fullDay) + Number(elem.halfDay)) +
      Number(elem.auricolari || 0) * totaleSenzaAutistiFn;
    elem.totale = arrotonda(Number(elem.totale));
    // console.log("settaDatiGuide", elem.totale, elem, totaleSenzaAutistiFn);
    if (!pdf) {
      setArrDatiGuide(temp);
    } else {
      setArrDatiGuidePDF(temp);
    }
  };

  const settaDatiTransferLoco = (array, index, values) => {
    // let temp = [...array];
    // temp[index][key] = value;
    let temp = [...array];
    for (let key in values) {
      temp[index][key] = values[key];
    }
    // console.log(temp, index, value);
    if (values.prezzoAndata && !values.prezzoRitorno && caricato) {
      temp[index]["prezzoRitorno"] = temp[index]["prezzoAndata"];
    }

    ////calcolo totale
    let elem = temp[index];
    // // console.log(elem)
    elem.totale = arrotonda(
      Number(elem["prezzoAndata"]) + Number(elem["prezzoRitorno"])
    );

    elem.totalePers = arrotonda(elem.totale / totaleConAutisti);

    setTransferLoco(temp);
  };

  const settaDatiTransferScuola = (array, index, values) => {
    // let temp = [...array];
    // temp[index][key] = value;

    let temp = [...array];
    for (let key in values) {
      temp[index][key] = values[key];
    }

    if (values.prezzoAndata && !values.prezzoRitorno && caricato) {
      temp[index]["prezzoRitorno"] = temp[index]["prezzoAndata"];
    }
    // if (key == "prezzoAndata") {
    //   temp[index]["prezzoRitorno"] = value;
    // }

    ////calcolo totale
    let elem = temp[index];
    // // console.log(elem)
    elem.totale = arrotonda(
      Number(elem["prezzoAndata"]) + Number(elem["prezzoRitorno"])
    );

    elem.totalePers = arrotonda(elem.totale / totaleConAutisti);

    setTransferScuola(temp);
  };

  const settaDatiIngressi = (
    array,
    index,
    values,
    totalePaganti,
    totaleNonPaganti,
    pdf = false
  ) => {
    let temp = [...array];
    for (let key in values) {
      temp[index][key] = values[key];
    }
    ////calcolo totale
    let elem = temp[index];

    elem.totale =
      Number(totalePaganti) * Number(elem.prezzoPax || 0) +
      Number(totaleNonPaganti) * Number(elem.prezzoNonPag || 0) +
      Number(elem.prezzoGruppo || 0);
    // console.log("ingressi", temp, totalePaganti, totaleNonPaganti);
    if (!pdf) {
      setArrDatiIngressi(temp);
    } else {
      setArrDatiIngressiPDF(temp);
    }
  };

  const settaDatiPrezziRist = (array, key, index, value) => {
    let temp = [...array];
    temp[index][key] = value;
    // console.log(temp, tempObj, index, value);
    // console.log(2222, value);
    setPrezziRistorazione(temp);
  };

  const settaDatiRiepilogo = (array, key, index1, index2, value) => {
    // console.log("zzz", array, key, index1, index2, value);
    let temp = [...array];
    temp[index1][index2][key] = value;

    setArrDatiRiepilogo(temp);
  };

  const eliminaGuida = (index) => {
    let temp = [...arrDatiGuide];
    temp.splice(index, 1);
    setArrDatiGuide(temp);
  };

  const copiaGuida = (index) => {
    let temp = [...arrDatiGuide];

    temp.splice(index, 0, { ...temp[index] });
    // console.log(temp);
    setArrDatiGuide(temp);
  };

  const eliminaTransferLoco = (index) => {
    let temp = [...transferLoco];
    temp.splice(index, 1);
    setTransferLoco(temp);
  };

  const eliminaTransferScuola = (index) => {
    let temp = [...transferScuola];
    temp.splice(index, 1);
    setTransferScuola(temp);
  };

  const eliminaEscursione = (index) => {
    let temp = [...arrDatiEscursioni];
    temp.splice(index, 1);
    setArrDatiEscursioni(temp);
  };

  const eliminaIngresso = (index) => {
    let temp = [...arrDatiIngressi];
    temp.splice(index, 1);
    setArrDatiIngressi(temp);
  };

  const eliminaTreno = (index, index2) => {
    let temp = [...proposteTreno];
    temp[index].treni.splice(index2, 1);
    setProposteTreno(temp);
  };

  const eliminaSosta = (indiceSosta) => {
    let temp = [...arrDatiSistemazione];
    temp.splice(indiceSosta, 1);
    setArrDatiSistemazione(temp);
  };

  const eliminaPropostaAereo = (indice) => {
    let temp = [...proposteAereo];
    temp.splice(indice, 1);
    setProposteAereo(temp);
    setNumeroProposteAereo(numeroProposteAereo - 1);
  };

  const eliminaPropostaNave = (indice) => {
    let temp = [...proposteNave];
    temp.splice(indice, 1);
    setProposteNave(temp);
    setNumeroProposteNave(numeroProposteNave - 1);
  };

  const eliminaPropostaTreno = (indice) => {
    let temp = [...proposteTreno];
    temp.splice(indice, 1);
    setProposteTreno(temp);
    setNumeroProposteTreno(numeroProposteTreno - 1);
  };

  const eliminaProposta = (indiceProposta) => {
    let temp = [...arrDatiSistemazione];
    ///ciclo le sistemazioni e ne elimino la proposta selezionata
    temp.forEach((el) => {
      el.splice(indiceProposta, 1);
    });
    setArrDatiSistemazione(temp);
    if (numeroSistemazioni > 1) {
      ricalcolaSistemazioni(
        arrDatiSistemazione,
        numeroSoste,
        numeroSistemazioni - 1
      );
      setNumeroSistemazioni(numeroSistemazioni - 1);
    }
  };

  const ricalcolaSistemazioni = (
    arrDatiSistemazione,
    numeroSoste,
    numeroSistemazioni
  ) => {
    if (numeroSoste > 5) {
      setNumeroSoste(5);
      return;
    }

    if (numeroSistemazioni > 5) {
      setNumeroSistemazioni(5);
      return;
    }

    //popolo array dati sistemazione
    let temp = [...arrDatiSistemazione];
    let tempM = [...arrDatiSistemazioneMezzo];
    // console.log("SET SOSTE", temp, numeroSistemazioni, numeroSoste);
    let elementi = arrDatiSistemazione.length;
    let elementiM = arrDatiSistemazioneMezzo[0]
      ? arrDatiSistemazioneMezzo[0].length
      : 0;
    if (numeroSoste >= elementi) {
      //aggiungo
      for (let i = 0; i < numeroSoste; i++) {
        if (temp[i]) {
          //item con quella chiave già presente
          let elementi2 = temp[i].length;
          if (numeroSistemazioni > elementi2) {
            for (let i2 = elementi2; i2 < numeroSistemazioni; i2++) {
              temp[i].push({ ...datiSistemazione });
            }
          } else if (numeroSistemazioni < elementi2) {
            for (let i3 = elementi2; i3 > numeroSistemazioni; i3--) {
              temp[i].splice(i3 - 1, 1);
            }
          }
        } else {
          //aggiungo sosta
          temp.push([]);

          //aggiungo sistemazioni
          for (let i2 = 0; i2 < numeroSistemazioni; i2++) {
            temp[i].push({ ...datiSistemazione });
          }
        }
      }
      setArrDatiSistemazione(temp);
    } else {
      ////rimuovo soste
      for (let i = elementi; i > numeroSoste; i--) {
        temp.splice(i - 1, 1);
      }
      setArrDatiSistemazione(temp);
      ricalcolaSistemazioni(temp, numeroSoste, numeroSistemazioni);
    }

    ///soste mezzo ricalcolo -------------------
    if (numeroSistemazioni >= elementiM) {
      ///aggiungo soste mezzo

      tempM.forEach((el1, index) => {
        let indice = 0;
        if (el1[0]) {
          indice = el1[0].indice;
        }
        console.log(indice);
        for (let i = 0; i < numeroSistemazioni; i++) {
          // console.log(el1[i], i, numeroSistemazioni);
          if (!el1[i]) {
            el1[i] = {
              tipo: index == 0 ? "prima" : "ultima",
              indice: indice,
              proposta: i,
            };
          }
        }
      });
      setArrDatiSistemazioneMezzo(tempM);
      ///
    } else {
      //rimuovo soste mezzo
      tempM.forEach((el1) => {
        for (let i = elementiM; i > numeroSistemazioni; i--) {
          el1.splice(i - 1, 1);
        }
      });

      console.log(tempM);
      setArrDatiSistemazioneMezzo(tempM);
    }
    //

    setNumeroSistemazioniOk(numeroSistemazioni);
  };

  useEffect(() => {
    //popolo array dati proposte aereo
    if (numeroProposteAereo > 5) {
      setNumeroProposteAereo(5);
      return;
    }

    let temp = [...proposteAereo];
    let elementi = temp.length;

    clearTimeout(timeoutId.proposteAereo);
    timeoutId.proposteAereo = setTimeout(() => {
      if (numeroProposteAereo >= elementi) {
        //aggiungo
        for (let i = 0; i < numeroProposteAereo; i++) {
          if (temp[i]) {
            //item con quella chiave già presente
          } else {
            temp.push({});
          }
          // temp.push([]);
        }
      } else {
        //tolgo
        for (let i = elementi; i > Number(numeroProposteAereo); i--) {
          // console.log(2222, (i - 1), elementi, temp[i-1]);
          temp.splice(i - 1, 1);
        }
      }
      if (numeroProposteAereo != elementi) {
        setProposteAereo(temp);
      }
    }, 500);
  }, [numeroProposteAereo, proposteAereo]);

  useEffect(() => {
    //popolo array dati proposte nave
    if (numeroProposteNave > 5) {
      setNumeroProposteNave(5);
      return;
    }

    let temp = [...proposteNave];
    let elementi = temp.length;

    clearTimeout(timeoutId.proposteNave);
    timeoutId.proposteNave = setTimeout(() => {
      if (numeroProposteNave >= elementi) {
        //aggiungo
        for (let i = 0; i < numeroProposteNave; i++) {
          if (temp[i]) {
            //item con quella chiave già presente
          } else {
            temp.push({});
          }
          // temp.push([]);
        }
      } else {
        //tolgo
        for (let i = elementi; i > Number(numeroProposteNave); i--) {
          // console.log(2222, (i - 1), elementi, temp[i-1]);
          temp.splice(i - 1, 1);
        }
      }
      if (numeroProposteNave != elementi) {
        setProposteNave(temp);
      }
    }, 500);
  }, [numeroProposteNave, proposteNave]);

  useEffect(() => {
    //popolo array dati proposte treno
    if (numeroProposteTreno > 5) {
      setNumeroProposteTreno(5);
      return;
    }

    let temp = [...proposteTreno];
    let elementi = temp.length;

    clearTimeout(timeoutId.proposteTreno);
    timeoutId.proposteTreno = setTimeout(() => {
      if (numeroProposteTreno >= elementi) {
        //aggiungo
        for (let i = 0; i < numeroProposteTreno; i++) {
          if (temp[i]) {
            //item con quella chiave già presente
          } else {
            temp.push({});
          }
          // temp.push([]);
        }
      } else {
        //tolgo
        for (let i = elementi; i > Number(numeroProposteTreno); i--) {
          // console.log(2222, (i - 1), elementi, temp[i-1]);
          temp.splice(i - 1, 1);
        }
      }
      if (numeroProposteTreno != elementi) {
        // console.log("zzz1", temp, numeroProposteTreno, elementi);
        setProposteTreno(temp);
      }
    }, 500);
  }, [numeroProposteTreno, proposteTreno]);

  async function queryListaPrezzi(tipo) {
    const res = await monFetch("/priceList", {
      key: tipo,
      quoteDate: dataPreventivo ? dataPreventivo.split(" ")[0] : null,
    });
    if (prezziRistorazioneDB) {
      setPrezziRistorazione(prezziRistorazioneDB);
    } else {
      setPrezziRistorazione(res.price);
    }
  }

  async function queryPrezzoAssic(
    tipiPolicy,
    area,
    dataPreventivo,
    giorni,
    arrDatiRiepilogo
  ) {
    // console.log("zzz2", arrDatiRiepilogo);
    const res = await monFetch("/priceGetCorrect", {
      filter: {
        key: tipiPolicy,
        area: area,
        price: arrDatiRiepilogo,
        days: giorni,
      },
      quoteDate: dataPreventivo ? dataPreventivo.split(" ")[0] : null,
    });
    return res.res;
  }

  async function queryPrezzoSingolo(tipo) {
    const res = await monFetch("/priceGet", {
      key: tipo,

      quoteDate: dataPreventivo ? dataPreventivo.split(" ")[0] : null,
    });
    return res.price;
  }

  async function queryVettoriAereo() {
    const res = await monFetch("/airplaneListVector");
    let temp = [];
    res.res.forEach((el) => {
      temp.push({
        label: el.name,
        value: el.id,
        cabin: el.cabin,
        cabin_stiva: el.cabin_stiva,
        luggage: el.luggage,
      });
    });
    setVettoriAereo(temp);
  }

  async function queryMezziTrasporto(mezziTrasportoPrev, idMezzo = null) {
    const res = await monFetch("/transportList", {});
    const trasporti = [];
    res.transport.forEach((el) => {
      //pusho se mezzo compreso tra quelli del preventivo
      if (
        mezziTrasportoPrev &&
        mezziTrasportoPrev.find((el2) => {
          return el.id == el2.transport_id;
        })
      ) {
        trasporti.push({ label: el.name, value: el.id });
      }
    });
    setOptionsTrasporti(trasporti);
    setMezzoTrasporto(trasporti[0]);
  }

  async function queryAssicPolicy() {
    const res = await monFetch("/policyList", {
      dataPreventivo: dataPreventivo,
    });
    const opt = [];
    res.res.forEach((el) => {
      opt.push({ label: el.name, value: el.id, tipi: el.types });
    });
    setOptionsPolicy(opt);
  }

  async function queryOpzioniHotel() {
    const res = await monFetch("/hotelOptions", {});
    let opt = {};
    let temp = [];
    res.types.forEach((el) => {
      temp.push({ label: el.type, value: el.id });
    });

    opt.tipi = temp;

    temp = [];
    res["1free"].forEach((el) => {
      // temp.push({ label: String(el["1free_count"]), value: el.id });
      temp.push(String(el["1free_count"]));
    });

    opt.gratuita = temp;

    temp = [];
    res.location.forEach((el) => {
      temp.push({ label: el.location, value: el.id });
    });

    opt.posizione = temp;

    temp = [];
    res.treatment.forEach((el) => {
      temp.push({ label: el.treatment, value: el.id });
    });

    opt.trattamento = temp;

    temp = [];
    res.supplier.forEach((el) => {
      temp.push({ label: el.name, value: el.id });
    });

    opt.supplier = temp;

    setOptHotel(opt);
  }
  async function queryListaPropostePrev(nuovo) {
    // setInCaricamento(true)
    const res = await monFetch("/quotePriceList", { quote_id: id });

    let opt = [];
    if (res.res?.length > 0) {
      res.res.forEach((el) => {
        opt.push({ label: el.proposal_name, value: el.id });
      });
    }

    setOptionsProposteCalcolo(opt);
    if (!nomeProposta && !calcQuoteId) {
      ////nome proposta di default
      setNomeProposta("Proposta " + (opt.length + 1));
      nomePropostaVar = "Proposta " + (opt.length + 1);
      // console.log(1, "Proposta " + (opt.length + 1), nomePropostaVar);
    }
  }

  useEffect(() => {
    let policyValue = null;
    if (optionsPolicy.length > 0) {
      let opt = optionsPolicy.find((el) => el.value == policy);
      if (opt) {
        policyValue = optionsPolicy.find((el) => el.value == policy);
        setPolicyNome(opt.label);
      }
      setPolicyValue(policyValue);
      setTipiPolicy(opt?.tipi);
    }
  }, [policy, optionsPolicy]);

  useEffect(() => {
    // console.log("mezzo", mezzoTrasporto);
    // if(!mezzoTrasporto){
    //   return;
    // }
    if (
      tipoViaggio &&
      ((mezziTrasportoPrev && mezziTrasportoPrev.length > 0) ||
        mezziTrasportoPrev === null) &&
      !inserimentoNuovo
    ) {
      // console.log("mezziTrasportoPrev", mezziTrasportoPrev);
      //se sono in duplicazione
      if (parDuplica) {
        queryPreventivoCalcolo(parDuplica, true, parametri);
      } else if (idProposta) {
        //se id proposta in url
        queryPreventivoCalcolo(idProposta, false, parametri);
      } else {
        //se non c'è id proposta
        queryPreventivoCalcolo(null, false, parametri);
      }
    }
    if (inserimentoNuovo) {
      caricato = true;
    }

    // if (mezzoTrasporto && mezzoTrasporto.value == 2) {
    //   ///se aereo query dei vettori
    //   queryVettoriAereo();
    // }
  }, [
    optionsTrasporti,
    // mezzoTrasporto
  ]);

  useEffect(() => {
    // console.log(8888, mezzoTrasporto)
    if (mezzoTrasporto?.value == 1) {
      setIsBus(true);
      setIsAereo(false);
      setIsTreno(false);
      setIsNave(false);
    } else if (mezzoTrasporto?.value == 3) {
      setIsTreno(true);
      setIsBus(false);
      setIsAereo(false);
      setIsNave(false);
      if (numeroProposteTreno == 0) {
        setNumeroProposteTreno(1);
      }
    } else if (mezzoTrasporto?.value == 2) {
      setIsAereo(true);
      setIsBus(false);
      setIsTreno(false);
      setIsNave(false);
      if (numeroProposteAereo == 0) {
        setNumeroProposteAereo(1);
      }
    } else if (mezzoTrasporto?.value == 4) {
      setIsNave(true);
      setIsAereo(false);
      setIsBus(false);
      if (numeroProposteNave == 0) {
        setNumeroProposteNave(1);
      }
      setIsTreno(false);
    }
  }, [mezzoTrasporto]);

  useEffect(() => {
    if (mezzoTrasporto?.value) {
      queryMarkup();
    }
  }, [mezzoTrasporto, totalePaganti, dataPreventivo]);

  useEffect(() => {
    //calcolo totale
    setTotaleConAutisti(
      Number(totalePaganti) + Number(totaleNonPaganti) + Number(autisti)
    );
    setTotaleSenzaAutisti(Number(totalePaganti) + Number(totaleNonPaganti));
  }, [totalePaganti, totaleNonPaganti, autisti]);

  useEffect(() => {
    setSpesePasti(autisti * giorni * costoPranzoAutista);
  }, [autisti, giorni, costoPranzoAutista]);

  useEffect(() => {
    if (!caricato) {
      return;
    }
    // console.log("zzzzz", totaleNonPaganti, autisti);
    ///aggiorno gratuiti in doppia per renderlo uguale agli autisti + non paganti
    setGratuitiInSingola(Number(autisti) + Number(totaleNonPaganti));
  }, [autisti, totaleNonPaganti]);

  useEffect(() => {
    /////////calcolo assicurazione

    //verifico se presente almeno un riepilogo
    let riepOk = false;
    if (arrDatiRiepilogo[0]) {
      if (arrDatiRiepilogo[0][0]) {
        if (arrDatiRiepilogo[0][0].lordo) {
          riepOk = true;
        }
      }
    }

    if (
      totaleConAutisti == 0 ||
      area == null ||
      tipiPolicy == null ||
      !riepOk
    ) {
      return;
    }
    async function fn() {
      let res = await queryPrezzoAssic(
        tipiPolicy,
        area,
        dataPreventivo,
        giorni,
        arrDatiRiepilogo
      );

      let temp = [];
      //livello 1
      res.forEach((el, index) => {
        temp[index] = [];
        //livello 2
        el.forEach((el2) => {
          let somma = 0;
          //prezzi assic
          el2.forEach((el3) => {
            somma += el3;
          });
          temp[index].push(somma);
        });
      });
      // console.log(99999, tmp);
      setAssicurazioni(temp);
    }

    clearTimeout(timeout);
    timeout = setTimeout(fn, 1000);
  }, [area, dataPreventivo, tipiPolicy, giorni, arrDatiRiepilogo]);

  useEffect(() => {
    //calcolo prezzo a persona extra ristorazione
    if (!totalePaganti) {
      return;
    }
    clearTimeout(timeoutId.rist);

    timeoutId.rist = setTimeout(() => {
      let temp = [...prezziRistorazione];
      let totale = 0;
      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      let totalePers = arrotonda(totale / totalePaganti);

      setTotalePersRistorazione(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [prezziRistorazione, totalePaganti, totaleNonPaganti]);

  useEffect(() => {
    //calcolo prezzo a persona guide
    if (!totalePaganti) {
      return;
    }

    clearTimeout(timeoutId.guide);
    // console.log("2222guide",arrDatiGuide,totalePaganti, totaleNonPaganti);
    timeoutId.guide = setTimeout(() => {
      let temp = [...arrDatiGuide];
      let totale = 0;

      ///resetto dati guide al cambiare del n. dei partecipanti
      // temp.forEach((el, index) => {
      //   settaDatiGuide(temp, "totale", index, "");
      // });
      ///

      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      // let totalePers = arrotonda(totale / totalePaganti);
      let totalePers = totale / totalePaganti;

      setTotalePersGuide(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [arrDatiGuide, totalePaganti, totaleNonPaganti]);

  useEffect(() => {
    //calcolo prezzo a persona escursioni
    if (!totalePaganti) {
      return;
    }
    clearTimeout(timeoutId.escurs);

    timeoutId.escurs = setTimeout(() => {
      let temp = [...arrDatiEscursioni];
      let totale = 0;

      ///resetto dati escursioni al cambiare del n. dei partecipanti
      // temp.forEach((el, index) => {
      //   settaDatiEscursioni(temp, "totale", index, "");
      // });
      ///

      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      let totalePers = totale / totalePaganti;

      setTotalePersEscursioni(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [arrDatiEscursioni, totalePaganti, totaleNonPaganti]);

  useEffect(() => {
    //calcolo prezzo a persona ingressi
    if (!totalePaganti) {
      return;
    }
    clearTimeout(timeoutId.ingressi);

    timeoutId.ingressi = setTimeout(() => {
      let temp = [...arrDatiIngressi];
      let totale = 0;
      // console.log("Ing2", temp);
      ///resetto dati ingressi al cambiare del n. dei partecipanti
      // temp.forEach((el, index) => {
      //   settaDatiIngressi(temp, "totale", index, "");
      // });
      ///

      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      let totalePers = totale / totalePaganti;

      setTotalePersIngressi(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [arrDatiIngressi, totalePaganti, totaleNonPaganti]);

  useEffect(() => {
    //calcolo prezzo a persona transfer scuola
    if (!totalePaganti) {
      return;
    }

    clearTimeout(timeoutId.transScuola);

    timeoutId.transScuola = setTimeout(() => {
      let temp = [...transferScuola];
      let totale = 0;

      // ///resetto totali al variare dei partecipanti
      // temp.forEach((el, index) => {
      //   settaDatiTransferScuola(temp, "totale", index, "");
      // });
      // //

      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      let totalePers = arrotonda(totale / totalePaganti);

      setTotaleTransferScuola(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [transferScuola, totalePaganti, totaleNonPaganti, totaleConAutisti]);

  useEffect(() => {
    //calcolo prezzo a persona transfer loco
    if (!totalePaganti) {
      return;
    }

    clearTimeout(timeoutId.transLoco);

    timeoutId.transLoco = setTimeout(() => {
      let temp = [...transferLoco];
      let totale = 0;

      // ///resetto totali al variare dei partecipanti
      // temp.forEach((el, index) => {
      //   settaDatiTransferLoco(temp, "totale", index, "");
      // });
      // //
      temp.forEach((el) => {
        totale += Number(el.totale);
      });
      let totalePers = arrotonda(totale / totalePaganti);

      setTotaleTransferLoco(totalePers);
    }, 600);

    setTimeoutId(timeoutId);
  }, [transferLoco, totalePaganti, totaleNonPaganti, totaleConAutisti]);

  useEffect(() => {
    calcolaRiepilogo(parametri);
  }, [
    totalePersBus,
    totalePersRistorazione,
    totalePersGuide,
    totalePersEscursioni,
    totalePersIngressi,
    arrDatiSistemazione,
    totaleTransferLoco,
    totaleTransferScuola,
    proposteAereo,
    proposteTreno,
    proposteNave,
    markup,
    numeroSistemazioniOk,
  ]);

  ///setto se solo land o solo mezzo
  ///7 - 12 - solo land
  ///6 - 11 - solo treno
  ///3 - 10 - solo volo
  if (tipoViaggio == 7 || tipoViaggio == 12) {
    soloLand = true;
  } else if (
    tipoViaggio == 6 ||
    tipoViaggio == 11 ||
    tipoViaggio == 3 ||
    tipoViaggio == 10
  ) {
    soloMezzo = true;
  }

  let parametri = {
    setContrattoInviato,
    setStatoPreventivo,
    setIstituto,
    setIstitutoId,
    queryVettoriAereo,
    caricato,
    setInCaricamento,
    id,
    optionsTrasporti,
    setNumeroBus,
    calcQuoteId,
    nomeProposta,
    nomePropostaVar,
    setDatiBus,
    setTotaliRiepilogoDB,
    setCalcQuoteId,
    autisti,
    setAutisti,
    setPropostaControllata,
    setTotalePaganti,
    totalePaganti,
    totaleNonPaganti,
    setTotaleNonPaganti,
    setGratuitiInSingola,
    setGratuitiInDoppia,
    setPagantiInSingola,
    setPagantiInDoppia,
    setMezzoTrasporto,
    setNomeProposta,
    pagantiF,
    setPagantiF,
    pagantiM,
    setPagantiM,
    nonPagantiF,
    setNonPagantiF,
    nonPagantiM,
    setNonPagantiM,
    setArea,
    setPolicy,
    setProgrammaCustom,
    setNascondiProgramma,
    setNoteStampa,
    setGiorni,
    setNotti,
    setPartenza,
    setRientro,
    setTipoNave,
    optionsNave,
    arrDatiSistemazione,
    setArrDatiSistemazione,
    arrDatiSistemazioneMezzo,
    setArrDatiSistemazioneMezzo,
    setNumeroSistemazioni,
    setNumeroSoste,
    ricalcolaSistemazioni,
    prezziRistorazione,
    setPrezziRistorazione,
    setPrezziRistorazioneDB,
    // setFree1Ristorazione,
    setDataPreventivo,
    setDataUpdate,
    datiGuide,
    settaDatiGuide,
    setArrDatiGuide,
    datiEscursioni,
    settaDatiEscursioni,
    setArrDatiEscursioni,
    datiIngressi,
    settaDatiIngressi,
    setArrDatiIngressi,
    settaDatiTransferScuola,
    settaDatiTransferLoco,
    setTransferLoco,
    setTransferScuola,
    setProposteAereo,
    setNumeroProposteAereo,
    setProposteNave,
    setNumeroProposteNave,
    setProposteTreno,
    setNumeroProposteTreno,
    ricalcolaSistemazioni,
    setInCaricamento,
    // setPropostaCaricata,
    setErrore,
    setInSalvataggio,
    gratuitiInDoppia,
    gratuitiInSingola,
    pagantiInSingola,
    pagantiInDoppia,
    totaleConAutisti,
    mezzoTrasporto,
    giorni,
    notti,
    rientro,
    partenza,
    area,
    numeroPreventivo,
    id,
    policy,
    tipoNave,
    programmaCustom,
    nascondiProgramma,
    includiSimilari,
    setIncludiSimilari,
    noteStampa,
    risposteBus,
    numeroSistemazioni,
    numeroSistemazioniOk,
    numeroSoste,
    arrDatiSistemazione,
    totalePersRistorazione,
    prezziRistorazione,
    arrDatiGuide,
    arrDatiGuidePDF,
    setArrDatiGuidePDF,
    arrDatiEscursioni,
    arrDatiEscursioniPDF,
    setArrDatiEscursioniPDF,
    arrDatiIngressi,
    arrDatiIngressiPDF,
    setArrDatiIngressiPDF,
    transferLoco,
    transferScuola,
    totaliRiepilogoSave,
    isBus,
    isNave,
    datiBus,
    isAereo,
    isTreno,
    proposteNave,
    proposteAereo,
    proposteTreno,
    calcQuoteId,
    setCalcQuoteId,
    setInSalvataggio,
    // setSalvato,
    setMessaggioSnackbar,
    setApriSnackbar,
    queryListaPropostePrev,
    inserimentoNuovo,
    timeoutId,
    totalePersBus,
    totalePersRistorazione,
    totalePersGuide,
    totalePersEscursioni,
    totalePersIngressi,
    totaleTransferLoco,
    totaleTransferScuola,
    numeroProposteAereo,
    numeroProposteTreno,
    numeroProposteNave,
    totaliRiepilogoSave,
    totaliRiepilogoDB,
    arrotonda,
    markup,
    setArrDatiRiepilogo,
    setMezziTrasportoPrev,
    setNumeroPreventivo,
    setNumeroPreventivoKreo,
    setDestinazione,
    setAddetto,
    setAddettoConferma,
    setAddettoRiquotazione,
    setNoteBus,
    setRisposteBus,
    queryMezziTrasporto,
    setTipoViaggio,
    tipoViaggio,
    soloLand,
    soloMezzo,
    dataVerificaPrezzi,
    setDataVerificaPrezzi,
    busInLoco,
    setBusInLoco,
    primaNotteInMezzo,
    setPrimaNotteInMezzo,
    ultimaNotteInMezzo,
    setUltimaNotteInMezzo,
  };

  // console.log(tipoViaggio);

  // return null;
  return (
    <>
      {<Caricamento inCaricamento={inCaricamento} />}
      <form>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <div className="bloccoCalcoloPrev">
              <Grid container spacing={1}>
                <Grid
                  container
                  item
                  md={12}
                  xs={12}
                  spacing={1}
                  className="rigaFlex"
                >
                  <Grid item>
                    <TextField
                      size="small"
                      label="Data modifica"
                      type="datetime-local"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="outlined"
                      className="inputCustom"
                      value={dataUpdate}
                    />
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      <TextField
                        size="small"
                        label="Cliente"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={istituto}
                      />
                      <Button
                        variant="contained"
                        className="bottoneLight"
                        size="small"
                        title="Contatti"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopupDettaglioCliente(istitutoId);
                        }}
                      >
                        <SupportAgentIcon />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      style={{ minWidth: 200 }}
                      freeSolo
                      fullWidth
                      disabled={isAutista}
                      filterOptions={(x) => x}
                      inputValue={nomeProposta || ""}
                      onInputChange={(e, value) => {
                        setNomeProposta(value);
                        nomePropostaVar = value;
                        // console.log(2, value, nomePropostaVar);
                      }}
                      options={[
                        { label: "In conferma" },
                        { label: "Riquotazione" },
                        { label: "Confermato" },
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          label="Stato"
                          className="inputCustom"
                        />
                      )}
                    />
                  </Grid>
                  {statoPreventivo >= 35 && (
                    //se stato >= riquotazione
                    <Grid item style={{ minWidth: 150 }}>
                      <TextField
                        size="small"
                        label="Addetto conferma"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        className="inputCustom"
                        value={addettoConferma}
                      />
                    </Grid>
                  )}
                  <Grid item style={{ minWidth: 150 }}>
                    <TextField
                      size="small"
                      label="Addetto preventivo"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={addetto}
                    />
                  </Grid>

                  {statoPreventivo >= 45 && (
                    //se stato >= riquotazione
                    <Grid item style={{ minWidth: 150 }}>
                      <TextField
                        size="small"
                        label="Addetto riquotazione"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        className="inputCustom"
                        value={addettoRiquotazione}
                      />
                    </Grid>
                  )}
                  <Grid item style={{ maxWidth: 150 }}>
                    <TextField
                      size="small"
                      label="Codice"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={
                        numeroPreventivo +
                        " " +
                        (numeroPreventivoKreo
                          ? " - K" + numeroPreventivoKreo
                          : "")
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    style={{ width: 50, flexGrow: 0, textAlign: "right" }}
                  >
                    <div title={contrattoInviato.descr}>
                      <ForwardToInboxIcon
                        style={{
                          color: contrattoInviato.colore,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} paddingTop="8px">
          <Grid item md={12}>
            <div className="bloccoCalcoloPrev">
              <Grid
                container
                item
                md={12}
                xs={12}
                spacing={1}
                className="rigaFlex"
                style={{ marginBottom: 10 }}
              >
                <Grid item md={3} xs={3}>
                  {/* tendina con le aree disponibili */}
                  <Autocomplete
                    value={area}
                    onChange={(e, value) => {
                      setArea(value);
                    }}
                    options={optionsArea}
                    disableClearable
                    disabled={isAutista}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Area"
                        className="inputCustom"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    size="small"
                    label="Destinazione"
                    type="text"
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom destinazione inputDisabledBlack"
                    value={destinazione}
                  />
                </Grid>

                {!soloLand && (
                  <Grid item md={6} xs={12}>
                    <div style={{ display: "flex", gap: 10 }}>
                      {/* tendina con i mezzi di trasporto presenti nella chiamata */}
                      <Autocomplete
                        value={mezzoTrasporto}
                        onChange={(e, value) => {
                          if (value == null) return;
                          // console.log("mezzo onchange", value);
                          setMezzoTrasporto(value);
                        }}
                        options={optionsTrasporti}
                        disableClearable
                        style={{ flexGrow: 1 }}
                        disabled={isAutista}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                            label="Mezzo di trasporto"
                            className="inputCustom"
                          />
                        )}
                      />
                      {isNave && (
                        <>
                          {/* tendina tipo nave */}
                          <Autocomplete
                            value={tipoNave}
                            onChange={(e, value) => {
                              // console.log(value, optionsNave);
                              setTipoNave(value);
                            }}
                            options={optionsNave}
                            disableClearable
                            disabled={isAutista}
                            style={{ flexGrow: 1 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                size="small"
                                label="Tipo Nave"
                                className="inputCustom"
                              />
                            )}
                          />
                        </>
                      )}
                    </div>
                  </Grid>
                )}
                <Grid item>
                  <TextField
                    size="small"
                    label="Giorni"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom"
                    value={giorni}
                    onChange={(e) => {
                      let giorni = e.target.value;
                      if (giorni < 1) {
                        giorni = 1;
                      }
                      setGiorni(giorni);
                      setNotti(Number(e.target.value) - 1);
                      //calcolo data rientro
                      setRientro(calcDataFine(partenza, e.target.value));
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    label="Notti"
                    disabled
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom inputDisabledBlack"
                    value={notti}
                    onChange={(e) => {
                      setNotti(e.target.value);
                      setGiorni(Number(e.target.value) + 1);
                    }}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="date"
                    label="Data partenza"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom"
                    value={partenza}
                    onChange={(e) => {
                      setPartenza(e.target.value);
                      setRientro(calcDataFine(e.target.value, giorni));
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    type="date"
                    label="Data rientro"
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom inputDisabledBlack"
                    value={rientro}
                  />
                </Grid>
                <Grid item>
                  {/* tendina policy */}
                  <Autocomplete
                    style={{ minWidth: 200 }}
                    value={policyValue}
                    onChange={(e, value) => {
                      setPolicy(value?.value);
                    }}
                    options={optionsPolicy}
                    disableClearable
                    disabled={isAutista}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Assicurazioni"
                        className="inputCustom"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                md={12}
                xs={12}
                spacing={1}
                className="rigaFlex"
              >
                <Grid item style={{ flexGrow: 0 }}>
                  <TextField
                    size="small"
                    label="Paganti"
                    type="number"
                    fullWidth
                    style={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={totalePaganti}
                    onChange={(e) => {
                      setTotalePaganti(e.target.value);
                      allineaDatiGuideEcc(e.target.value, totaleNonPaganti);
                    }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 0 }}>
                  <TextField
                    size="small"
                    label="Non paganti"
                    type="number"
                    style={{ width: 150 }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={totaleNonPaganti}
                    onChange={(e) => {
                      allineaDatiGuideEcc(totaleNonPaganti, e.target.value);
                      setTotaleNonPaganti(e.target.value);
                    }}
                  />
                </Grid>
                {isBus && (
                  <Grid item>
                    {/* Visibile solo se il mezzo di trasporto è BUS, altrimenti deve essere impostato a zero e disabilitato */}
                    <TextField
                      size="small"
                      label="Autisti"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // disabled={isAutista}
                      disabled={true}
                      variant="outlined"
                      className="inputCustom "
                      // value se bus
                      value={autisti}
                    />
                  </Grid>
                )}

                <Grid item>
                  {/* somma di paganti + non paganti */}
                  <TextField
                    size="small"
                    label="Totale"
                    type="number"
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom inputDisabledBlack"
                    value={totaleConAutisti}
                    // onChange={(e) => {
                    //   setTotale(e.target.value);
                    // }}
                  />
                </Grid>

                <Grid item>
                  {/* predefinito deve essere uguale alla somma dei (non paganti + gli autisti - (2*quelli in doppia)) */}
                  <TextField
                    size="small"
                    label="Non paganti in singola"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={gratuitiInSingola}
                    onChange={(e) => {
                      setGratuitiInSingola(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* predefinito a zero */}
                  <TextField
                    size="small"
                    label="Non paganti in doppia"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={gratuitiInDoppia}
                    onChange={(e) => {
                      setGratuitiInDoppia(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* predefinito deve essere uguale alla somma dei (non paganti + gli autisti - (2*quelli in doppia)) */}
                  <TextField
                    size="small"
                    label="Paganti in singola"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={pagantiInSingola}
                    onChange={(e) => {
                      setPagantiInSingola(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* predefinito a zero */}
                  <TextField
                    size="small"
                    label="Paganti in doppia"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isAutista}
                    variant="outlined"
                    className="inputCustom "
                    value={pagantiInDoppia}
                    onChange={(e) => {
                      setPagantiInDoppia(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item md={12} xs={12} spacing={1}>
                <Grid item>
                  <div className="gruppoInput" style={{ width: 150 }}>
                    <TextField
                      size="small"
                      label="M"
                      style={{ maxWidth: 100 }}
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isAutista}
                      variant="outlined"
                      className="inputCustom "
                      value={pagantiM}
                      onChange={(e) => {
                        let value = e.target.value;
                        syncMaschiFemmine(
                          value,
                          totalePaganti - value,
                          "paganti"
                        );
                      }}
                    />

                    <TextField
                      size="small"
                      label="F"
                      fullWidth
                      style={{ maxWidth: 100 }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isAutista}
                      variant="outlined"
                      className="inputCustom "
                      value={pagantiF}
                      onChange={(e) => {
                        let value = e.target.value;
                        syncMaschiFemmine(
                          totalePaganti - value,
                          value,
                          "paganti"
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className="gruppoInput" style={{ width: 150 }}>
                    <TextField
                      size="small"
                      label="M"
                      style={{ maxWidth: 100 }}
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isAutista}
                      variant="outlined"
                      className="inputCustom "
                      value={nonPagantiM}
                      onChange={(e) => {
                        let value = e.target.value;
                        syncMaschiFemmine(
                          value,
                          totaleNonPaganti - value,
                          "nonPaganti"
                        );
                      }}
                    />
                    <TextField
                      size="small"
                      label="F"
                      style={{ maxWidth: 100 }}
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isAutista}
                      variant="outlined"
                      className="inputCustom "
                      value={nonPagantiF}
                      onChange={(e) => {
                        let value = e.target.value;
                        syncMaschiFemmine(
                          totaleNonPaganti - value,
                          value,
                          "nonPaganti"
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <div style={{ display: "flex", gap: 18 }}>
                    <div>
                      Bus in loco
                      <input
                        type="checkbox"
                        checked={busInLoco ? true : false}
                        onChange={(e) => {
                          setBusInLoco(e.target.checked);
                        }}
                      />
                    </div>

                    <div>
                      Prima notte in {mezzoTrasporto?.label}
                      <input
                        type="checkbox"
                        checked={primaNotteInMezzo ? true : false}
                        onChange={(e) => {
                          let value = e.target.checked;
                          setPrimaNotteInMezzo(value);
                          ///set arrDatiSistemazioneMezzo at position 0 or create if not present
                          generaSistemazioniMezzo(0, value, "prima");
                        }}
                      />
                    </div>
                    <div>
                      Ultima notte in {mezzoTrasporto?.label}
                      <input
                        type="checkbox"
                        checked={ultimaNotteInMezzo ? true : false}
                        onChange={(e) => {
                          let value = e.target.checked;
                          setUltimaNotteInMezzo(value);
                          ///set arrDatiSistemazioneMezzo at position 0 or create if not present
                          generaSistemazioniMezzo(1, value, "ultima");
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {/* riga BUS */}
        {(isBus || (isNave && tipoNave.value == "busSI")) && !inCaricamento && (
          <ProposteBus
            // style={{marginTop: "8px"}}
            numeroBus={numeroBus}
            totalePaganti={totalePaganti}
            setNumeroBus={setNumeroBus}
            totalePersBus={totalePersBus}
            setTotalePersBus={setTotalePersBus}
            datiBus={datiBus}
            setDatiBus={setDatiBus}
            spesePasti={spesePasti}
            setAutisti={setAutisti}
          />
        )}
        {/* fine riga BUS */}
        {isAutista && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid item xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      label="Note Autonoleggio"
                      multiline
                      rows={2}
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={noteBus || ""}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      label="Risposte Autonoleggio"
                      multiline
                      rows={2}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setRisposteBus(e.target.value);
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={risposteBus || ""}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
        {/* RIGA TRANSFER AEREO  O TRENO*/}
        {(isAereo || isTreno || isNave) && !isAutista && !soloLand && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid
              item
              md={6}
              xs={12}
              className={totaleTransferScuola > 0 ? "" : "hideOnPrint"}
            >
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <label className="labelGruppo noBorder">
                      Transfer da scuola
                    </label>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totalePaganti euro"
                      value={totaleTransferScuola}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota totale"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totaleComplessivo euro"
                      value={arrotonda(totaleTransferScuola * totalePaganti)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} className="hideOnPrint">
                    <div className="labelGruppoCont"></div>
                  </Grid>
                  {transferScuola.map((dati, index) => {
                    return (
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={1}
                        key={index}
                      >
                        <Transfer
                          dati={dati}
                          settaDati={settaDatiTransferScuola}
                          arr={transferScuola}
                          index={index}
                          elimina={eliminaTransferScuola}
                          mostraCompagnia={true}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} className="hideOnPrint">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        let temp = [...transferScuola];
                        temp.push({ ...datiTransfer });
                        setTransferScuola(temp);
                      }}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              className={totaleTransferLoco > 0 ? "" : "hideOnPrint"}
            >
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <label className="labelGruppo noBorder">
                      Transfer in loco
                    </label>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totalePaganti"
                      value={totaleTransferLoco}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota totale"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totaleComplessivo"
                      value={arrotonda(totaleTransferLoco * totalePaganti)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} className="hideOnPrint">
                    <div className="labelGruppoCont"></div>
                  </Grid>
                  {transferLoco.map((dati, index) => {
                    return (
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={1}
                        key={index}
                      >
                        <Transfer
                          dati={dati}
                          settaDati={settaDatiTransferLoco}
                          arr={transferLoco}
                          index={index}
                          elimina={eliminaTransferLoco}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} className="hideOnPrint">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        let temp = [...transferLoco];
                        temp.push({ ...datiTransfer });
                        setTransferLoco(temp);
                      }}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
        {/* FINE RIGA TRANSFER*/}
        {/* riga proposte aereo */}
        {isAereo && !isAutista && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid item md={12} xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <label className="labelGruppo">Proposte Aereo</label>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      size="small"
                      label="N. Proposte Aereo"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={numeroProposteAereo}
                      onChange={(e) => {
                        setNumeroProposteAereo(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="Data verifica prezzi"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={dataVerificaPrezzi}
                      onChange={(e) => {
                        setDataVerificaPrezzi(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {proposteAereo.map((arr, index) => {
              return (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                  key={index}
                  className="propostaAereo"
                >
                  <div
                    className="eliminaS"
                    onClick={() => {
                      eliminaPropostaAereo(index);
                    }}
                  >
                    <DeleteIcon /> Proposta {index + 1}
                  </div>
                  <div className="bloccoCalcoloPrev">
                    <Grid container spacing={1}>
                      <PropostaAereo
                        vettoriAereo={vettoriAereo}
                        dati={arr}
                        index={index}
                        settaDati={settaDatiProposteAereo}
                        arr={proposteAereo}
                        totalePaganti={totalePaganti}
                        totaleNonPaganti={totaleNonPaganti}
                        idProp={calcQuoteId}
                      />
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
        {/* fine riga proposte aereo */}

        {/* riga proposte nave */}
        {isNave && !isAutista && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid item md={12} xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <label className="labelGruppo">Proposte Nave</label>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      size="small"
                      label="N. Proposte Nave"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={numeroProposteNave}
                      onChange={(e) => {
                        setNumeroProposteNave(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="Data verifica prezzi"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={dataVerificaPrezzi}
                      onChange={(e) => {
                        setDataVerificaPrezzi(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {proposteNave.map((arr, index) => {
              return (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                  key={index}
                  className="propostaNave"
                >
                  <div
                    className="eliminaS"
                    onClick={() => {
                      eliminaPropostaNave(index);
                    }}
                  >
                    <DeleteIcon /> Proposta {index + 1}
                  </div>
                  <div className="bloccoCalcoloPrev">
                    <Grid container spacing={1}>
                      <PropostaNave
                        dati={arr}
                        index={index}
                        settaDati={settaDatiProposteNave}
                        arr={proposteNave}
                        totalePaganti={totalePaganti}
                        totaleNonPaganti={totaleNonPaganti}
                        idProp={calcQuoteId}
                      />
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
        {/* fine riga proposte nave */}

        {/* riga proposte treno */}
        {isTreno && !isAutista && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid item md={12} xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <label className="labelGruppo">Proposte Treno</label>
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="N. Proposte Treno"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={numeroProposteTreno}
                      onChange={(e) => {
                        setNumeroProposteTreno(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="Data verifica prezzi"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={dataVerificaPrezzi}
                      onChange={(e) => {
                        setDataVerificaPrezzi(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {proposteTreno.map((arr, index) => {
              return (
                <Grid item md={6} sm={12} key={index}>
                  <div
                    className="eliminaS"
                    onClick={() => {
                      eliminaPropostaTreno(index);
                    }}
                  >
                    <DeleteIcon /> Proposta {index + 1}
                  </div>
                  <div className="bloccoCalcoloPrev">
                    <Grid container spacing={1}>
                      <PropostaTreno
                        dati={arr}
                        index={index}
                        settaDati={settaDatiProposteTreno}
                        arr={proposteTreno}
                        totalePaganti={totalePaganti}
                        totaleNonPaganti={totaleNonPaganti}
                        eliminaTreno={eliminaTreno}
                        gratuitaTreno={gratuitaTreno}
                        idProp={calcQuoteId}
                      />
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
        {/* fine riga proposte treno */}
        {/* notti su mezzo ------------*/}
        {!isAutista && !soloMezzo && arrDatiSistemazioneMezzo.length > 0 && (
          <Grid container spacing={1} paddingTop="8px">
            <Grid item md={12} xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <label className="labelGruppo">
                      Notti in {mezzoTrasporto?.label}
                    </label>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {arrDatiSistemazioneMezzo.map((arr, index) => {
              return (
                <Grid item container md={12} xs={12} spacing={1} key={index}>
                  {arr.map((dati, index2) => {
                    return (
                      <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                        key={index2}
                        className="propostaSistemazione"
                      >
                        <div className="bloccoCalcoloPrev">
                          <Grid container spacing={1}>
                            <SistemazioneMezzo
                              dati={dati}
                              settaDati={settaDatiSistemazioneMezzo}
                              arr={arrDatiSistemazioneMezzo}
                              index={index}
                              index2={index2}
                              isAutista={isAutista}
                              mezzo={mezzoTrasporto?.label}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* sistemazioni --------------------- */}
        <Grid container spacing={1} paddingTop="8px">
          {!isAutista && !soloMezzo && (
            <Grid item md={12} xs={12}>
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <label className="labelGruppo">
                      Sistemazioni Alberghiere
                    </label>
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="N. Sistemazioni"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={numeroSistemazioni}
                      onChange={(e) => {
                        setNumeroSistemazioni(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} className="hideOnPrint">
                    <TextField
                      size="small"
                      label="N. Soste"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={numeroSoste}
                      onChange={(e) => {
                        if (e.target.value > notti) {
                          //non ci possono essere più soste che notti
                          setNumeroSoste(notti);
                        } else {
                          setNumeroSoste(e.target.value);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} className="hideOnPrint">
                    <div
                      style={{ display: "flex", gap: 15, alignItems: "center" }}
                    >
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          ricalcolaSistemazioni(
                            arrDatiSistemazione,
                            numeroSoste,
                            numeroSistemazioni
                          );
                        }}
                      >
                        RICALCOLA
                      </Button>
                      <div>
                        Similare
                        <input
                          type="checkbox"
                          checked={includiSimilari ? true : false}
                          onChange={(e) => {
                            setIncludiSimilari(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          {!isAutista &&
            arrDatiSistemazione.map((arr, index) => {
              return (
                <Grid item container md={12} xs={12} spacing={1} key={index}>
                  {arr.map((dati, index2) => {
                    return (
                      <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                        key={index2}
                        className="propostaSistemazione"
                      >
                        <div className="eliminaSistemazioni">
                          {index2 == 0 && (
                            <div>
                              <b>Sosta {index + 1}</b>
                            </div>
                          )}
                          {index2 == 0 && (
                            <div
                              className="eliminaS"
                              onClick={() => {
                                eliminaSosta(index);
                              }}
                            >
                              <DeleteIcon /> Sosta {index + 1}
                            </div>
                          )}

                          {index == 0 && (
                            <div
                              className="eliminaS"
                              onClick={() => {
                                eliminaProposta(index2);
                              }}
                            >
                              <DeleteIcon /> Proposta {index2 + 1} (per tutte le
                              soste)
                            </div>
                          )}
                        </div>

                        <div className="bloccoCalcoloPrev">
                          <Grid container spacing={1}>
                            <Sistemazione
                              dati={dati}
                              settaDati={settaDatiSistemazione}
                              arr={arrDatiSistemazione}
                              index={index}
                              index2={index2}
                              totalePaganti={totalePaganti}
                              totaleNonPaganti={totaleNonPaganti}
                              totalePass={totaleConAutisti} //comprende autisti
                              optHotel={optHotel}
                              gratInSing={gratuitiInSingola}
                              gratInDopp={gratuitiInDoppia}
                              pagInSing={pagantiInSingola}
                              pagInDopp={pagantiInDoppia}
                              notti={notti}
                              isAutista={isAutista}
                              idProp={calcQuoteId}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
        </Grid>

        <Grid container spacing={1} paddingTop="8px">
          {!isAutista && (
            <Ristorazione
              totalePersRistorazione={totalePersRistorazione}
              arrotonda={arrotonda}
              totalePaganti={totalePaganti}
              prezziRistorazione={prezziRistorazione}
              totaleSenzaAutisti={totaleSenzaAutisti}
              optHotel={optHotel}
              giorni={giorni}
              notti={notti}
              settaDatiPrezziRist={settaDatiPrezziRist}
              style={{ display: soloMezzo ? "none" : "block" }}
            />
          )}
          {!isAutista && !soloMezzo && (
            <Grid
              item
              md={6}
              xs={12}
              className={totalePersGuide > 0 ? "" : "hideOnPrint"}
            >
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div
                      className="alignCenter"
                      style={{ justifyContent: "space-between", gap: 10 }}
                    >
                      <label className="labelGruppo noBorder">
                        Visite guidate
                      </label>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("guide");
                          setInserisciExtraPDF(true);
                        }}
                        className="bottoneExtra"
                      >
                        <TourIcon />
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("guide");
                          setInserisciExtraPDF(false);
                        }}
                      >
                        <TourIcon />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totalePaganti euro"
                      value={arrotonda(totalePersGuide)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota totale"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totaleComplessivo euro"
                      value={arrotonda((totalePersGuide || 0) * totalePaganti)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} className="hideOnPrint">
                    <div className="labelGruppoCont"></div>
                  </Grid>
                  {arrDatiGuide.map((dati, index) => {
                    return (
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={1}
                        key={index}
                      >
                        <Guide
                          dati={dati}
                          settaDati={settaDatiGuide}
                          arr={arrDatiGuide}
                          index={index}
                          eliminaGuida={eliminaGuida}
                          copiaGuida={copiaGuida}
                          totalePaganti={totalePaganti}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        let temp = [...arrDatiGuide];
                        temp.push({ ...datiGuide });
                        setArrDatiGuide(temp);
                      }}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          {!isAutista && !soloMezzo && (
            <Grid
              item
              md={6}
              xs={12}
              className={totalePersEscursioni > 0 ? "" : "hideOnPrint"}
            >
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div
                      className="alignCenter"
                      style={{ justifyContent: "space-between", gap: 10 }}
                    >
                      <label className="labelGruppo noBorder">
                        Escursioni e servizi extra
                      </label>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("escursioni");
                          setInserisciExtraPDF(true);
                        }}
                        className="bottoneExtra"
                      >
                        <HikingIcon />
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("escursioni");
                          setInserisciExtraPDF(false);
                        }}
                      >
                        <HikingIcon />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totalePaganti euro"
                      value={arrotonda(totalePersEscursioni)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota totale"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totaleComplessivo euro"
                      value={arrotonda(
                        (totalePersEscursioni || 0) * totalePaganti
                      )}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} className="hideOnPrint">
                    <div className="labelGruppoCont"></div>
                  </Grid>
                  {arrDatiEscursioni.map((dati, index) => {
                    return (
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={1}
                        key={index}
                      >
                        <Escursioni
                          dati={dati}
                          settaDati={settaDatiEscursioni}
                          arr={arrDatiEscursioni}
                          index={index}
                          eliminaEscursione={eliminaEscursione}
                          totalePaganti={totalePaganti}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        let temp = [...arrDatiEscursioni];
                        temp.push({ ...datiEscursioni });
                        setArrDatiEscursioni(temp);
                      }}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          {!isAutista && !soloMezzo && (
            <Grid
              item
              md={6}
              xs={12}
              className={totalePersIngressi > 0 ? "" : "hideOnPrint"}
            >
              <div className="bloccoCalcoloPrev">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div
                      className="alignCenter"
                      style={{ justifyContent: "space-between", gap: 10 }}
                    >
                      <label className="labelGruppo noBorder">Ingressi</label>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("ingressi");
                          setInserisciExtraPDF(true);
                        }}
                        className="bottoneExtra"
                      >
                        <LocalActivityIcon />
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setPopupAggiungiOpen(true);
                          setPopupAggiungiTipo("ingressi");
                          setInserisciExtraPDF(false);
                        }}
                      >
                        <LocalActivityIcon />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totalePaganti euro"
                      value={arrotonda(totalePersIngressi)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Quota totale"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom totaleComplessivo euro"
                      value={arrotonda(
                        (totalePersIngressi || 0) * totalePaganti
                      )}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} className="hideOnPrint">
                    <div className="labelGruppoCont"></div>
                  </Grid>
                  {arrDatiIngressi.map((dati, index) => {
                    return (
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={1}
                        key={index}
                      >
                        <Ingressi
                          dati={dati}
                          settaDati={settaDatiIngressi}
                          arr={arrDatiIngressi}
                          index={index}
                          eliminaIngresso={eliminaIngresso}
                          totalePaganti={totalePaganti}
                          totaleNonPaganti={totaleNonPaganti}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        let temp = [...arrDatiIngressi];
                        temp.push({ ...datiIngressi });
                        setArrDatiIngressi(temp);
                      }}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          {!isAutista &&
            arrDatiRiepilogo.map((datiL0, indexL0) => {
              return (
                <Grid item container spacing={2} key={indexL0}>
                  {datiL0.map((datiL1, indexL1) => {
                    return (
                      <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                        key={indexL0 + "" + indexL1}
                        className="riepilogo"
                      >
                        <div className="bloccoCalcoloPrev">
                          <Grid container spacing={1}>
                            <Riepilogo
                              dati={datiL1}
                              datiL0={datiL0}
                              totaliRiepilogoDB={totaliRiepilogoDB}
                              setTotaliRiepilogoDB={setTotaliRiepilogoDB}
                              totaliRiepilogoSave={totaliRiepilogoSave}
                              totalePaganti={totalePaganti}
                              setTotaliRiepilogoSave={setTotaliRiepilogoSave}
                              settaDati={settaDatiRiepilogo}
                              arr={arrDatiRiepilogo}
                              index1={indexL0}
                              index2={indexL1}
                              markup={markup}
                              calcolaRiepilogo={() =>
                                calcolaRiepilogo(parametri)
                              }
                              assicurazione={
                                assicurazioni[indexL0]
                                  ? assicurazioni[indexL0][indexL1]
                                  : 0
                              }
                              calcQuoteId={calcQuoteId}
                              parametri={parametri}
                              policyNome={policyNome}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}

          <Grid item xs={12}></Grid>
          {!isAutista && (
            ////preventivo custom e note
            <>
              {!soloMezzo && (
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      gap: 15,
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <h4>Programma Forzato</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        alignItems: "center",
                      }}
                    >
                      <small>Nascondi</small>
                      <input
                        type="checkbox"
                        checked={nascondiProgramma ? true : false}
                        onChange={(e) => {
                          setNascondiProgramma(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                  <EditorBase
                    value={programmaCustom}
                    setValue={setProgrammaCustom}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <h4>Note Stampa</h4>
                <EditorBase value={noteStampa} setValue={setNoteStampa} />
              </Grid>
            </>
          )}
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {errore ? <p className="bannerErrore">{errore}</p> : ""}
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: 0,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {!isAutista && (
              <Button
                className="inputCustom"
                href={"/preventivo/" + id}
                variant="contained"
              >
                Indietro
              </Button>
            )}
            <div
              style={{
                paddingTop: 0,
                justifyContent: "space-between",
                display: "flex",
                gap: 15,
              }}
            >
              <Button
                variant="contained"
                className="bottoneSalva"
                disabled={inSalvataggio}
                onClick={(e) => {
                  ////copiato in useEffect al cambio del riepilogo
                  // setTimeout(() => {
                  salvaPreventivo(true, parametri, false, false);
                  // }, 1000);
                }}
              >
                {!inSalvataggio ? "Salva" : <CircularProgress size={20} />}
              </Button>

              {(optionsProposteCalcolo.length == 1 || proposteControllate) &&
              statoPreventivo == 10 &&
              checkRuoloUtente("controllore") ? (
                ///se tutte le proposte controllate
                ///o se il numero di proposte è 1
                ///e stato da controllare
                ///cambio lo stato di tutto il preventivo
                <Button
                  variant="contained"
                  disabled={inSalvataggio}
                  className="bottoneSalva"
                  onClick={async (e) => {
                    ///aggiorno stato preventivo in controllato
                    const res = await monFetch("/quoteSetStatus", {
                      status_id: 20,
                      id: id,
                    });

                    //aggiorno pagina calcolo
                    salvaPreventivo(true, parametri, true);
                  }}
                >
                  {!inSalvataggio ? (
                    "Salva come controllato"
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Button>
              ) : (
                ////////salva come checked la proposta
                optionsProposteCalcolo.length > 1 &&
                !propostaControllata &&
                checkRuoloUtente("controllore") && (
                  <Button
                    variant="contained"
                    disabled={inSalvataggio}
                    className="bottoneSalva"
                    onClick={async (e) => {
                      ///marco come controllata
                      const res = await monFetch("/quotePriceSetChecked", {
                        id: calcQuoteId,
                      });

                      //aggiorno pagina calcolo
                      salvaPreventivo(true, parametri);
                    }}
                  >
                    {!inSalvataggio ? (
                      "Conferma quota"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                )
              )}

              {!isAutista && calcQuoteId && (
                <Button
                  onClick={(e) => {
                    setPopupElimina(true);
                    setDaEliminare(calcQuoteId);
                  }}
                  className="bottoneElimina"
                  variant="contained"
                >
                  Elimina
                </Button>
              )}
              {/* <Button
                variant="contained"
                className="bottoneAzione"
                onClick={(e) => {
                  window.print();
                }}
              >
                <PrintIcon />
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </form>

      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaPreventivo(daEliminare)}
      />
      <PopupDuplica
        open={popupDuplica}
        fnAnnulla={(e) => setPopupDuplica(false)}
        fnSuccess={(e) => duplicaPreventivo()}
      />

      <BoxNotifica
        apriSnackbar={apriSnackbar}
        chiudiSnackbar={chiudiSnackbar}
        messaggioSnackbar={messaggioSnackbar}
      />

      <PopupAggiungi
        open={popupAggiungiOpen}
        tipo={popupAggiungiTipo}
        fnAnnulla={(e) => setPopupAggiungiOpen(false)}
        arrDatiGuide={arrDatiGuide}
        setArrDatiGuide={setArrDatiGuide}
        arrDatiGuidePDF={arrDatiGuidePDF}
        setArrDatiGuidePDF={setArrDatiGuidePDF}
        settaDatiGuide={settaDatiGuide}
        arrDatiEscursioni={arrDatiEscursioni}
        setArrDatiEscursioni={setArrDatiEscursioni}
        arrDatiEscursioniPDF={arrDatiEscursioniPDF}
        setArrDatiEscursioniPDF={setArrDatiEscursioniPDF}
        settaDatiEscursioni={settaDatiEscursioni}
        arrDatiIngressi={arrDatiIngressi}
        setArrDatiIngressi={setArrDatiIngressi}
        arrDatiIngressiPDF={arrDatiIngressiPDF}
        setArrDatiIngressiPDF={setArrDatiIngressiPDF}
        settaDatiIngressi={settaDatiIngressi}
        destinazione={destinazione}
        inserisciExtraPDF={inserisciExtraPDF}
        idProposta={calcQuoteId}
      />

      <PopupInvioMail
        open={popupMailOpen}
        fnAnnulla={(e) => setPopupMailOpen(false)}
        // addetto = {addetto}
        guide={arrDatiGuide}
        escursioni={arrDatiEscursioni}
        ingressi={arrDatiIngressi}
        transferLoco={transferLoco}
        sistemazioni={arrDatiSistemazione}
        treno={proposteTreno}
        aereo={proposteAereo}
        numeroPreventivo={numeroPreventivo}
        destinazione={destinazione}
        partenza={partenza}
        rientro={rientro}
        totalePaganti={totalePaganti}
        totaleNonPaganti={totaleNonPaganti}
        pagantiInSingola={pagantiInSingola}
        gratuitiInSingola={gratuitiInSingola}
        pagantiInDoppia={pagantiInDoppia}
        gratuitiInDoppia={gratuitiInDoppia}
        autisti={autisti}
        id={id}
        istituto={istituto}
      />

      <PopupSpostaProposta
        open={popupSpostaPropostaOpen}
        fnAnnulla={(e) => setPopupSpostaPropostaOpen(false)}
        idProposta={calcQuoteId}
      />

      <PopupDettaglioCliente
        open={popupDettaglioCliente}
        fnAnnulla={(e) => setPopupDettaglioCliente(false)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupDuplica = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Duplica proposta</DialogTitle>
      <DialogContent>
        <DialogContentText>Vuoi duplicare la proposta?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Duplica
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
