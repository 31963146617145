// import Tooltip from "@mui/material/Tooltip";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import TextField from "@mui/material/TextField";
import {
  monFetch,
  // monForm,
  monMedia,
  // checkRuoloUtente,
  checkAutista,
} from "../../inc/inc";
// import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GridOnIcon from "@mui/icons-material/GridOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import FileLista from "./FileLista";
import { MediaBottoniForm } from "./MediaBottoniForm";

// import { MediaUpload } from "./MediaUpload";

// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import FolderIcon from "@mui/icons-material/Folder";

import PopupElimina from "./PopupElimina";
import PopupAllegati from "./PopupAllegati";
import PopupVedi from "./PopupVedi";
import PopupUpload from "./PopupUpload";
import PopupRinomina from "./PopupRinomina";
import PopupDuplicaMedia from "./PopupDuplicaMedia";

function getMime(el) {
  let mime = "doc";
  let visualizza = false;
  if (el.mime.startsWith("image")) {
    mime = "img";
    visualizza = true;
  } else if (el.mime.startsWith("audio")) {
    mime = "audio";
    visualizza = true;
  } else if (el.mime.startsWith("video")) {
    mime = "video";
    visualizza = true;
  } else if (el.mime.startsWith("text")) {
    visualizza = true;
  } else if (el.mime.includes("pdf")) {
    mime = "pdf";
    visualizza = true;
  } else if (el.mime.includes("spreadsheet")) {
    mime = "excel";
  }

  return [mime, visualizza];
}

const IconaFile = (props) => {
  if (props.mime == "doc") {
    return <InsertDriveFileIcon />;
  }
  if (props.mime == "img") {
    return <ImageIcon />;
  }

  if (props.mime == "pdf") {
    return <PictureAsPdfIcon />;
  }

  if (props.mime == "audio") {
    return <VolumeDownIcon />;
  }

  if (props.mime == "video") {
    return <OndemandVideoIcon />;
  }

  if (props.mime == "excel") {
    return <GridOnIcon />;
  }
};

const MediaLista = (p) => {
  //se sono nel popup per allegare files alla mail
  let allegaON = p.allegaON;

  // console.log("media props", p);
  //
  // const [media, setMedia] = useState([]);
  const [mediaTree, setMediaTree] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [popupRinomina, setPopupRinomina] = useState(false);
  const [daRinominare, setDaRinominare] = useState(null);
  const [nomeFileDaRinominare, setNomeFileDaRinominare] = useState(null);

  const [popupDuplica, setPopupDuplica] = useState(false);
  const [daDuplicare, setDaDuplicare] = useState(null);

  const [popupUpload, setPopupUpload] = useState(false);

  const [popupVedi, setPopupVedi] = useState(false);
  const [vediMedia, setVediMedia] = useState(null);
  const [vediMediaMime, setVediMediaMime] = useState(null);

  const [popupAllegati, setPopupAllegati] = useState(false);
  const [tipoPopup, setTipoPopup] = useState("");

  const [checkSel, setCheckSel] = useState({});

  const [tipoMedia, setTipoMedia] = useState("");
  const [subCat, setSubCat] = useState("");

  const idPrev = p.idPrev;

  function settaAllegato(id, nome, size = 0, path = "") {
    let temp = [...p.allegatiMail];
    temp.push({ id, nome, size, path });
    p.setAllegatiMail(temp);
  }

  // async function queryMedia() {
  //   const res = await monFetch("/mediaList", { quote_id: idPrev });
  //   // console.log(res);
  //   setMedia(res.mediaRes);
  // }
  async function queryMediaTree() {
    const res = await monFetch("/mediaListTree", { quote_id: idPrev });
    // console.log(res);
    setMediaTree(res.mediaTree);
  }

  async function downloadMedia(id, nomeFile, tipoPopup = "") {
    let res;
    if (tipoPopup == "tree") {
      res = await monMedia("/mediaDownloadTree", { media_id: id });
    }
    // else {
    //   res = await monMedia("/mediaDownload", { media_id: id });
    // }

    var url = window.URL.createObjectURL(res);
    var a = document.createElement("a");
    a.href = url;
    a.download = nomeFile;
    document.body.appendChild(a);
    // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }

  // async function getDatiMedia(id) {
  //   let res;

  //   res = await monFetch("/mediaGet", { id: id });
  //   // console.log(res);
  //   setVediMedia(res.media);

  //   setVediMediaMime(res.mime);
  // }

  async function rinominaMedia() {
    let dati = {
      idMedia: daRinominare,
      nome: nomeFileDaRinominare,
    };

    console.log(dati);

    let res = await monFetch("/rinominaMedia", dati);

    if (res.success) {
      queryMediaTree();
    }
  }

  async function getDatiMediaTree(id) {
    let res;

    res = await monFetch("/mediaGetTree", { id: id });
    // console.log(res);
    setVediMedia(res.media);

    setVediMediaMime(res.mime);
  }

  async function allegaFiles() {
    let idFiles = [];
    for (let c in checkSel) {
      if (checkSel[c] == true) {
        idFiles.push(c);
      }
    }

    let res;

    if (tipoPopup == "tree") {
      res = await monFetch("/fileQuoteLinkTree", {
        quote_id: idPrev,
        file_ids: idFiles,
        cat_id: tipoMedia,
        subcat_id: subCat,
      });
    }
    // else {
    //   res = await monFetch("/fileQuoteLink", {
    //     quote_id: idPrev,
    //     file_ids: idFiles,
    //     media_type_id: tipoMedia,
    //   });
    // }

    setPopupAllegati(false);
    // queryMedia();
    queryMediaTree();
    //queryFiles();
  }

  // async function queryFiles() {
  //   const res = await monFetch("/fileQuoteGet", { quote_id: idPrev });
  //   setFiles(res.res);
  // }

  useEffect(
    (e) => {
      // console.log(p.elementiBarraTop);
      //if (!p.elementiBarraTop) {
      // queryMedia();
      queryMediaTree();

      //se non sono fornitore di bus
      if (allegaON) {
        return;
      }
      p.setElementiBarraTop(
        <>
          {/* {!checkAutista() && (
            <div style={{ display: "flex", alignItems: "center" }}>
    
            </div>
          )} */}
          {p.elementiBarraTop}
          {p.elementiBarraTop2}
        </>
      );

      //}
    },
    [p.codice]
  );

  async function eliminaMedia(ids) {
    // if (tipoPopup == "tree") {
    // console.log(ids);
    let count = 0;
    ids.forEach(async (id) => {
      const res = await monFetch("/mediaDeleteTree", { id });
      count++;
      if (count == ids.length) {
        queryMediaTree();
      }
    });

    // }
    // else {
    //   const res = await monFetch("/mediaDelete", { id });
    // }

    // queryMedia();
  }

  return (
    <div className="boxMedia boxUpload">
      {/* blocco files globali */}
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          {mediaTree.map((cat, index) => {
            return (
              <Grid item sm={6} xs={12} key={cat.id}>
                <div className="bloccoMedia">
                  <Grid
                    container
                    spacing={0}
                    style={{ marginTop: 0, marginLeft: 0 }}
                  >
                    <Grid item sm={8} xs={8} className="labelMedia">
                      {!cat.chiuso ? (
                        <KeyboardArrowDownIcon
                          onClick={() => {
                            ///chiudi macro categoria
                            let temp = [...mediaTree];

                            temp[index].chiuso = true;
                            // console.log(temp)
                            setMediaTree(temp);
                          }}
                        />
                      ) : (
                        <KeyboardArrowRightIcon
                          onClick={() => {
                            ///apri macro categoria
                            let temp = [...mediaTree];

                            temp[index].chiuso = false;
                            setMediaTree(temp);
                          }}
                        />
                      )}
                      <label style={{ marginTop: 0 }}>
                        {cat.media_category}{" "}
                        {(cat.files?.length > 0 || cat.subcat?.length > 0) && (
                          <DeleteIcon
                            color="error"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              ///elimina tutta la cartella
                              e.stopPropagation();
                              setPopupElimina(true);

                              let ids = [];

                              cat.files?.forEach((el) => {
                                ids.push(el.id);
                              });

                              cat.subcat?.forEach((sub) => {
                                sub.files?.forEach((el) => {
                                  ids.push(el.id);
                                });
                              });
                              // console.log(ids);
                              setDaEliminare(ids);
                            }}
                          />
                        )}
                      </label>
                    </Grid>
                    <Grid item sm={4} xs={4} className="labelMedia2">
                      {!allegaON && (
                        <MediaBottoniForm
                          idCat={cat.id}
                          tree={true}
                          setTipoPopup={setTipoPopup}
                          setTipoMedia={setTipoMedia}
                          setPopupAllegati={setPopupAllegati}
                          setPopupUpload={setPopupUpload}
                        />
                      )}
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <div
                        className={
                          "listaMedia tree" +
                          (!cat.chiuso ? " aperto" : " chiuso")
                        }
                      >
                        {/* files singoli */}
                        {cat.files && (
                          <>
                            <div className="">
                              {cat.files.map((file) => {
                                let disabilitato =
                                  p.allegatiMail &&
                                  p.allegatiMail.find((el) => {
                                    return el.id == file.id;
                                  });
                                let mime = getMime(file)[0];
                                let visualizza = getMime(file)[1];

                                let classeFileGlobale = "";
                                if (file.id) {
                                  classeFileGlobale = "globale";
                                }
                                return (
                                  <div
                                    className={
                                      "elListaMedia " + classeFileGlobale
                                    }
                                    key={file.id}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      <IconaFile mime={mime} />

                                      <small>{file.original_name}</small>

                                      {file.file_id && <LinkIcon />}

                                      <DriveFileRenameOutlineIcon
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setPopupRinomina(true);
                                          setDaRinominare(file.id);
                                          setNomeFileDaRinominare(
                                            file.original_name
                                          );
                                        }}
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                      }}
                                    >
                                      <small>
                                        {file.size ? file.size + "MB" : ""}{" "}
                                      </small>
                                      <DownloadIcon
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          downloadMedia(
                                            file.id,
                                            file.original_name,
                                            "tree"
                                          );
                                        }}
                                      />

                                      {visualizza && (
                                        <VisibilityIcon
                                          className="bottoneC"
                                          style={{
                                            cursor: "pointer",
                                            marginRight: 5,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setPopupVedi(true);
                                            getDatiMediaTree(file.id);
                                          }}
                                        />
                                      )}
                                      <ContentCopyIcon
                                        ///globale
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          ///elimina singolo
                                          e.stopPropagation();
                                          setPopupDuplica(true);

                                          setDaDuplicare(file.id);
                                        }}
                                      />
                                      {!checkAutista() && !allegaON && (
                                        <DeleteIcon
                                          color="error"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            ///elimina singolo
                                            e.stopPropagation();
                                            setPopupElimina(true);

                                            setDaEliminare([file.id]);
                                          }}
                                        />
                                      )}
                                      {allegaON && (
                                        <LinkIcon
                                          color="success"
                                          style={{
                                            cursor: "pointer",
                                            opacity: disabilitato ? 0.5 : 1,
                                          }}
                                          onClick={(e) => {
                                            if (disabilitato) {
                                              return;
                                            }
                                            e.stopPropagation();
                                            settaAllegato(
                                              file.id,
                                              file.original_name,
                                              file.size,
                                              file.path
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}

                        {/* sottocategorie */}
                        {cat.subcat &&
                          cat.subcat.map((subcat, index2) => {
                            // console.log(subcat)
                            return (
                              <div
                                className={
                                  "folderBoxOut" +
                                  (subcat.aperto ? " aperto" : " chiuso")
                                }
                                key={subcat.subcat}
                              >
                                <div className="titoloSottoSezione">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {subcat.aperto ? (
                                      <KeyboardArrowDownIcon
                                        onClick={() => {
                                          ///chiudi sub categoria
                                          let temp = [...mediaTree];

                                          temp[index].subcat[
                                            index2
                                          ].aperto = false;
                                          // console.log(temp)
                                          setMediaTree(temp);
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowRightIcon
                                        onClick={() => {
                                          ///apri sub categoria
                                          let temp = [...mediaTree];

                                          temp[index].subcat[
                                            index2
                                          ].aperto = true;
                                          setMediaTree(temp);
                                        }}
                                      />
                                    )}
                                    <FolderIcon />
                                  </div>
                                  {subcat.subcat}
                                  <DeleteIcon
                                    color="error"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      ///elimina la sotto cartella
                                      e.stopPropagation();
                                      setPopupElimina(true);

                                      let ids = [];
                                      // console.log(subcat);

                                      subcat?.files?.forEach((el) => {
                                        ids.push(el.id);
                                      });

                                      console.log(ids);
                                      setDaEliminare(ids);
                                    }}
                                  />
                                </div>
                                <div className="folderBox">
                                  {subcat.files &&
                                    subcat.files.map((file) => {
                                      let disabilitato =
                                        p.allegatiMail &&
                                        p.allegatiMail.find((el) => {
                                          return el.id == file.id;
                                        });
                                      let mime = getMime(file)[0];
                                      let visualizza = getMime(file)[1];

                                      let classeFileGlobale = "";
                                      if (file.id) {
                                        classeFileGlobale = "globale";
                                      }
                                      return (
                                        <div
                                          className={
                                            "elListaMedia " + classeFileGlobale
                                          }
                                          key={file.id}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: 10,
                                            }}
                                          >
                                            <IconaFile mime={mime} />

                                            <small>{file.original_name}</small>

                                            {file.file_id && <LinkIcon />}

                                            <DriveFileRenameOutlineIcon
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setPopupRinomina(true);
                                                setDaRinominare(file.id);
                                                setNomeFileDaRinominare(
                                                  file.original_name
                                                );
                                              }}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: 10,
                                              alignItems: "center",
                                            }}
                                          >
                                            <small>
                                              {file.size
                                                ? file.size + "MB"
                                                : ""}{" "}
                                            </small>
                                            <DownloadIcon
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                downloadMedia(
                                                  file.id,
                                                  file.original_name,
                                                  "tree"
                                                );
                                              }}
                                            />
                                            {visualizza && (
                                              <VisibilityIcon
                                                className="bottoneC"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setPopupVedi(true);
                                                  getDatiMediaTree(file.id);
                                                }}
                                              />
                                            )}
                                            <ContentCopyIcon
                                              //subcat
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                ///elimina singolo
                                                e.stopPropagation();
                                                setPopupDuplica(true);

                                                setDaDuplicare(file.id);
                                              }}
                                            />
                                            {!checkAutista() && !allegaON && (
                                              <DeleteIcon
                                                color="error"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  ///elimina singolo
                                                  e.stopPropagation();
                                                  setPopupElimina(true);

                                                  setDaEliminare([file.id]);
                                                }}
                                              />
                                            )}
                                            {allegaON && (
                                              <LinkIcon
                                                color="success"
                                                style={{
                                                  cursor: "pointer",
                                                  opacity: disabilitato
                                                    ? 0.5
                                                    : 1,
                                                }}
                                                onClick={(e) => {
                                                  if (disabilitato) {
                                                    return;
                                                  }
                                                  e.stopPropagation();
                                                  settaAllegato(
                                                    file.id,
                                                    file.original_name,
                                                    file.size,
                                                    file.path
                                                  );
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => {
          setPopupElimina(false);
          eliminaMedia(daEliminare);
        }}
      />

      <PopupRinomina
        open={popupRinomina}
        fnAnnulla={(e) => setPopupRinomina(false)}
        daRinominare={daRinominare}
        nomeFileDaRinominare={nomeFileDaRinominare}
        setNomeFileDaRinominare={setNomeFileDaRinominare}
        fnSuccess={(e) => {
          rinominaMedia();
          setPopupRinomina(false);
        }}
      />

      <PopupVedi
        open={popupVedi}
        media={vediMedia}
        mime={vediMediaMime}
        fnAnnulla={(e) => {
          setPopupVedi(false);
          setVediMediaMime(null);
        }}
      />

      <PopupUpload
        open={popupUpload}
        idPrev={idPrev}
        tipoPopup={tipoPopup}
        tipoMedia={tipoMedia}
        setTipoMedia={setTipoMedia}
        fnSuccess={(e) => {
          setPopupUpload(false);
          // queryMedia();
          queryMediaTree();
        }}
        fnAnnulla={(e) => setPopupUpload(false)}
      />

      <PopupAllegati
        open={popupAllegati}
        idPrev={idPrev}
        selettori={true}
        tipoPopup={tipoPopup}
        checkSel={checkSel}
        setCheckSel={setCheckSel}
        tipoMedia={tipoMedia}
        setTipoMedia={setTipoMedia}
        subCat={subCat}
        setSubCat={setSubCat}
        fnSuccess={(e) => {
          allegaFiles();
        }}
        fnAnnulla={(e) => setPopupAllegati(false)}
      />

      <PopupDuplicaMedia
        open={popupDuplica}
        fnAnnulla={(e) => setPopupDuplica(false)}
        daRinominare={daRinominare}
        daDuplicare={daDuplicare}
        setDaDuplicare={setDaDuplicare}
        fnSuccess={(e) => {
          // rinominaMedia();
          setPopupDuplica(false);
        }}
      />
    </div>
  );
};

export { MediaLista };
