import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  convertiDataHM,
  monFetch,
  checkRuoloUtente,
  convertiData,
  calcDataFine,
  pad,
  calcDataDifferenzaGiorniLavorativi,
  calcDataSommaGiorniLavorativi,
} from "../inc/inc";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import EmailIcon from "@mui/icons-material/Email";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CalculateIcon from "@mui/icons-material/Calculate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PopupDettaglioCliente from "./PopupDettaglioCliente";
import PopupProposteConferma from "./PopupProposteConferma";

let calcolaAnno = () => {
  //anno parte da settembre (se mese da sett a dicembre, l'anno è il seguente)
  let date = new Date();
  let mese = date.getMonth() + 1;

  let mesiAnnoSeguente = [9, 10, 11, 12];

  let anno;
  if (mesiAnnoSeguente.includes(mese)) {
    anno = date.getFullYear() + 1;
  } else {
    anno = date.getFullYear();
  }

  return anno;
};

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;
  let id = null;
  if (params.id) {
    id = params.id;
    isModifica = true;
  }

  const [dataInserimento, setDataInserimento] = useState("");
  const [dataConsegna, setDataConsegna] = useState("");
  const [dataConsegnaReale, setDataConsegnaReale] = useState("");
  const [dataInizio, setDataInizio] = useState("");
  const [dataFine, setDataFine] = useState("");
  const [idMetodo, setIdMetodo] = useState(null);
  const [idTipoViaggio, setIdTipoViaggio] = useState(null);
  const [idTipoGruppo, setIdTipoGruppo] = useState(null);
  const [idAdminDocState, setIdAdminDocState] = useState(null);
  const [noteSpedizione, setNoteSpedizione] = useState("");
  const [notePreventivo, setNotePreventivo] = useState("");
  const [statoId, setStatoId] = useState(null);
  const [statoData, setStatoData] = useState("");
  const [statoIdMetodo, setStatoIdMetodo] = useState(null);
  const [istitutoId, setIstitutoId] = useState(null);
  const [codice, setCodice] = useState("");
  const [codiceKreo, setCodiceKreo] = useState("");
  const [anno, setAnno] = useState(calcolaAnno());
  const [idTipologia, setIdTipologia] = useState("");
  const [destinazione, setDestinazione] = useState("");
  const [idUtente, setIdUtente] = useState("");
  const [idUtenteConferma, setIdUtenteConferma] = useState("");
  const [idUtenteRiquotazione, setIdUtenteRiquotazione] = useState("");
  const [giorni, setGiorni] = useState("");
  const [mezziTrasporto, setMezziTrasporto] = useState([]);
  const [tech_eco_offer, setTech_eco_offer] = useState(false);
  const [hotel_rec, setHotel_rec] = useState(3);
  const [bus_rec, setBus_rec] = useState(3);
  const [studenti, setStudenti] = useState(0);
  const [studentiConDisabilita, setStudentiConDisabilita] = useState(0);
  const [insegnantiPaganti, setInsegnantiPaganti] = useState(0);
  const [insegnantiNonPaganti, setInsegnantiNonPaganti] = useState(0);
  const [genitoriPaganti, setGenitoriPaganti] = useState(0);
  const [genitoriNonPaganti, setGenitoriNonPaganti] = useState(0);

  const [pagantiM, setPagantiM] = useState(0);
  const [pagantiF, setPagantiF] = useState(0);
  const [nonPagantiM, setNonPagantiM] = useState(0);
  const [nonPagantiF, setNonPagantiF] = useState(0);

  const [noteInterne, setNoteInterne] = useState("");
  const [noteBus, setNoteBus] = useState("");
  const [risposteBus, setRisposteBus] = useState("");
  const [transfer_rec, setTransfer_rec] = useState(3);
  const [anticipateDay, setAnticipateDay] = useState(0);
  const [cig, setCig] = useState("");
  const [lotto, setLotto] = useState("");

  const [creatoDa, setCreatoDa] = useState("");

  const [modificatoDa, setModificatoDa] = useState("");
  const [dataModifica, setDataModifica] = useState("");

  const [testoSelectClienti, setTestoSelectClienti] = useState("");

  const [optionsMetodo, setOptionsMetodo] = useState([]);
  const [optionsOffers, setOptionsOffers] = useState([]);
  const [optionsTipiViaggio, setOptionsTipiViaggio] = useState([]);
  const [optionsTipiGruppo, setOptionsTipiGruppo] = useState([]);
  const [optionsStato, setOptionsStato] = useState([]);
  const [optionsIstituto, setOptionsIstituto] = useState([]);
  const [optionsTipologia, setOptionsTipologia] = useState([]);
  const [optionsUtente, setOptionsUtente] = useState([]);
  const [optionsTrasporti, setOptionsTrasporti] = useState([]);
  const [optionsTrasportiSelezionate, setOptionsTrasportiSelezionate] =
    useState([]);
  const [optionsAdminDocState, setOptionsAdminDocState] = useState([]);
  const [optionsFornitoriBus, setOptionsFornitoriBus] = useState([]);
  const [fornitoreBus, setFornitoreBus] = useState(null);

  const [obbligatorio, setObbligatorio] = useState([]);
  const [disabilitato, setDisabilitato] = useState([]);

  const [triggerSalvaPreventivo, setTriggerSalvaPreventivo] = useState(0);

  const [condizioneMostraBus, setCondizioneMostraBus] = useState(false);
  const [condizioneMostraTransfer, setCondizioneMostraTransfer] =
    useState(false);

  const [popupMail, setPopupMail] = useState(false);
  const [popupMailRiquot, setPopupMailRiquot] = useState(false);
  const [popupDettaglioCliente, setPopupDettaglioCliente] = useState(false);

  const [popupProposteConferma, setPopupProposteConferma] = useState(false);
  const [optionsProposteConferma, setOptionsProposteConferma] = useState([]);

  const [logMail, setLogMail] = useState([]);

  const [caricato, setCaricato] = useState(false);

  useEffect(() => {
    //gestisco la visibilita di transfer e bus
    //bus
    if (
      idTipoViaggio &&
      idTipoViaggio != 3 &&
      idTipoViaggio != 6 &&
      idTipoViaggio != 7
    ) {
      setCondizioneMostraBus(true);
      if (!caricato || !isModifica) {
        setBus_rec(3);
      }
    } else {
      setCondizioneMostraBus(false);
      if (!caricato || !isModifica) {
        setBus_rec(4);
      }
    }

    //transfer
    if (
      idTipoViaggio &&
      idTipoViaggio != 4 &&
      idTipoViaggio != 7 &&
      !checkMezzoDiTrasportoContiene(1)
    ) {
      setCondizioneMostraTransfer(true);
      if (!caricato || !isModifica) {
        setTransfer_rec(3);
      }
    } else {
      if (!caricato || !isModifica) {
        setTransfer_rec(4);
      }
      setCondizioneMostraTransfer(false);
    }

    //hotel
    if (
      idTipoViaggio &&
      idTipoViaggio != 3 &&
      idTipoViaggio != 4 &&
      idTipoViaggio != 6
    ) {
      if (!caricato || !isModifica) {
        setHotel_rec(3);
      }
    } else {
      if (!caricato || !isModifica) {
        setHotel_rec(4);
      }
    }
  }, [idTipoViaggio, mezziTrasporto]);

  async function queryPreventivo() {
    const res = await monFetch("/quoteGet", {
      id,
    });

    const prev = res.quote;

    setDataConsegna(prev.date_delivery || "");
    setDataConsegnaReale(prev.date_delivery_real || "");
    setDataInizio(prev.start_date || "");
    setDataFine(prev.end_date || "");
    setIdMetodo(prev.method_id || "");
    setAnticipateDay(prev.anticipate_day || 0);
    setIdTipoViaggio(prev.trip_type_id || "");
    setIdTipoGruppo(prev.group_type_id || "");
    setIdAdminDocState(prev.admin_doc_state_id || "");
    setNoteSpedizione(prev.delivery_notes || "");
    setNotePreventivo(prev.quote_notes || "");
    setStatoId(prev.status_id || "1");
    setStatoData(prev.status_date || "");
    setStatoIdMetodo(prev.status_method_id || "");
    setIstitutoId(prev.customer_id || "");
    setAnno(prev.year || "");
    setIdTipologia(prev.typology_id || "");
    setDestinazione(prev.destination || "");
    setIdUtente(prev.user_id || "");
    setIdUtenteConferma(prev.user_id_conferma || "");
    setIdUtenteRiquotazione(prev.user_id_riquotazione || "");
    setGiorni(prev.days || "");
    setMezziTrasporto(prev.transportIds || "");
    setTech_eco_offer(prev.tech_eco_offer ? true : false);
    setBus_rec(prev.bus_rec);
    setHotel_rec(prev.hotel_rec);
    setStudenti(prev.students);
    setStudentiConDisabilita(prev.w_disability);
    setInsegnantiPaganti(prev.paying_teacher);
    setInsegnantiNonPaganti(prev.free_teacher);
    setGenitoriPaganti(prev.paying_parents);
    setGenitoriNonPaganti(prev.free_parents);
    setNoteInterne(prev.internal_notes || "");
    setNoteBus(prev.bus_notes || "");
    setRisposteBus(prev.bus_response || "");
    setTransfer_rec(prev.transfer_rec);
    setFornitoreBus(prev.busProvider);
    setCig(prev.cig);
    setLotto(prev.lotto);
    setCodiceKreo(prev.code_kreosoft || "");
    queryClienti("", prev.customer_id);
    setPagantiM(prev.payers_m || 0);
    setPagantiF(prev.payers_f || 0);
    setNonPagantiM(prev.free_m || 0);
    setNonPagantiF(prev.free_f || 0);

    //se sto clonando un preventivo svuoto i campi necessari
    if (new URL(window.location.href).searchParams.get("clona")) {
      getCodicePrev(prev.year);
      getCampiObbligatori();
    } else {
      setDataInserimento(prev.date_insert || "");
      setDataModifica(prev.date_update);
      setCreatoDa(prev.first_nameC + " " + prev.last_nameC);
      setModificatoDa(prev.first_nameE + " " + prev.last_nameE);
      setCodice(prev.code || "");

      setDisabilitato(res.fields.disabled);
      setObbligatorio(res.fields.required);
    }

    setCaricato(true);
  }

  function ActButtons() {
    let button = "";
    if (new URL(window.location.href).searchParams.get("clona")) {
      //inserimento e casi standard
      button = (
        <Button
          className="inputCustom bottoneSalva"
          type="submit"
          variant="contained"
          onClick={(e) => {
            setTriggerSalvaPreventivo(triggerSalvaPreventivo + 1);
          }}
        >
          Clona
        </Button>
      );
    } else if (
      (statoId == 1 || statoId == 2) &&
      checkRuoloUtente("assegnazione-operatore")
    ) {
      // operatore da assegnare
      button = (
        <span>
          <Button
            sx={{ mr: 2 }}
            className="inputCustom bottoneSalva"
            type="submit"
            variant="contained"
          >
            Salva
          </Button>
          <Button
            className="inputCustom bottoneSalva"
            type="submit"
            variant="contained"
            onClick={(e) => {
              setStatoId(5);
              setTriggerSalvaPreventivo(triggerSalvaPreventivo + 1);
            }}
          >
            Assegna
          </Button>
        </span>
      );
    } else if (statoId == 10 && checkRuoloUtente("controllore")) {
      // se è da controllare e l'utente ha il ruolo di controllore
      button = (
        <span>
          <Button
            sx={{ mr: 2 }}
            className="inputCustom bottoneElimina"
            type="submit"
            variant="contained"
            onClick={(e) => {
              setStatoId(15);
              setTriggerSalvaPreventivo(triggerSalvaPreventivo + 1);
            }}
          >
            Rifiuta
          </Button>

          <Button
            sx={{ mr: 2 }}
            className="inputCustom bottoneSalva"
            type="submit"
            variant="contained"
          >
            Salva
          </Button>

          <Button
            className="inputCustom bottoneSalva"
            variant="contained"
            onClick={(e) => {
              setStatoId(20);
              setTriggerSalvaPreventivo(triggerSalvaPreventivo + 1);
            }}
          >
            Conferma
          </Button>
        </span>
      );
    } else {
      //inserimento e casi standard
      button = (
        <Button
          className="inputCustom bottoneSalva"
          type="submit"
          variant="contained"
        >
          Salva
        </Button>
      );
    }

    return button;
  }

  function checkMezzoDiTrasportoContiene(idMezzo) {
    let contiene = false;
    mezziTrasporto.forEach((el) => {
      if (el.transport_id == idMezzo) {
        contiene = true;
      }
    });
    return contiene;
  }

  async function getCampiObbligatori() {
    const res = await monFetch("/utilityFields", {});
    setDisabilitato(res.disabled);
    setObbligatorio(res.required);
  }

  async function getCodicePrev(anno) {
    const res = await monFetch("/utilityNewCode", {
      year: anno,
    });
    setCodice(res.code);
  }

  useEffect(() => {
    // getCampiObbligatori('assegnazione-operatore');
    if (!id) {
      //se nuovo, otttengo nuovo codice
      getCodicePrev(anno);
      getCampiObbligatori();
    }
  }, [anno]);

  useEffect(() => {
    if (dataInizio && giorni) {
      setDataFine(calcDataFine(dataInizio, giorni));
    }
  }, [dataInizio, giorni]);

  function calcExpiration(dataConsegnaReale, day_before = null) {
    let anticipate = 0;
    if (day_before != null) {
      anticipate = day_before;
    } else {
      anticipate = anticipateDay;
    }

    // console.log(anticipate, dataConsegnaReale);
    if (dataConsegnaReale != "") {
      let calcDate = calcDataDifferenzaGiorniLavorativi(
        dataConsegnaReale,
        anticipate
      );
      setDataConsegna(
        calcDate.getFullYear() +
        "-" +
        pad(calcDate.getMonth() + 1) +
        "-" +
        pad(calcDate.getDate()) +
        "T" +
        pad(calcDate.getHours()) +
        ":" +
        pad(calcDate.getMinutes())
      );
    }
  }

  useEffect(
    //popolo trasporti selezionati
    () => {
      if (optionsTrasporti.length == 0) {
        return;
      }
      let selez = [];
      mezziTrasporto.forEach((e) => {
        optionsTrasporti.forEach((e2) => {
          if (e2.value == e.transport_id) {
            selez.push(e2);
          }
        });
      });

      setOptionsTrasportiSelezionate(selez);
    },
    [mezziTrasporto, optionsTrasporti]
  );
  async function queryMetodi() {
    const res = await monFetch("/methodList", {});
    const metodi = [];
    res.methods.forEach((el) => {
      metodi.push({
        label: el.name,
        value: el.id,
        anticipate: el.anticipate_day,
      });
    });
    setOptionsMetodo(metodi);
  }

  async function queryOffers() {
    const res = await monFetch("/offersStateList", {});
    const offerte = [];
    res.offers.forEach((el) => {
      offerte.push({ label: el.name, value: el.id, available: el.available });
    });
    setOptionsOffers(offerte);
  }

  async function queryStati() {
    const res = await monFetch("/statusList", {});
    const stati = [];
    res.status.forEach((el) => {
      stati.push({ label: el.name, value: el.id, available: el.available });
    });
    setOptionsStato(stati);
  }

  async function queryClienti(value = "", id = "") {
    const res = await monFetch("/customerList", { search: value, id });

    const istituti = [];
    res.customer.forEach((el) => {
      let nome;
      if (el.first_name) {
        nome =
          el.first_name +
          " " +
          el.last_name +
          (el.city ? " - " + el.city : "") +
          (el.fiscalCode ? " - " + el.fiscalCode : "") +
          " - " +
          el.id;
      } else {
        nome =
          el.companyName +
          (el.city ? " - " + el.city : "") +
          (el.fiscalCode ? " - " + el.fiscalCode : "") +
          " - " +
          el.id;
      }
      istituti.push({ label: nome, value: el.id });
    });
    //cerca se il testo è una opzione esatta
    let testoEsatto = false;
    optionsIstituto.forEach((e) => {
      if (e.label == value) {
        testoEsatto = true;
      }
    });
    if (!testoEsatto) {
      setOptionsIstituto(istituti);
    }
  }

  async function queryTipiViaggio() {
    const res = await monFetch("/tripTypeList", {
      group_type_id: idTipoGruppo,
    });
    const trip_type = [];
    res.trip_type.forEach((el) => {
      trip_type.push({ label: el.name, value: el.id });
    });
    setOptionsTipiViaggio(trip_type);
  }

  async function queryTipiGruppo() {
    const res = await monFetch("/groupTypeList", {});
    const temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.group_type, value: el.id });
    });
    setOptionsTipiGruppo(temp);
  }

  async function queryTipologie() {
    const res = await monFetch("/typologyList", {});
    const tipologie = [];
    res.typology.forEach((el) => {
      // console.log(el);
      tipologie.push({
        label: el.name,
        value: el.id,
        giorniPosticipo: el.days_after,
      });
    });
    setOptionsTipologia(tipologie);
  }

  async function queryUtenti() {
    const res = await monFetch("/userListFull", {});
    const utenti = [];
    res.users.forEach((el) => {
      utenti.push({
        label: el.first_name + " " + el.last_name,
        value: el.id,
      });
    });
    setOptionsUtente(utenti);
  }

  async function queryMezziTrasporto() {
    const res = await monFetch("/transportList", {});
    const trasporti = [];
    res.transport.forEach((el) => {
      trasporti.push({ label: el.name, value: el.id });
    });
    setOptionsTrasporti(trasporti);
  }

  async function queryFornitoriBus() {
    const res = await monFetch("/busProviderList", {
      quote_id: id,
    });
    const trasporti = [];
    res.res.forEach((el) => {
      trasporti.push({ label: el.name, value: el.id });
    });
    setOptionsFornitoriBus(trasporti);
    setLogMail(res.logMail || []);
  }

  async function queryAdminDocState() {
    const res = await monFetch("/adminDocStateList", {});
    const docState = [];
    res.admin_doc_state.forEach((el) => {
      docState.push({ label: el.name, value: el.id });
    });
    setOptionsAdminDocState(docState);
  }

  async function inviaMailBus() {
    if (!fornitoreBus) {
      setPopupMail(false);
      return;
    }
    const res = await monFetch("/busProviderSendMail", {
      id: fornitoreBus,
      quote_id: id,
    });
    if (res.success) {
      setPopupMail(false);
      queryFornitoriBus();
    }
  }

  async function inviaMailBusRiquot() {
    if (!fornitoreBus) {
      setPopupMailRiquot(false);
      return;
    }
    const res = await monFetch("/busProviderSendMailRiquot", {
      id: fornitoreBus,
      quote_id: id,
    });
    if (res.success) {
      setPopupMailRiquot(false);
      queryFornitoriBus();
    }
  }

  async function salvaPreventivo(e, ricaricaPag = true) {
    if (e) {
      e.preventDefault();
    }
    let daClonare = null;
    if (new URL(window.location.href).searchParams.get("clona")) {
      daClonare = id;
      id = null;
    }
    const data = {
      id,
      date_delivery: dataConsegna,
      date_delivery_real: dataConsegnaReale,
      start_date: dataInizio,
      end_date: dataFine,
      method_id: idMetodo,
      trip_type_id: idTipoViaggio,
      group_type_id: idTipoGruppo,
      admin_doc_state_id: idAdminDocState,
      delivery_notes: noteSpedizione,
      quote_notes: notePreventivo,
      status_id: statoId,
      status_date: statoData,
      status_method_id: statoIdMetodo,
      customer_id: istitutoId,
      code: codice,
      codeKreo: codiceKreo,
      year: anno,
      typology_id: idTipologia,
      destination: destinazione,
      user_id: idUtente,
      user_id_conferma: idUtenteConferma,
      user_id_riquotazione: idUtenteRiquotazione,
      days: giorni,
      transport_ids: mezziTrasporto,
      tech_eco_offer: tech_eco_offer,
      hotel_rec: hotel_rec,
      bus_rec: bus_rec,
      students: studenti,
      w_disability: studentiConDisabilita,
      paying_teacher: insegnantiPaganti,
      free_teacher: insegnantiNonPaganti,
      paying_parents: genitoriPaganti,
      free_parents: genitoriNonPaganti,
      internal_notes: noteInterne,
      bus_notes: noteBus,
      transfer_rec: transfer_rec,
      fornitoreBus: fornitoreBus,
      cig: cig,
      lotto: lotto,
      clone: daClonare,
      free_m: nonPagantiM,
      free_f: nonPagantiF,
      payers_m: pagantiM,
      payers_f: pagantiF,
    };

    const res = await monFetch("/quoteSet", data);
    if (res.success && ricaricaPag) {
      if (id && res.proposteDaConfermare.length == 0) {
        window.location.href = "/preventivi";
      } else if (id && res.proposteDaConfermare.length > 0) {
        //mostro popup con proposte da confermare
        setPopupProposteConferma(true);
        setOptionsProposteConferma(res.proposteDaConfermare);
      } else {
        window.location.href = "/preventivo/media/" + res.quote;
      }
    }
  }

  useEffect(() => {
    if (triggerSalvaPreventivo) {
      salvaPreventivo();
    }
  }, [triggerSalvaPreventivo]);

  useEffect(() => {
    //se solo land, rimuovo i mezzi selezionati
    if (idTipoViaggio == 7) {
      setMezziTrasporto([]);
    }
    if (idTipoViaggio == 4) {
      ///solobus
      setMezziTrasporto([{ transport_id: 1 }]);
    }
    if (idTipoViaggio == 6) {
      ///solo treno
      setMezziTrasporto([{ transport_id: 3 }]);
    }
    if (idTipoViaggio == 3) {
      ///solo aereo
      setMezziTrasporto([{ transport_id: 2 }]);
    }
  }, [idTipoViaggio]);

  useEffect(() => {
    if (idTipoGruppo) {
      queryTipiViaggio();
    }
    setOptionsTipiViaggio([]);
  }, [idTipoGruppo]);

  useEffect(() => {
    queryMezziTrasporto();
    queryFornitoriBus();
    queryMetodi();

    queryTipiGruppo();
    // evita una chiamata doppia in caso di modifica
    if (!id) {
      queryClienti();
    }
    queryStati();
    queryOffers();
    queryTipologie();
    queryUtenti();
    queryAdminDocState();
    if (isModifica) {
      queryPreventivo();
    }
    else {
      setCaricato(true)
    }
  }, []);

  useEffect(() => {
    ///setto gli anticipate day quando cambia il metodo
    let giorniAnticipo = 0;
    if (idTipologia && idMetodo && caricato) {
      let metodo = optionsMetodo.find((el) => el.value == idMetodo);

      ///cerco giorni posticipo
      let tipologia = optionsTipologia.find((el) => el.value == idTipologia);
      if (!tipologia || !tipologia.giorniPosticipo) {
        ///se non scadenza settata tramite giorni posticipo, setto la scadenza con i giorni di anticipo
        giorniAnticipo = metodo.anticipate;
      }
      setAnticipateDay(giorniAnticipo);
    }
    calcExpiration(dataConsegnaReale, giorniAnticipo);
    // console.log(giorniAnticipo, dataConsegnaReale);
  }, [idTipologia, idMetodo, caricato]);

  useEffect(() => {
    let barraTop;
    let titolo;
    if (isModifica) {
      titolo = "Modifica preventivo";
    } else {
      titolo = "Inserimento preventivo";
    }

    barraTop = (
      <>
        <h2>
          {titolo}{" "}
          {codice != "" && (
            <span className="numeroPreventivo">
              {codice}/{anno} {codiceKreo ? " - K" + codiceKreo : ""}
            </span>
          )}
        </h2>
        {isModifica && (
          <div>
            {statoId >= 1 && statoId < 99 ? (
              /////se assegnato
              <Button
                // color="info"
                onClick={() => {
                  window.location.href = "/calcoloPreventivo/" + id;
                }}
                variant="contained"
                style={{ marginRight: 15 }}
                title="Vai alla pagina di calcolo"
              >
                <CalculateIcon />
              </Button>
            ) : (
              ""
            )}

            <Button
              variant="contained"
              href={"/preventivo/media/" + id}
              title="Allegati"
            >
              <AttachFileIcon />
            </Button>
          </div>
        )}
      </>
    );
    props.setElementiBarraTop(barraTop);
  }, [statoId, codice, codiceKreo]);

  return (
    <>
      <div className="textSmall">
        {creatoDa &&
          "Creato da " + creatoDa + " il " + convertiDataHM(dataInserimento)}
        {modificatoDa &&
          " - Modificato da " +
          modificatoDa +
          " il " +
          convertiDataHM(dataModifica)}
      </div>
      <form onSubmit={(e) => salvaPreventivo(e)}>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <div className="boxPrev">
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <label className="labelGruppo">Dettagli</label>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <TextField
                    size="small"
                    label="Data Inserimento"
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="datetime-local"
                    variant="outlined"
                    className="inputCustom"
                    value={dataInserimento}
                    onChange={(e) => {
                      setDataInserimento(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <TextField
                    required={obbligatorio["year"]}
                    disabled={disabilitato["year"]}
                    size="small"
                    label="Anno"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: "9999" }}
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={anno}
                    onChange={(e) => {
                      getCodicePrev(e.target.value);
                      setAnno(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  {/* //tipo viaggio */}
                  <Autocomplete
                    disabled={disabilitato["trip_type_id"]}
                    value={
                      optionsTipiGruppo.find((op) => op.value == idTipoGruppo)
                        ? optionsTipiGruppo.find(
                          (op) => op.value == idTipoGruppo
                        )
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdTipoGruppo(value?.value);
                    }}
                    options={optionsTipiGruppo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Tipologia di gruppo"
                        className="inputCustom"
                        required={obbligatorio["trip_type_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  {/* //tipo viaggio */}
                  <Autocomplete
                    disabled={disabilitato["trip_type_id"]}
                    value={
                      optionsTipiViaggio.find((op) => op.value == idTipoViaggio)
                        ? optionsTipiViaggio.find(
                          (op) => op.value == idTipoViaggio
                        )
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdTipoViaggio(value?.value);
                    }}
                    options={optionsTipiViaggio}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Tipologia di viaggio"
                        className="inputCustom"
                        required={obbligatorio["trip_type_id"]}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={2} xs={12}>
                  <TextField
                    //required={obbligatorio["code"]}
                    // disabled={true}
                    size="small"
                    label="Codice preventivo"
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={codice}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCodice(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <TextField
                    //required={obbligatorio["code"]}
                    // disabled={true}
                    size="small"
                    label="Codice Kreosoft"
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={codiceKreo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCodiceKreo(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  {/* //addetto */}
                  <Autocomplete
                    disabled={disabilitato["user_id"]}
                    value={
                      optionsUtente.find((op) => op.value == idUtente)
                        ? optionsUtente.find((op) => op.value == idUtente)
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdUtente(value?.value);
                    }}
                    options={optionsUtente}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Addetto Preventivo"
                        className="inputCustom"
                        required={obbligatorio["user_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  {/* //Addetto Conferma */}
                  <Autocomplete
                    // disabled={disabilitato["user_id"]}
                    value={
                      optionsUtente.find((op) => op.value == idUtenteConferma)
                        ? optionsUtente.find(
                          (op) => op.value == idUtenteConferma
                        )
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdUtenteConferma(value?.value);
                    }}
                    options={optionsUtente}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Addetto conferma"
                        className="inputCustom"
                      // required={obbligatorio["user_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  {/* //Addetto Riquotazione */}
                  <Autocomplete
                    // disabled={disabilitato["user_id"]}
                    value={
                      optionsUtente.find(
                        (op) => op.value == idUtenteRiquotazione
                      )
                        ? optionsUtente.find(
                          (op) => op.value == idUtenteRiquotazione
                        )
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdUtenteRiquotazione(value?.value);
                    }}
                    options={optionsUtente}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Addetto Riquotazione"
                        className="inputCustom"
                      // required={obbligatorio["user_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  {/* //stato documento admin */}
                  <Autocomplete
                    disabled={disabilitato["admin_doc_state_id"]}
                    value={
                      optionsAdminDocState.find(
                        (op) => op.value == idAdminDocState
                      )
                        ? optionsAdminDocState.find(
                          (op) => op.value == idAdminDocState
                        )
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setIdAdminDocState(value?.value);
                    }}
                    options={optionsAdminDocState}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Stato doc. amministrativa"
                        className="inputCustom"
                        required={obbligatorio["admin_doc_state_id"]}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: 5,
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      disabled={disabilitato["customer_id"]}
                      freeSolo
                      filterOptions={(x) => x}
                      value={
                        optionsIstituto.find((op) => op.value == istitutoId) &&
                          testoSelectClienti == 0
                          ? optionsIstituto.find((op) => op.value == istitutoId)
                          : testoSelectClienti
                      }
                      inputValue={testoSelectClienti}
                      onInputChange={(e, value) => {
                        setTestoSelectClienti(value);
                        if (value.length >= 3) {
                          queryClienti(value);
                        }
                      }}
                      onChange={(e, value) => {
                        setIstitutoId(value?.value);
                      }}
                      options={optionsIstituto}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          label="Cliente - Città - Cod.Fiscale"
                          className="inputCustom"
                          required={obbligatorio["customer_id"]}
                        />
                      )}
                    />
                    <Button
                      variant="contained"
                      className="bottoneLight"
                      title="Contatti"
                      onClick={(e) => {
                        e.preventDefault();
                        setPopupDettaglioCliente(istitutoId);
                      }}
                    >
                      <SupportAgentIcon />
                    </Button>
                  </div>
                </Grid>

                <Grid item sm={3} xs={12}>
                  {/* //tipologie */}
                  <Autocomplete
                    disabled={disabilitato["typology_id"]}
                    value={
                      optionsTipologia.find((op) => op.value == idTipologia)
                        ? optionsTipologia.find((op) => op.value == idTipologia)
                        : null
                    }
                    onChange={(e, value) => {
                      setIdTipologia(value?.value);
                      if (value?.value == 2) {
                        // per i bandi di tipologia informale, il metodo di consegna è sempre "email"
                        setIdMetodo(2);
                      }
                      if (value?.giorniPosticipo) {
                        console.log(dataInserimento, value?.giorniPosticipo)
                        // setto la scadenza effettiva dopo i giorni di posticipo, se settati in DB
                        let tmp = calcDataSommaGiorniLavorativi(
                          dataInserimento ? dataInserimento : new Date(),
                          value?.giorniPosticipo
                        );
                        let tmp2 =
                          tmp.getFullYear() +
                          "-" +
                          pad(tmp.getMonth() + 1) +
                          "-" +
                          pad(tmp.getDate()) +
                          "T" +
                          pad(tmp.getHours()) +
                          ":" +
                          pad(tmp.getMinutes());
                        setDataConsegnaReale(tmp2);
                        // calcExpiration(tmp2, 0);
                      }

                    }}
                    options={optionsTipologia}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Tipologia"
                        className="inputCustom"
                        required={obbligatorio["typology_id"]}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={3} xs={12}>
                  {/* //metodo */}
                  <Autocomplete
                    disabled={disabilitato["method_id"]}
                    value={
                      optionsMetodo.find((op) => op.value == idMetodo)
                        ? optionsMetodo.find((op) => op.value == idMetodo)
                        : null
                    }
                    onChange={(e, value) => {
                      setIdMetodo(value?.value);
                      // setAnticipateDay(value?.anticipate);
                    }}
                    options={optionsMetodo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Consegna prevista tramite"
                        className="inputCustom"
                        required={obbligatorio["method_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["date_delivery_real"]}
                    disabled={disabilitato["date_delivery_real"]}
                    size="small"
                    label="Data scadenza effettiva"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="datetime-local"
                    variant="outlined"
                    className="inputCustom"
                    value={dataConsegnaReale}
                    onChange={(e) => {
                      setDataConsegnaReale(e.target.value);
                      calcExpiration(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["date_delivery"]}
                    disabled={disabilitato["date_delivery"]}
                    size="small"
                    label="Data scadenza interna"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="datetime-local"
                    variant="outlined"
                    className="inputCustom"
                    value={dataConsegna}
                    onChange={(e) => {
                      setDataConsegna(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <span className="checkMultiple">
                    <span>Compilare offerta tecnico/economica</span>
                    <Checkbox
                      checked={tech_eco_offer}
                      onChange={() => setTech_eco_offer(!tech_eco_offer)}
                      //required={obbligatorio["tech_eco_offer"]}
                      disabled={disabilitato["tech_eco_offer"]}
                    />
                  </span>
                </Grid>

                <Grid item sm={3} xs={12}>
                  <TextField
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="CIG"
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={cig || ""}
                    onChange={(e) => {
                      setCig(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item sm={3} xs={12}>
                  <TextField
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Lotto"
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={lotto || ""}
                    onChange={(e) => {
                      setLotto(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    required={obbligatorio["quote_notes"]}
                    disabled={disabilitato["quote_notes"]}
                    size="small"
                    label="Note preventivo"
                    multiline
                    rows={3}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={notePreventivo}
                    onChange={(e) => {
                      setNotePreventivo(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required={obbligatorio["delivery_notes"]}
                    disabled={disabilitato["delivery_notes"]}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Note spedizione"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    className="inputCustom"
                    value={noteSpedizione}
                    onChange={(e) => {
                      setNoteSpedizione(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    required={obbligatorio["internal_notes"]}
                    disabled={disabilitato["internal_notes"]}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Note interne"
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={noteInterne}
                    onChange={(e) => {
                      setNoteInterne(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    // required={obbligatorio["internal_notes"]}
                    // disabled={disabilitato["internal_notes"]}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Note autonoleggio"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={noteBus}
                    onChange={(e) => {
                      setNoteBus(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    // required={obbligatorio["internal_notes"]}
                    // disabled={disabilitato["internal_notes"]}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Risposte autonoleggio"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={risposteBus}
                    disabled
                  />
                </Grid>
              </Grid>
            </div>
            <div className="boxPrev">
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <label className="labelGruppo">Offerte</label>
                </Grid>
                {
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      disabled={disabilitato["hotel_rec"]}
                      value={
                        optionsOffers.find((op) => op.value == hotel_rec)
                          ? optionsOffers.find((op) => op.value == hotel_rec)
                          : null
                      }
                      onChange={(e, value) => {
                        setHotel_rec(value?.value);
                      }}
                      options={optionsOffers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          label="Hotel"
                          className="inputCustom"
                        />
                      )}
                    />
                  </Grid>
                }
                {condizioneMostraBus && (
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      disabled={disabilitato["bus_rec"]}
                      value={
                        optionsOffers.find((op) => op.value == bus_rec)
                          ? optionsOffers.find((op) => op.value == bus_rec)
                          : null
                      }
                      onChange={(e, value) => {
                        setBus_rec(value?.value);
                      }}
                      options={optionsOffers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          label="Bus"
                          className="inputCustom"
                        />
                      )}
                    />
                  </Grid>
                )}
                {condizioneMostraTransfer && (
                  // se non solo bus
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      disabled={disabilitato["transfer_rec"]}
                      value={
                        optionsOffers.find((op) => op.value == transfer_rec)
                          ? optionsOffers.find((op) => op.value == transfer_rec)
                          : null
                      }
                      onChange={(e, value) => {
                        setTransfer_rec(value?.value);
                      }}
                      options={optionsOffers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          label="Transfer"
                          className="inputCustom"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item lg={4} xs={12}>
            <div className="boxPrev">
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <label className="labelGruppo">Viaggio</label>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <TextField
                    required={obbligatorio["destination"]}
                    disabled={disabilitato["destination"]}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Destinazione"
                    fullWidth
                    variant="outlined"
                    className="inputCustom"
                    value={destinazione}
                    onChange={(e) => {
                      setDestinazione(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["days"]}
                    disabled={disabilitato["days"]}
                    size="small"
                    label="Giorni"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={giorni}
                    onChange={(e) => {
                      let giorni = e.target.value;
                      if (giorni < 1) {
                        giorni = 1;
                      }
                      setGiorni(giorni);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required={obbligatorio["start_date"]}
                    disabled={disabilitato["start_date"]}
                    size="small"
                    label="Data Inizio"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    className="inputCustom"
                    value={dataInizio}
                    onChange={(e) => {
                      setDataInizio(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    size="small"
                    label="Data fine"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    className="inputCustom"
                    value={dataFine}
                    disabled={true}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  {/* //trasporti */}
                  <Autocomplete
                    disabled={
                      disabilitato["transport_ids"] || idTipoViaggio == 7
                    }
                    multiple
                    size="small"
                    value={optionsTrasportiSelezionate || []}
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      const mezzi = [];
                      //const opzioniSelezionate = [];
                      value.forEach((el) => {
                        mezzi.push({ transport_id: el.value });
                        //valori unici
                        //mezzi = [... new Set(mezzi)]
                        //opzioniSelezionate.push(el);
                      });
                      setMezziTrasporto(mezzi);

                      //setOptionsTrasportiSelezionate(opzioniSelezionate);
                    }}
                    options={optionsTrasporti}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Mezzo"
                        className="inputCustom"
                        required={
                          mezziTrasporto.length > 0 || idTipoViaggio == 7
                            ? false
                            : obbligatorio["transport_ids"]
                        }
                      />
                    )}
                  />
                </Grid>

                {(checkMezzoDiTrasportoContiene(1) ||
                  checkMezzoDiTrasportoContiene(4)) && (
                    <>
                      <Grid item sm={10} xs={10}>
                        {/* //fornitore Bus */}
                        <Autocomplete
                          value={
                            optionsFornitoriBus.find((el) => {
                              return el.value == fornitoreBus;
                            })
                              ? optionsFornitoriBus.find((el) => {
                                return el.value == fornitoreBus;
                              })
                              : null
                          }
                          onChange={(e, value) => {
                            let valore;
                            if (!value) {
                              valore = null;
                            } else {
                              valore = value?.value;
                            }
                            setFornitoreBus(valore);
                          }}
                          options={optionsFornitoriBus}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                              label="Fornitore Bus"
                              className="inputCustom"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item sm={2} xs={2}>
                        <Button
                          className={
                            "inputCustom " +
                            (logMail.length > 0 ? "bottoneSalva" : "")
                          }
                          href="/preventivi"
                          variant="contained"
                          fullWidth
                          disabled={!isModifica}
                          onClick={(e) => {
                            e.preventDefault();
                            setPopupMail(true);
                          }}
                        >
                          <EmailIcon />
                        </Button>

                        <Button
                          className={
                            "inputCustom " +
                            (logMail.find((el) => {
                              return el.quotation_type == "r";
                            })
                              ? "bottoneSalva"
                              : "")
                          }
                          href="/preventivi"
                          variant="contained"
                          color="info"
                          fullWidth
                          disabled={!isModifica}
                          onClick={(e) => {
                            e.preventDefault();
                            setPopupMailRiquot(true);
                          }}
                        >
                          <EmailIcon />
                        </Button>
                      </Grid>

                      {logMail.length > 0 && (
                        <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                          {logMail.map((el) => {
                            return (
                              <div key={el.id} style={{ fontSize: 11 }}>
                                {convertiData(el.date)} - {el.email} (
                                {el.quotation_type.toUpperCase()})
                              </div>
                            );
                          })}
                        </Grid>
                      )}
                    </>
                  )}
              </Grid>
            </div>
            <div className="boxPrev">
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <label className="labelGruppo">Partecipanti</label>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["students"]}
                    disabled={disabilitato["students"]}
                    size="small"
                    label="Studenti"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={studenti}
                    onChange={(e) => {
                      setStudenti(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["paying_teacher"]}
                    disabled={disabilitato["paying_teacher"]}
                    size="small"
                    label="Insegnanti non paganti"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={insegnantiNonPaganti}
                    onChange={(e) => {
                      setInsegnantiNonPaganti(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["paying_parents"]}
                    disabled={disabilitato["paying_parents"]}
                    size="small"
                    label="Genitori non paganti"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={genitoriNonPaganti}
                    onChange={(e) => {
                      setGenitoriNonPaganti(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["w_disability"]}
                    disabled={disabilitato["w_disability"]}
                    size="small"
                    label="Studenti con disabilità"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={studentiConDisabilita}
                    onChange={(e) => {
                      setStudentiConDisabilita(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["paying_teacher"]}
                    disabled={disabilitato["paying_teacher"]}
                    size="small"
                    label="Insegnanti paganti"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={insegnantiPaganti}
                    onChange={(e) => {
                      setInsegnantiPaganti(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    required={obbligatorio["paying_parents"]}
                    disabled={disabilitato["paying_parents"]}
                    size="small"
                    label="Genitori paganti"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={genitoriPaganti}
                    onChange={(e) => {
                      setGenitoriPaganti(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["payers_m"]}
                    disabled={disabilitato["payers_m"]}
                    size="small"
                    label="Paganti M"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={pagantiM}
                    onChange={(e) => {
                      setPagantiM(e.target.value || 0);
                      setPagantiM(e.target.value || 0);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["payers_f"]}
                    disabled={disabilitato["payers_f"]}
                    size="small"
                    label="Paganti F"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={pagantiF}
                    onChange={(e) => {
                      setPagantiF(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["free_m"]}
                    disabled={disabilitato["free_m"]}
                    size="small"
                    label="Non paganti M"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={nonPagantiM}
                    onChange={(e) => {
                      setNonPagantiM(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    required={obbligatorio["free_f"]}
                    disabled={disabilitato["free_f"]}
                    size="small"
                    label="Non paganti F"
                    fullWidth
                    type="number"
                    variant="outlined"
                    className="inputCustom"
                    value={nonPagantiF}
                    onChange={(e) => {
                      setNonPagantiF(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="boxPrev">
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <label className="labelGruppo">Consegna</label>
                </Grid>
                <Grid item sm={12} xs={12}>
                  {/* //stato */}
                  <Autocomplete
                    disabled={disabilitato["status_id"]}
                    value={
                      optionsStato.find((op) => op.value == statoId)
                        ? optionsStato.find((op) => op.value == statoId)
                        : null
                    }
                    onChange={(e, value) => {
                      setStatoId(value?.value);
                    }}
                    getOptionDisabled={(option) => option.available == 0}
                    options={optionsStato}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Stato"
                        className="inputCustom"
                        required={obbligatorio["status_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {/* //stato metodo */}
                  <Autocomplete
                    disabled={disabilitato["status_method_id"]}
                    value={
                      optionsMetodo.find((op) => op.value == statoIdMetodo)
                        ? optionsMetodo.find((op) => op.value == statoIdMetodo)
                        : null
                    }
                    onChange={(e, value) => {
                      // console.log("change autoc", value);
                      setStatoIdMetodo(value?.value);
                    }}
                    options={optionsMetodo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        label="Consegna effettuata tramite"
                        className="inputCustom"
                        required={obbligatorio["status_method_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required={obbligatorio["status_date"]}
                    disabled={disabilitato["status_date"]}
                    size="small"
                    label="Data Invio"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    className="inputCustom"
                    value={statoData}
                    onChange={(e) => {
                      setStatoData(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            style={{
              paddingTop: 0,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Button
              className="inputCustom"
              href="/preventivi"
              variant="contained"
            >
              Indietro
            </Button>

            <ActButtons />
          </Grid>
        </Grid>
        <PopupMail
          open={popupMail}
          fnAnnulla={(e) => setPopupMail(false)}
          fnSuccess={(e) => {
            salvaPreventivo(e, false);
            inviaMailBus();
          }}
        />
        <PopupMailRiquotazione
          open={popupMailRiquot}
          fnAnnulla={(e) => setPopupMailRiquot(false)}
          fnSuccess={(e) => {
            salvaPreventivo(e, false);
            inviaMailBusRiquot();
          }}
        />

        <PopupDettaglioCliente
          open={popupDettaglioCliente}
          fnAnnulla={(e) => setPopupDettaglioCliente(false)}
        />

        <PopupProposteConferma
          open={popupProposteConferma}
          options={optionsProposteConferma}
          fnAnnulla={() => {
            setPopupProposteConferma(false);
          }}
          idPrev={id}
        />
      </form>
    </>
  );
};

const PopupMail = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma invio mail</DialogTitle>
      <DialogContent>
        <DialogContentText>
          La mail verrà inviata al fornitore selezionato
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupMailRiquotazione = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma invio mail riquotazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          La mail verrà inviata al fornitore selezionato
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
