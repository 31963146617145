import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  monFetch,
} from "../inc/inc";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Comp = (props) => {
  let location = useLocation();
  // console.log(props)

  const [dati, setDati] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [inputNome, setInputNome] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const [apriBoxNuovo, setApriBoxNuovo] = useState(false);

  const settaEditabile = (index, editabile) => {
    let temp = [...dati];
    temp[index].editabile = editabile;
    setDati(temp);
  };

  async function query() {
    const res = await monFetch("/tourOperatorList", {
      response: true,
    });
    setDati(res.res);
  }

  async function salvaNuovo(id = null) {
    const res = await monFetch("/tourOperatorSet", {
      id,
      name: inputNome,
      email: inputEmail,
    });
    query();
    setInputNome("");
    setInputEmail("");
  }

  async function elimina(id) {
    const res = await monFetch("/tourOperatorDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    if (!props.setElementiBarraTop) {
      return;
    }
    let barraTop = (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          <a href="/dest_guide" style={{ color: "#fff" }}>
            Tour Operator
          </a>
        </h2>
        <Button
          className="inputCustom"
          variant="contained"
          onClick={(e) => {
            // console.log(apriBoxNuovo);
            setInputNome("");
            setInputEmail("");

            setApriBoxNuovo(!apriBoxNuovo);
          }}
        >
          {!apriBoxNuovo ? "Nuovo" : "Annulla"}
        </Button>
      </div>
    );
    props.setElementiBarraTop(barraTop);
  }, [apriBoxNuovo]);

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <div
        className="boxNuovaDest"
        style={{ display: apriBoxNuovo ? "flex" : "none" }}
      >
        <TextField
          label="Nome"
          size="small"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputNome}
          onChange={(e) => {
            setInputNome(e.target.value);
          }}
        />
        <TextField
          label="Email"
          size="small"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputEmail}
          onChange={(e) => {
            setInputEmail(e.target.value);
          }}
        />

        <Button
          fullWidth
          className="inputCustom"
          variant="contained"
          onClick={(e) => {
            if (!inputNome) {
              return;
            }
            salvaNuovo();
          }}
        >
          Aggiungi
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dati.map((p, index) => {
            return (
              <TableRow
                key={p.id}
                className=""
              >
                <TableCell>{p.name}</TableCell>
                <TableCell>
                  {!p.editabile ? (
                    p.email
                  ) : (
                    <TextField
                      label="Email"
                      size="small"
                      fullWidth
                      variant="outlined"
                      className="inputCustom"
                      value={inputEmail}
                      onChange={(e) => {
                        setInputEmail(e.target.value);
                      }}
                    />
                  )}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: "right",
                    width: 150,
                  }}
                >
                  {!p.editabile ? (
                    <EditIcon
                      onClick={(e) => {
                        setInputEmail(p.email || "");
                        settaEditabile(index, true);
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                  ) : (
                    <SaveIcon
                      color="success"
                      onClick={(e) => {
                        salvaNuovo(p.id);
                        query();
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                  )}
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setDaEliminare(p.id);
                    }}
                    style={{ marginRight: "20px", cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />

      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
