import Button from "@mui/material/Button";
import { monFetch } from "../inc/inc";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Table, TableBody, TableRow, TableCell } from "@mui/material";

const Comp = (props) => {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [nomeAzienda, setNomeAzienda] = useState("");
  const [CF, setCF] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [citta, setCitta] = useState("");
  const [provincia, setProvincia] = useState("");
  const [CAP, setCAP] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cellulare, setCellulare] = useState("");
  const [email, setEmail] = useState("");

  async function queryDettagliCliente(customer_id) {
    const res = await monFetch("/customerGet", {
      id: customer_id,
    });
    if (res.success) {
      setNome(res.customer.first_name || "");
      setCognome(res.customer.last_name || "");
      setNomeAzienda(res.customer.companyName || "");
      setCF(res.customer.fiscalCode || "");
      setIndirizzo(res.customer.address || "");
      setCitta(res.customer.city || "");
      setProvincia(res.customer.state || "");
      setCAP(res.customer.postalCode || "");
      setCellulare(res.customer.mobilePhone || "");
      setEmail(res.customer.email || "");

      // se props.telefono inizia con 0039 rimuovo i primi 4 caratteri
      let telefonoTemp = res.customer.phone;
      if (telefonoTemp && telefonoTemp.substring(0, 4) == "0039") {
        telefonoTemp = telefonoTemp.substring(4);
      }

      setTelefono(telefonoTemp);
    }
  }

  useEffect(() => {
    if (props.open) {
      queryDettagliCliente(props.open);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={props.fnAnnulla}
      maxWidth="lg"
    >
      <DialogTitle>
        {nomeAzienda} {nome} {cognome}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Indirizzo</b>
              </TableCell>
              <TableCell>{indirizzo}</TableCell>
              <TableCell>
                <b>Città</b>
              </TableCell>
              <TableCell>
                {citta} ({provincia})
              </TableCell>
              <TableCell>
                <b>CAP</b>
              </TableCell>
              <TableCell>{CAP}</TableCell>

            </TableRow>
            <TableRow>
           
              <TableCell>
                <b>Telefono</b>
              </TableCell>
              <TableCell>{telefono}</TableCell>
              <TableCell>
                <b>Cellulare</b>
              </TableCell>
              <TableCell>{cellulare}</TableCell>
              <TableCell>
                <b>CF / P.IVA</b>
              </TableCell>
              <TableCell>{CF}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell colSpan={5}>{email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
