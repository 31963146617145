import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { monFetch, monForm } from "../../inc/inc";
import { useLocation, useParams } from "react-router-dom";

import Button from "@mui/material/Button";

import ReactCrop, { centerCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function FileUpload(props) {
  const maxFiles = props.maxFiles || 1;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: caricaFiles,
  });
  // const [fileUpload, setFileUpload] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [filesLista, setFileslista] = useState([]);

  async function caricaFiles(files) {
    let file = files[0];
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      props.setImgDaCrop(reader.result);
    };
  }
  // useEffect(() => {
  //   // let lista = files.map((file, index) => {
  //   //   return (
  //   //     <li key={index}>
  //   //       {file.path} - {(file.size / 1000000).toFixed(2)} MB
  //   //     </li>
  //   //   );
  //   // });
  //   // setFileslista(lista);
  // }, [files]);

  return (
    <section className="dropContainer">
      <div {...getRootProps({ className: "dropZone" })}>
        <input {...getInputProps()} />
        <div>
          <p>Carica e rilascia il file qui</p>
          {/* <p>Puoi caricare massimo {maxFiles} file</p> */}
        </div>
      </div>

      {/* {filesLista.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <h4>File in caricamento:</h4>
          <ul className="dropList">{filesLista}</ul>
        </div>
      )} */}

      {/* {fileUpload ? (
        // <Alert severity="error">File non caricati</Alert>
        <Alert severity="success" style={{ marginTop: 15 }}>
          File caricati correttamente
        </Alert>
      ) : (
        ""
      )} */}
    </section>
  );
}

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;

  const [crop, setCrop] = useState();
  const [imgDaCrop, setImgDaCrop] = useState("");

  useEffect(() => {
    props.setContenutoImmagine("");
    setCrop();
  }, [imgDaCrop]);

  function generaImgTagliata(e) {
    if (e && e.width && e.height) {
      let image = document.querySelector("."+props.classeImg);
      let canvas = document.querySelector("."+props.classe);

      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("No 2d context");
      }

      let scale = 1;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const pixelRatio = window.devicePixelRatio;

      console.log(scaleX, scaleY);

      // const pixelRatio = 1

      canvas.width = Math.floor(e.width * scaleX * pixelRatio);
      canvas.height = Math.floor(e.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = e.x * scaleX;
      const cropY = e.y * scaleY;

      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 2) Scale the image
      ctx.scale(scale, scale);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();

      let dataUrl = document.querySelector("."+props.classe).toDataURL("image/png");

      let dataUrl2 = dataUrl.replace("data:", "");
      dataUrl2 = dataUrl2.replace("base64,", "");

      props.setMimeImmagine(dataUrl2.split(";")[0]);

      props.setContenutoImmagine(dataUrl2.split(";")[1]);
    }
  }

  return (
    <>
      <FileUpload setImgDaCrop={setImgDaCrop} />

      {imgDaCrop && (
        <div style={{textAlign: "center"}}>
          <div>Seleziona l'area da tagliare per salvare</div>

          <ReactCrop
            crop={crop}
            onChange={(c, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(e) => {
              generaImgTagliata(e);
            }}
            aspect={21 / 29.7}
          >
            <img
              style={{ maxWidth: 500, margin: "auto" }}
              className={props.classeImg}
              src={imgDaCrop}
            />
          </ReactCrop>
        </div>
      )}

      {crop && (
        <>
          <div>
            <canvas
              className={props.classe}
              style={{ maxWidth: 300, display: "none" }}
            ></canvas>
          </div>
        </>
      )}
    </>
  );
};

export default Comp;
