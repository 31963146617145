import {
  monFetch,
  checkRuoloUtente,
  notificationPolling,
  settaCookieFiltri,
  resettaCookieFiltri,
} from "../inc/inc";
import Cookies from "js-cookie";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Chart from "react-apexcharts";
import BloccoDashboard from "./BloccoDashboard";

const Comp = (props) => {

  const colors = ["#e54707", "#666"];
  // data
  let date = new Date();
  let datePrec = new Date();
  datePrec.setFullYear(date.getFullYear() - 1);
  let month = date.getMonth() + 1;
  let monthPrec = datePrec.getMonth() + 1;
  if (month <= 9) {
    month = "0" + month;
  }
  if (monthPrec <= 9) {
    monthPrec = "0" + monthPrec;
  }

  const [prevInScadenzaOggi, setPrevInScadenzaOggi] = useState(0);
  const [prevInseriti, setPrevInseriti] = useState(0);
  const [prevDaAssegnare, setPrevDaAssegnare] = useState(0);
  const [prevDaRealizzare, setPrevDaRealizzare] = useState([]);
  const [prevDaControllare, setPrevDaControllare] = useState(0);
  const [prevDaRivedere, setPrevDaRivedere] = useState(0);
  const [prevDaFinalizzare, setPrevDaFinalizzare] = useState(0);
  const [prevDaInviare, setPrevDaInviare] = useState(0);
  const [prevConfermati, setPrevConfermati] = useState(0);
  const [prevDocAmmCaricare, setPrevDocAmmCaricare] = useState(0);

  const [prevConfermatiMezzo, setPrevConfermatiMezzo] = useState([]);
  const [prevConfermatiLabelMezzo, setPrevConfermatiLabelMezzo] = useState([]);

  const [statisticheMezzi, setStatisticheMezzi] = useState({
    axes: [],
    data: [],
  });

  async function getPreventiviCounter() {
    const res = await monFetch("/homepageStats", {});
    setPrevInScadenzaOggi(res.todayDeadlineCnt);
    setPrevInseriti(res.totalQuoteCnt);
  }

  async function getPreventiviMezzo(anno) {
    const res = await monFetch("/homepageTransport", {});
    setPrevConfermatiMezzo(res.count);
    setPrevConfermatiLabelMezzo(res.labels);
  }

  async function getPreventiviDocAmm() {
    const res = await monFetch("/homepageDocAmm", {});
    setPrevDocAmmCaricare(res.count);
  }

  async function getCounterPerUtente() {
    const res = await notificationPolling();

    if (checkRuoloUtente("assegnazione-operatore")) {
      setPrevDaAssegnare(res.toAssign);
    }
    if (checkRuoloUtente("realizzazione-preventivo")) {
      setPrevDaRealizzare(res.toDo);
      setPrevDaRivedere(res.toReview);
    }
    if (checkRuoloUtente("controllore")) {
      setPrevDaControllare(res.toCheck);
    }
    if (checkRuoloUtente("finalizzazione-preventivo")) {
      setPrevDaFinalizzare(res.toFinalize);
    }
    if (checkRuoloUtente("invio-preventivo")) {
      setPrevDaInviare(res.toSend);
    }

    setPrevConfermati(res.confirmed);
  }

  async function transportCount() {
    const res = await monFetch("/transportCount", {});
    setStatisticheMezzi(res.statisticheMezzi);
  }

  useEffect(() => {
    //se loggato come fornitore bus
    if (Cookies.get("autistaId")) {
      window.location.href = "/preventivi";
    }

    props.setElementiBarraTop(<h2>Homepage</h2>);
    getPreventiviCounter();

    getPreventiviMezzo();
    transportCount();

    getPreventiviDocAmm();
    getCounterPerUtente();
  }, []);
  const current = new Date();
  const today = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  return (
    <Grid container spacing={2}>
      <BloccoDashboard
        titolo="Preventivi inseriti"
        larghezza={3}
        className="pointer"
        dati={<p className="datoNumero">{prevInseriti}</p>}
        onClick={() => {
          resettaCookieFiltri();
          window.location.href = "/preventivi";
        }}
      />

      <BloccoDashboard
        titolo={"Preventivi in scadenza oggi " + today}
        larghezza={3}
        className="pointer"
        dati={<p className="datoNumero">{prevInScadenzaOggi}</p>}
        onClick={() => {
          resettaCookieFiltri();
          settaCookieFiltri("dataScad", new Date().toISOString().split("T")[0]);
          settaCookieFiltri("ordinaPer", "date_delivery");
          settaCookieFiltri("direzione", "asc");
          window.location.href = "/preventivi";
        }}
      />
      <Grid item xs={3}></Grid>
      <BloccoDashboard
        titolo="Preventivi confermati"
        larghezza={3}
        className="pointer "
        dati={<p className="datoNumero">{prevConfermati}</p>}
        styleinterno={{ backgroundColor: "#d12985", color: "#fff" }}
        onClick={() => {
          resettaCookieFiltri();
          settaCookieFiltri("statusId", 50);
          window.location.href = "/preventivi";
        }}
      />

      {checkRuoloUtente("assegnazione-operatore") && (
        <BloccoDashboard
          titolo="Preventivi da assegnare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaAssegnare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 1);
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("realizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da realizzare"
          larghezza={3}
          className="pointer"
          dati={<div className="datoNumero">{prevDaRealizzare.map(e => {

            return <div key={e.user} style={{ fontSize: "14px" }}>{e.user} : {e.count}</div>;
          })}</div>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 5);
            settaCookieFiltri("userId", Cookies.get("id"));
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("controllore") && (
        <BloccoDashboard
          titolo="Preventivi da controllare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaControllare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 10);
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("finalizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi controllati"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaFinalizzare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 20);
            settaCookieFiltri("userId", Cookies.get("id"));
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("realizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da rivedere"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaRivedere}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 15);
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("inserimento-preventivi") && (
        <BloccoDashboard
          titolo="Doc. amministrativa da fare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDocAmmCaricare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            // settaCookieFiltri("dataScad", calcDataSommaGiorniLavorativi(new Date(), 5).toISOString().split("T")[0]); // devo aggiungere 5 gg lavorativi
            settaCookieFiltri("documentazioneAmministrativa", "1");
            settaCookieFiltri("statusId", "1,5,10,15,20,25,30,45");
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("invio-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da inviare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaInviare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 25);
            settaCookieFiltri("ordinaPer", "date_delivery");
            settaCookieFiltri("direzione", "asc");
            window.location.href = "/preventivi";
          }}
        />
      )}



      {statisticheMezzi.axes.length > 0 && (
        <BloccoDashboard
          larghezza={8}
          titolo="Statistiche mezzi di trasporto richiesti"
          dati={
            <Chart
              type="bar"
              height={350}
              series={statisticheMezzi.data}
              options={{
                chart: {
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: statisticheMezzi.axes,
                },
              }}
            />
          }
        />
      )}

      {prevConfermatiLabelMezzo.length > 0 && (
        <BloccoDashboard
          titolo="Andamento conferme per mezzo"
          dati={
            prevConfermatiMezzo.length == 0 ? (
              "Nessun dato da mostrare"
            ) : (
              <Chart
                type="pie"
                series={prevConfermatiMezzo}
                options={{
                  labels: prevConfermatiLabelMezzo,
                }}
              />
            )
          }
        />
      )}
    </Grid>
  );
};

export default Comp;
