import Button from "@mui/material/Button";
import { monFetch } from "../inc/inc";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const Comp = (props) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);


  async function confermaProposta(id) {
    const res = await monFetch("/confermaProposta", {
      id: id,
      quote_id: props.idPrev,
    });
    if (res.success) {
     window.location.href = "/preventivi";
    }
  }

  useEffect(() => {
    // if (props.open) {
    //   // queryDettagliCliente(props.open);
    // }

    // console.log(props);
    let temp = [];
    props.options.forEach(el => {
      temp.push({value: el.id, label: el.proposal_name});
    });
    setOptions(temp);
  }, [props.open]);

  return (
    <Dialog open={props.open ? true : false} maxWidth="lg">
      <DialogTitle>Proposta da confermare</DialogTitle>
      <DialogContent style={{paddingTop: 10}}>
        
        <Autocomplete
        fullWidth
        style={{width: 400, maxWidth: "90vw"}}
          value={
            options.find((op) => op.value == value)
              ? options.find((op) => op.value == value)
              : null
          }
          onChange={(e, value) => {
            // console.log("change autoc", value);
            setValue(value?.value);
          }}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Proposta"
              className="inputCustom"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
      <Button variant="contained" onClick={()=>{confermaProposta(value)}}>Salva</Button>
       
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
