import Button from "@mui/material/Button";
import React, { useState, useEffect, useCallback } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import DestGuide from "../DestGuide";
import DestEscursioni from "../DestEscursioni";
import DestIngressi from "../DestIngressi";

const PopupAggiungi = (props) => {
  ///aggiungi guide ingressi e escursioni in calcolo preventivo
  const [elementiSelezionati, setElementiSelezionati] = useState([]);
  const [idInseriti, setIdInseriti] = useState([]);
  const [idInseritiPDF, setIdInseritiPDF] = useState([]);

  // console.log(props)

  let tipo = props.tipo;
  let titolo = "";
  let elemento = null;
  let datiTo = [];

  let pdf = false;
  if (props.inserisciExtraPDF) {
    pdf = true;
  }

  let generaArrayIds = (arr) => {
    let ret = [];
    arr.forEach((el) => {
      if (el.id) {
        ret.push(el.id);
      }
    });
    return ret;
  };

  let eliminaInseritoPDF = (id) => {
    let temp;
    if (tipo == "guide") {
      temp = [...props.arrDatiGuidePDF];
    } else if (tipo == "escursioni") {
      temp = [...props.arrDatiEscursioniPDF];
    } else if (tipo == "ingressi") {
      temp = [...props.arrDatiIngressiPDF];
    }
    let indice = temp.findIndex((el) => {
      return el.id == id;
    });

    // console.log(temp, id, indice);

    temp.splice(indice, 1);

    if (tipo == "guide") {
      props.setArrDatiGuidePDF(temp);
    } else if (tipo == "escursioni") {
      props.setArrDatiEscursioniPDF(temp);
    } else if (tipo == "ingressi") {
      props.setArrDatiIngressiPDF(temp);
    }
  };

  useEffect(() => {
    if (tipo == "guide") {
      //genero array di id gia inseriti per non renderli ri-selezionabili
      setIdInseriti(generaArrayIds(props.arrDatiGuide));
      setIdInseritiPDF(generaArrayIds(props.arrDatiGuidePDF));
    } else if (tipo == "escursioni") {
      setIdInseriti(generaArrayIds(props.arrDatiEscursioni));
      setIdInseritiPDF(generaArrayIds(props.arrDatiEscursioniPDF));
    } else if (tipo == "ingressi") {
      setIdInseriti(generaArrayIds(props.arrDatiIngressi));
      setIdInseritiPDF(generaArrayIds(props.arrDatiIngressiPDF));
    }
    setElementiSelezionati([]);
  }, [
    props.open,
    props.arrDatiGuidePDF,
    props.arrDatiEscursioniPDF,
    props.arrDatiIngressiPDF,
  ]);

  // useEffect(() => {
  //   console.log("selezionati", elementiSelezionati);
  // }, [elementiSelezionati]);

  function importaDati(from, to, tipo) {
    // console.log("importa", from, to);
    let fnAggiorna = null;
    let fnSetta = null;
    let temp = [...to];
    let indexStart = temp.length;

    if (tipo == "guide") {
      fnSetta = props.settaDatiGuide;

      if (!pdf) {
        fnAggiorna = props.setArrDatiGuide;
      } else {
        fnAggiorna = props.setArrDatiGuidePDF;
      }

      from.forEach((el, index) => {
        index = index + indexStart;
        temp.push({});
        let temp2 = {
          id: el.id,
          auricolari: el.auricolari,
          localita: el.description,
          guidaPers: el.participants_per_guide,
          halfDay: el.half_day,
          fullDay: el.full_day,
        };

        fnSetta(temp, index, temp2, null, pdf);
      });
      fnAggiorna(temp);
      props.fnAnnulla();
    } else if (tipo == "escursioni") {
      fnSetta = props.settaDatiEscursioni;
      if (!pdf) {
        fnAggiorna = props.setArrDatiEscursioni;
      } else {
        fnAggiorna = props.setArrDatiEscursioniPDF;
      }
      from.forEach((el, index) => {
        index = index + indexStart;
        temp.push({});
        let temp2 = {
          id: el.id,
          localita: el.description,
          euroGruppo: el.group_price,
          euroPax: el.passenger_price,
          maxPax: el.max_passenger,
        };
        fnSetta(temp, index, temp2, null, pdf);
        // console.log(temp, index, temp2);
      });
      fnAggiorna(temp);
      props.fnAnnulla();
    } else if (tipo == "ingressi") {
      fnSetta = props.settaDatiIngressi;
      if (!pdf) {
        fnAggiorna = props.setArrDatiIngressi;
      } else {
        fnAggiorna = props.setArrDatiIngressiPDF;
      }
      from.forEach((el, index) => {
        index = index + indexStart;
        temp.push({});
        let temp2 = {
          id: el.id,
          descrizione: el.description,
          descrizione_interna: el.internal_description,
          prezzoGruppo: el.group_price,
          prezzoPax: el.passenger_price,
          prezzoNonPag: el.passenger_free_price,
        };
        fnSetta(temp, index, temp2, null, null, pdf);
        // console.log(temp, index, temp2);
      });
      // console.log("temp", temp);
      fnAggiorna(temp);
      props.fnAnnulla();
    }
  }

  // console.log(idInseriti, props.arrDatiEscursioni)
  if (!props.open) {
    return null;
  }
  if (tipo == "guide") {
    titolo = "Importa Visite Guidate";
    if (!pdf) {
      datiTo = props.arrDatiGuide;
    } else {
      datiTo = props.arrDatiGuidePDF;
    }
    elemento = (
      <DestGuide
        selezionabile={true}
        elementiSelezionati={elementiSelezionati}
        setElementiSelezionati={setElementiSelezionati}
        destinazione={props.destinazione}
        idInseriti={idInseriti}
        idInseritiPDF={idInseritiPDF}
        inserisciExtraPDF={props.inserisciExtraPDF}
        eliminaInseritoPDF={eliminaInseritoPDF}
        idProposta={props.idProposta}
      />
    );
  } else if (tipo == "escursioni") {
    titolo = "Importa Escursioni";
    if (!pdf) {
      datiTo = props.arrDatiEscursioni;
    } else {
      datiTo = props.arrDatiEscursioniPDF;
    }
    elemento = (
      <DestEscursioni
        selezionabile={true}
        elementiSelezionati={elementiSelezionati}
        setElementiSelezionati={setElementiSelezionati}
        destinazione={props.destinazione}
        idInseriti={idInseriti}
        idInseritiPDF={idInseritiPDF}
        inserisciExtraPDF={props.inserisciExtraPDF}
        eliminaInseritoPDF={eliminaInseritoPDF}
        idProposta={props.idProposta}
      />
    );
  } else if (tipo == "ingressi") {
    titolo = "Importa Ingressi";
    if (!pdf) {
      datiTo = props.arrDatiIngressi;
    } else {
      datiTo = props.arrDatiIngressiPDF;
    }
    elemento = (
      <DestIngressi
        selezionabile={true}
        elementiSelezionati={elementiSelezionati}
        setElementiSelezionati={setElementiSelezionati}
        destinazione={props.destinazione}
        idInseriti={idInseriti}
        idInseritiPDF={idInseritiPDF}
        inserisciExtraPDF={props.inserisciExtraPDF}
        eliminaInseritoPDF={eliminaInseritoPDF}
        idProposta={props.idProposta}
      />
    );
  }

  if (pdf) {
    titolo += " Supplementari";
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.fnAnnulla}
      maxWidth={"xl"}
      fullWidth
    >
      <DialogTitle>{titolo}</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>{elemento}</DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button
          onClick={() => {
            importaDati(elementiSelezionati, datiTo, tipo);
          }}
          variant="contained"
        >
          Importa
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupAggiungi;
