import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  monFetch,

} from "../inc/inc";  
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BoxNotifica from "./componenti_generici/BoxNotifica";

import Pagination from "@mui/material/Pagination";

const Comp = (props) => {
 
  const [voli, setVoli] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [apriSnackbar, setApriSnackbar] = useState(false);
  const [messaggioSnackbar, setMessaggioSnackbar] = useState("");

  const chiudiSnackbar = (event, reason) => {
    setApriSnackbar(false);
  };


  
  async function query() {
    const res = await monFetch("/flightSupplierList", {
    });
    setVoli(res.res);
  }

  async function elimina(id) {
    const res = await monFetch("/flightSupplierDelete", { id });
    if(res.success){
      setPopupElimina(false);
      query();
    }else{
      setPopupElimina(false);
      setApriSnackbar(true);
      setMessaggioSnackbar(res.msg);
    }
  }

  useEffect(() => {
    let barraTop = (
      <h2>
         Lista voli
      </h2>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  useEffect(() => {
    query();
  }, []);

  

  return (
    <>
    <Grid container spacing={2}>
      <Grid item sm={3} xs={12}>
        <Button variant="contained" href="/flight/nuovo">
          Nuovo volo
        </Button>
      </Grid>
      <Grid item sm={6} xs={12}></Grid>
  
    </Grid>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Compagnia aerea</TableCell>
          <TableCell>Bagaglio a mano</TableCell>
          <TableCell>Bagaglio piccolo in stiva</TableCell>
          <TableCell>Bagaglio grande in stiva</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {voli.map((p) => {
          return (
            <TableRow
              key={p.id}
              className="rigaSelezionabile"
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = "/flight/" + p.id;
              }}
            >
              <TableCell>{p.name}</TableCell>

              <TableCell>{p.cabin}</TableCell>
              <TableCell>{p.cabin_stiva}</TableCell>
              <TableCell>{p.luggage}</TableCell>
              <TableCell
                style={{
                  textAlign: "right",
                }}
              >
                <DeleteIcon
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopupElimina(true);
                    setDaEliminare(p.id);
                  }}

                  
                  style={{ marginRight: "20px" }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    <br />
    <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
  
  <BoxNotifica
        apriSnackbar={apriSnackbar}
        chiudiSnackbar={chiudiSnackbar}
        messaggioSnackbar={messaggioSnackbar}
        tipo="error"
      />


  </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export default Comp;
