import { Grid } from "@mui/material";

const BloccoDashboard = (props) => {
    return (
        <Grid
            item
            sm={props.larghezza || 4}
            xs={12}
            style={{ display: "flex", ...props.style }}
            {...props}
        >
            <div className="dashBox" style={{ width: "100%", ...props.styleinterno }}>
                <div className="">
                    <h3 style={{ marginTop: 0, marginBottom: 10 }}>{props.titolo}</h3>
                </div>

                {props.dati}
            </div>
        </Grid>
    );
};

export default BloccoDashboard;