import { monFetch } from "../../inc/inc";
import { useState, useEffect } from "react";
import BloccoDashboard from "../BloccoDashboard";
import Chart from "react-apexcharts";

const Comp = (props) => {
  const [statisticheOperatori, setStatisticheOperatori] = useState({
    axes: [],
    data: [],
  });
  const [statisticheRichieste, setStatisticheRichieste] = useState({
    axes: [],
    data: [],
  });
  const [statisticheConferme, setStatisticheConferme] = useState({
    axes: [],
    data: [],
  });
  const [statisticheConfermeImporto, setStatisticheConfermeImporto] = useState({
    axes: [],
    data: [],
  });

  async function queryOperator() {
    const res = await monFetch("/operatorCount", {});
    setStatisticheOperatori(res.statisticheOperatori);
  }
  async function queryRequest() {
    const res = await monFetch("/requestCount", {});
    setStatisticheRichieste(res.statisticheRichieste);
  }
  async function queryConfirm() {
    const res = await monFetch("/confirmCount", {});
    setStatisticheConferme(res.statisticheConferme);
  }
  async function queryConfirmImporto() {
    const res = await monFetch("/confirmImporto", {});
    setStatisticheConfermeImporto(res.statisticheConfermeImporto);
  }

  useEffect(() => {
    let barraTop = <h2>Statistiche</h2>;
    props.setElementiBarraTop(barraTop);

    queryOperator();
    queryRequest();
    queryConfirm();
    queryConfirmImporto();
  }, []);


  return (
    <>

      {statisticheOperatori.axes.length > 0 && (
        <BloccoDashboard
          larghezza={12}
          titolo="Statistiche occupazione operatori"
          style={{ marginBottom: "20px" }}
          dati={
            <Chart
              type="bar"
              height={350}
              series={statisticheOperatori.data}
              options={{
                chart: {
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: statisticheOperatori.axes,
                },
              }}
            />
          }
        />
      )}

      {statisticheRichieste.axes.length > 0 && (
        <BloccoDashboard
          larghezza={12}
          titolo="Preventivi inseriti"
          style={{ marginBottom: "20px" }}
          dati={
            <Chart
              type="line"
              height={350}
              series={statisticheRichieste.data}
              options={{
                chart: {
                  zoom: {
                    enabled: false
                  },
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: statisticheRichieste.axes,
                },
              }}
            />
          }
        />
      )}

      {(statisticheConferme.axes.length > 0 && statisticheConfermeImporto.axes.length) && (
        <BloccoDashboard
          larghezza={12}
          titolo="Preventivi confermati"
          style={{ marginBottom: "20px" }}
          dati={
            <Chart
              type="line"
              height={350}
              series={statisticheConferme.data}
              options={{
                chart: {
                  zoom: {
                    enabled: false
                  },
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: statisticheConferme.axes,
                },
                tooltip: {
                  y: {
                    formatter: function (val, { series, seriesIndex, dataPointIndex }) {
                      return val + " (" + (statisticheConfermeImporto.data[seriesIndex].data[dataPointIndex] * 1).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) + ")";
                    },
                  },
                },
              }}
            />
          }
        />
      )}

    </>
  );
};

export default Comp;
